import { UserProfile, Security, UserPermissions, ConsultationReasons } from "./auth"
import { SpeechArea, MultipleInputField, TreeNode } from "./general"
import { General, NewPatient, Disabilities, Insurances, Health, Treatments, MedicalCards, Memo, Management, PastInformation } from "./patients"

import { CkEditor, CkSimpleEditor } from "./editor"

import { BaseCalendar } from "./planning"
import { PatientCard } from "./schedule"

import { Allergies, History, BloodType, VitalFunctions, Measurements, Attests } from "./health"
import { Prescriptions, ChronicPrescriptions } from "./prescriptions"
import { DiagnosticReferrals } from "./lab"
import { Vaccinations } from "./vaccinations"
import { Decursus } from "./decursus"
import { BarChart, LineChart } from "./charts"

import { ReportingVitalFunctions } from "./hearts"

import { MsgNavbarButton, MsgInboxTab } from "./messaging"
import { VcViewTemplate, VcTemplateBuilder, VcBuilder, VcNavbarButton, VcListGroupComponent } from "./viewcreator"

import { Letters, LetterTemplateBuilder } from "./letters"

import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete'; // new

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate"

import * as rules from "vee-validate/dist/rules"
// import { numeric, double } from 'vee-validate/dist/rules';

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('measurement', {
  validate: value => {
		 // Convert the value to a string to handle different input types
		  const valueStr = value.toString();

		  // Check if the value is an integer
		  if (Number.isInteger(Number(value))) {
		    return true;
		  }

		  // Check if the value is a double with one decimal place
		  const oneDecimalPlacePattern = /^\d+\.\d{1}$/
		  if (oneDecimalPlacePattern.test(valueStr)) {
		    return true
		  }

		  // Check if the value is a double with two decimal places
		  const twoDecimalPlacesPattern = /^\d+\.\d{2}$/
		  if (twoDecimalPlacesPattern.test(valueStr)) {
		    return true
		  }

		  // If none of the conditions match, the value is invalid
		  return false
  },

  message: 'The {_field_} field must be a number with one or two decimal places'
})

import moment from 'moment'
extend('is_not_greator', (value, [otherValue]) => {
    const time1 = moment(value, 'HH:mm:ss')
    const time2 = moment(otherValue, 'HH:mm:ss')

    return time1.isBefore(time2)
}, {
  hasTarget: true
})

import { registerComponents } from "bootstrap-vue/src/utils/plugins"

const components = {
	UserProfile,
	Security,
	UserPermissions,
	ConsultationReasons,
	VcViewTemplate,
	VcTemplateBuilder,
	VcBuilder,
	VcNavbarButton,
	VcListGroupComponent,
	CkEditor,
	CkSimpleEditor,
	
	Letters,
	LetterTemplateBuilder,

	VueBootstrapAutocomplete,
	ValidationObserver,
	ValidationProvider,
	
	SpeechArea,
	MultipleInputField,
	TreeNode,
	
	General,
	NewPatient,
	Disabilities,
	// Consultations,
	Insurances,
	Health,
	Treatments,
	MedicalCards,
	Memo,
	Management,
	PastInformation,
	// HeartsHistory,
	
  BaseCalendar,
  PatientCard,
	History,
	Allergies,
	BloodType,
	VitalFunctions,
	Measurements,
	Attests,

	ReportingVitalFunctions,
	
	Prescriptions,
	ChronicPrescriptions,
	DiagnosticReferrals,
	Vaccinations,
	Decursus,
	BarChart,
	LineChart,

	MsgNavbarButton,
	MsgInboxTab
}

export default {
	install(Vue) {
		registerComponents(Vue, components)
	}
}
