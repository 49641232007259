export const reservedFieldNames = [
    'data',
    'initial',
    'only',
    'populate',
    'processing',
    'is_dirty',
    'reset',
    'withData',
]

export function guardAgainstReservedFieldName(fieldName) {
    if (reservedFieldNames.indexOf(fieldName) !== -1) {
        throw new Error(
            `Field name ${fieldName} isn't allowed to be used in a Form or Errors instance.`
        )
    }
}

export function isArray(object) {
    return Object.prototype.toString.call(object) === '[object Array]'
}

export function isFile(object) {
    return object instanceof File || object instanceof FileList
}

export function merge(a, b) {
    for (const key in b) {
        a[key] = cloneDeep(b[key])
    }
}

export function cloneDeep(object) {
    if (object === null) {
        return null
    }

    if (isFile(object)) {
        return object
    }

    if (Array.isArray(object)) {
        const clone = []

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                clone[key] = cloneDeep(object[key])
            }
        }

        return clone
    }

    if (typeof object === 'object') {
        const clone = {}

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                clone[key] = cloneDeep(object[key])
            }
        }

        return clone
    }

    return object
}

export function objectToFormData(object, formData = new FormData(), parent = null) {
    if (object === null || object === 'undefined' || object.length === 0) {
        return formData.append(parent, object)
    }

    for (const property in object) {
        if (object.hasOwnProperty(property)) {
            appendToFormData(formData, getKey(parent, property), object[property])
        }
    }

    return formData
}

function getKey(parent, property) {
    return parent ? parent + '[' + property + ']' : property
}

function appendToFormData(formData, key, value) {
    if (value instanceof Date) {
        return formData.append(key, value.toISOString())
    }

    if (value instanceof File) {
        return formData.append(key, value, value.name)
    }

    if (typeof value === "boolean") {
        return formData.append(key, value ? '1' : '0')
    }

    if (typeof value !== 'object') {
        return formData.append(key, value)
    }

    objectToFormData(value, formData, key)
}