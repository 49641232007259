const update = (el) => {
	if (el.value) {
		var event = new Event("input")
		el.value = el.value.toUpperCase()
		el.dispatchEvent(event)
	}
}
/*
 * Export our directive
 */
export const uppercase = {
	update
}
