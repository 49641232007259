<template>
	<b-card id="corner-popup" class="animated fadeIn card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
	    <template v-slot:header>
	        <i class="fa-regular fa-square-poll-vertical"></i> <strong>Results {{ referral?.type?.name }} | {{ referral?.priority?.name }}</strong>
	        <div class="card-header-actions">
	        	{{ toLocaleDate(referral?.created) }}
	        </div>
	    </template>
	    <div class="scrollable-test-list">
		    <b-list-group flush>
				<ReferralTestItem v-for="item in tests" :referral="item" :key="item.id"  />
			</b-list-group>
		</div>
		<b-card-body>
			<ResultUploadForm :referral="referral" />
    	</b-card-body>
    	<b-card-footer class="text-right">
    		<b-button variant="secondary" @click="$emit('update:referral', null)">Close</b-button>
    	</b-card-footer>
	</b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import ReferralTestItem from './forms/ReferralTestItem'
import ResultUploadForm from './forms/ResultUploadForm'

export default ComponentExtension.extend({
	name: "lab-referrals-popup",
    props: {
        referral: {
            type: Object,
            default: null
        }
    },
    components: {
        ReferralTestItem,
        ResultUploadForm
    },
    computed: {
    	tests() {
    		return this.referral ? this.referral?.diagnostics_tests : []
    	}
    }
})
</script>

<style scoped>
#corner-popup {
  position: fixed;
  bottom: 5px;
  right: 20px;
  width: 500px; /* Adjust width as needed */
  z-index: 1030; /* Lower it to allow page interaction */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.scrollable-test-list {
    overflow-y: scroll;
    max-height: 430px;
}

</style>