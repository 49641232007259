<template>
	<div>
		<textarea ref="editor" placeholder="Start typing..."></textarea>
	</div>
</template>

<script>
import { EditorWatchdog } from '@ckeditor/ckeditor5-watchdog'
import SimpleEditor from './simple-baseeditor'

import { debounce } from 'lodash'

const READ_ONLY_LOCK_ID = 'CKSIMPLEEDITOR'
const INPUT_EVENT_DEBOUNCE_WAIT = 300

export default {
	name: 'ck-simple-editor',

	props: {
		value: {
			type: String,
			default: ''
		},
		config: {
			type: Object,
			default: () => ( {} )
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			watchdog: null,

			$_lastEditorData: {
				type: String,
				default: ''
			},

			isBusy: false,
			status: '',
		}
	},

	async mounted() {

		// Create an editor watchdog.
		this.watchdog = new EditorWatchdog()


		// Define a callback that will create an editor instance and return it.
		this.watchdog.setCreator( ( elementOrData, editorConfig ) => {
		    return SimpleEditor
	        .create( elementOrData, editorConfig )
	        .then( editor => {

	        	// attach inspector
	        	// CKEditorInspector.attach( editor )

				// Use the leading edge so the first event in the series is emitted immediately.
				// Failing to do so leads to race conditions, for instance, when the component value
				// is set twice in a time span shorter than the debounce time.
				// See https://github.com/ckeditor/ckeditor5-vue/issues/149.
				const emitDebouncedInputEvent = debounce( evt => {
				
					// Clear status message when data changes 
					this.status = this.watchdog.state
					
					// Cache the last editor data. This kind of data is a result of typing,
					// editor command execution, collaborative changes to the document, etc.
					// This data is compared when the component value changes in a 2-way binding.
					const data = this.$_lastEditorData = editor.getData()

				// The compatibility with the v-model and general Vue.js concept of input–like components.
					this.$emit( 'input', data, evt, editor )
				
				}, INPUT_EVENT_DEBOUNCE_WAIT, { leading: true } )

				// Debounce emitting the #input event. When data is huge, $_instance#getData()
				// takes a lot of time to execute on every single key press and ruins the UX.
				//
				// See: https://github.com/ckeditor/ckeditor5-vue/issues/42
				editor.model.document.on( 'change:data', emitDebouncedInputEvent )

				editor.editing.view.document.on( 'focus', evt => {
					this.$emit( 'focus', evt, editor )
				} )

				editor.editing.view.document.on( 'blur', evt => {
					this.$emit( 'blur', evt, editor )
				} )

				// Get pending action for showing editor busy state
				const pendingActions = editor.plugins.get( 'PendingActions' )

				pendingActions.on( 'change:hasAny', ( evt, propertyName, newValue ) => {
					this.isBusy = newValue
					// Disable editing while editor is in busy state

					if ( newValue ) {
						const action = pendingActions.first
						const message = action.message
						this.status = message

						// ignore disabling state when autosave fires
						if (message != "Saving changes") { // autosave plugin action message
							editor.enableReadOnlyMode( READ_ONLY_LOCK_ID )
						}
					} else {
						this.status = this.watchdog.state
						if ( !this.disabled ) {
							editor.disableReadOnlyMode( READ_ONLY_LOCK_ID )
						}
					}
				})

				// Synchronize the editor content. The #value may change while the editor is being created, so the editor content has to be
				// synchronized with these potential changes as soon as it is ready.
				if ( this.value !== editorConfig.initialData ) {
					editor.setData( this.value )
				}

				// Set initial disabled state.
				if ( this.disabled ) {
					editor.enableReadOnlyMode( READ_ONLY_LOCK_ID )
				}

				// Let the world know the editor is ready.
				this.$emit( 'ready', editor )

				return editor
	        } )

		} )

		// Do something before the editor is destroyed. Return a promise.
		this.watchdog.setDestructor( editor => {
		    // Do something before the editor is destroyed.
		    // ...

		    return editor.destroy()
		} )

		this.$_buildEditor()
	},

	beforeDestroy() {
		this.watchdog.destroy()
	},

	watch: {
		value( value ) {
			if ( this.watchdog.editor && value !== this.$_lastEditorData ) {
				this.watchdog.editor.setData( value )
			}
		},

		// Synchronize changes of #disabled.
		disabled( readOnlyMode ) {
			if ( readOnlyMode ) {
				this.watchdog.editor.enableReadOnlyMode( READ_ONLY_LOCK_ID )
			} else {
				this.watchdog.editor.disableReadOnlyMode( READ_ONLY_LOCK_ID )
			}
		}
	},

	methods: {
		$_buildEditor() {
			// Clone the config first so it never gets mutated (across multiple editor instances).
			// https://github.com/ckeditor/ckeditor5-vue/issues/101
			// const editorConfig = Object.assign( {}, this.config )

			const editorConfig = {
			    ...this.config,
			}

			if ( this.value ) {
				editorConfig.initialData = this.value
			}

			this.watchdog.create( this.$refs.editor, editorConfig )

			this.watchdog.on( 'stateChange', () => {
			    this.status = this.watchdog.state
			} )
		}
	}
}
</script>