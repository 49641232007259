<template>
    <div>
        <div id="top-bar" class="p-1 text-center font-weight-bold">{{ form.name ? form.name : 'Untitled' }}</div>
        <ck-editor v-model="editorData" :config="editorConfig" />
    </div>
</template>
<script>
import Vue from 'vue'

import LetterExtension from './LetterExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import Placeholder from '@/core/components/editor/plugins/placeholders/placeholder'

import FileManagerModal from './FileManagerModal'
import SaveAsModal from './SaveAsModal'

export default LetterExtension.extend({
    name: 'letter-template-builder',
    props: {
        hidden: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editorData: '',
            form: new Form({
                name: null,
                consistency_token: null
            }),
            editorConfig: {
                menuBar: {
                    removeItems: [ 'menuBar:pdf', 'menuBar:word', 'menuBar:preview', 'menuBar:print' ]
                },

                extraPlugins: [
                    Placeholder,
                ],

                fileMenu: {
                    new: this.newFile,
                    open: this.openFile,
                    saveAs: this.saveAs,
                    save: this.onSave,
                },
                placeholder: {
                    variables: this.getVariableItems
                },

                autosave: {
                    // waitingTime: 5000, // in ms
                    save: this.autosave
                }
            }
        }
    },
    computed: {
        ...mapState('letters', {
            current: state => state.current.template,
            // tmp_data: state => state.tmp_data
        }),
        ...mapGetters('letters', ['getTemplates', 'getVariables']),
        templates() {
            return this.getTemplates()
        },
        variables() {
            return this.getVariables()
        }
    },
    watch: {
        hidden: {
            handler(val) {
                if (val) {
                    this.setCurrentTemplate(null)
                    this.form.reset()
                    this.editorData = ''
                }
            }
        }
    },
    methods: {
        ...mapActions('letters', ['getTemplatesVariables', 'getLetterTemplates', 'setCurrentTemplate', 'createLetterTemplate', 'updateLetterTemplate']),

        reset() {
            this.form.reset()
            this.editorData = ''
            this.setCurrentTemplate(null)
        },

        getVariableItems() {
            return new Promise( resolve => {
                setTimeout( () => {
                    resolve( this.variables )
                }, 500 )
            })
        },

        newFile( editor ) {
            this.reset()
        },

        openFile( editor ) {
            const action = this.createPendingAction(editor, 'waiting...')

            var ComponentClass = Vue.extend(FileManagerModal)
            var instance = new ComponentClass({
                parent: this,
                propsData: { items: this.templates, title: 'Templates' }
            })

            instance.$on("hidden", () => {
                instance.$destroy()
                this.stopPendingAction(editor, action)
            })

            instance.$on("selected", (selected) => {
                this.open(selected)
            })

            instance.$mount()
        },

        async open(selected) {
            this.form.populate(selected)
            this.editorData = selected.content
            this.setCurrentTemplate(selected)
        },

        saveAs( editor ) {
            const action = this.createPendingAction(editor, 'saving as...')

            var ComponentClass = Vue.extend(SaveAsModal)
            var instance = new ComponentClass({
                parent: this,
                propsData: { items: this.templates, title: 'Templates' }
            })

            instance.$on("hidden", () => {
                instance.$destroy()
                this.stopPendingAction(editor, action)
            })

            instance.$on("save-new", (name) => {
                this.form.populate({name: name})
                 this.save(editor, true)
            })

            instance.$on("saved", () => {
                this.save(editor)
            })

            instance.$mount()
        },

        save( editor, isNew = false ) {
            const action =  this.createPendingAction(editor, 'saving...')

            if (isNew) {
                const data = {...this.form.data(), ...{ content: this.editorData }}
                
                this.createLetterTemplate(data)
                .then(template => {
                    this.form.populate(template)
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.stopPendingAction(editor, action)
                })
            }else{
                this.updateLetterTemplate({ content:  this.editorData })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.stopPendingAction(editor, action)
                })
            }
        },

        onSave( editor ) {
            if (this.current) {
                this.save( editor )
            }else{
                this.saveAs( editor )
            }
        },

        async autosave( editor ) {
            // this.saveTmpData(this.editorData)
            if (this.current && this.editorData) {
                await this.updateLetterTemplate({ content:  this.editorData })
            }
        },
    },
    async mounted() {
        await this.getTemplatesVariables()
        await this.getLetterTemplates()
    }
})
</script>
<style scoped>
    #top-bar {
/*      border: 1px solid var(--ck-color-base-border);*/
        border-bottom: 1px solid var(--ck-color-base-border);

        background: var(--ck-color-base-background);
        border-radius: var(--ck-border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
</style>