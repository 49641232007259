// Messaging
const MessagingContainer = () => import("@/views/messaging/containers/DefaultContainer")
const MessagingInbox = () => import("@/views/messaging/views/Inbox")

const routes = [
    {
        path: "/messaging",
        component: MessagingContainer,
        meta: {
            label: "Notification Center"
        },
        children: [
            {
                path: "",
                component: MessagingInbox,
                meta: {
                    roleRequired: ["physician", "administration"],
                    label: "Inbox"
                }
            }
        ]
    }
]

export default routes
