import session from '@/shared/session'

const state = {
	patient: {
		procedures: [],
	},
	decursus: {
		procedures: [],
	}
}

// getters
const getters = {
	getPatientProcedures: (state) => () => {
		return state.patient.procedures
	},
	getDecursusProcedures: (state) => () => {
		return state.decursus.procedures
	}
}

// actions
const actions = {
	async filterProcedures({ commit, state }, params) {
		const items = await session
			.get('/api/procedures/types/', {
					params: params
			})

		return items.data
	},

	async getProceduresByCurrentDecursus({ commit, state, rootState }) {
		const procedures  = await session
			.get(`/api/decursus/${rootState.decursus.current?.id}/procedures/`)

		commit("set_decursus_procedures", procedures.data)

		return state.decursus.procedures
	},

	async getProceduresByDecursusId({ commit, state }, id) {
		const procedures  = await session
			.get(`/api/decursus/${id}/procedures/`)

		return procedures.data
	},

	async getProceduresByPatientId({ commit, state, rootState }) {
		const procedures  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/procedures/`)

		commit("set_patient_procedures", procedures.data)

		return state.patient.procedures
	},

	createProcedureForCurrentPatient({ commit, rootState, dispatch }, data) {
		dispatch("loading/load", "procedures", { root: true })
		return session
				.post(`/api/procedures/`, {...data,  decursus: rootState.patients.current?.current_consult?.decursus_id})
				.then(() => {
					return dispatch("getProceduresByPatientId")
				})
				.then(() => {
					return dispatch("getProcedureByCurrentDecursus")
				})
	      .finally(() => {
	        dispatch("loading/end", "procedures", { root: true })
	      })
	},

	// createPatientProcedure({ commit, rootState, dispatch }, data) {
	// 	dispatch("loading/load", "procedures", { root: true })
	// 	return session
	// 			.post(`/api/procedures/`, {...data,  decursus: rootState.decursus.current?.id})
	// 			.then(() => {
	// 				return dispatch("getProceduresByPatientId")
	// 			})
	//       .finally(() => {
	//         dispatch("loading/end", "procedures", { root: true })
	//       })
	// },
	// updatePatientProcedure({ commit, rootState, dispatch }, { id, data }) {
	// 	dispatch("loading/load", "procedures", { root: true })
	// 	return session
	// 			.patch(`/api/procedures/${id}/`, data)
	// 			.then(() => {
	// 				return dispatch("getProceduresByPatientId")
	// 			})
	//       .finally(() => {
	//         dispatch("loading/end", "procedures", { root: true })
	//       })
	// },

	// deletePatientProcedure({ commit, dispatch }, id) {
	// 	dispatch("loading/load", "procedures", { root: true })
	// 	return session
	// 			.delete(`/api/procedures/${id}/`)
	// 			.then(() => {
	// 				return dispatch("getProceduresByPatientId")
	// 			})
  //     .finally(() => {
  //       dispatch("loading/end", "procedures", { root: true })
  //     })
	// },

	createDecursusProcedure({ commit, rootState, dispatch }, data) {
		dispatch("loading/load", "procedures", { root: true })
		return session
				.post(`/api/procedures/`, {...data,  decursus: rootState.decursus.current?.id})
				.then(() => {
					dispatch("getProceduresByPatientId")
					return dispatch("getProceduresByCurrentDecursus")
				})
	      .finally(() => {
	        dispatch("loading/end", "procedures", { root: true })
	      })
	},
	updateDecursusProcedure({ commit, rootState, dispatch }, { id, data }) {
		dispatch("loading/load", "procedures", { root: true })
		return session
				.patch(`/api/procedures/${id}/`, data)
				.then((updated) => {
					dispatch("getProceduresByPatientId")
					dispatch("getProceduresByCurrentDecursus")
					return updated.data
				})
	      .finally(() => {
	        dispatch("loading/end", "procedures", { root: true })
	      })
	},

	deleteDecursusProcedure({ commit, dispatch }, id) {
		dispatch("loading/load", "procedures", { root: true })
		return session
				.delete(`/api/procedures/${id}/`)
				.then(() => {
					dispatch("getProceduresByPatientId")
					return dispatch("getProceduresByCurrentDecursus")
				})
      .finally(() => {
        dispatch("loading/end", "procedures", { root: true })
      })
	},
}

// mutations
const mutations = {
	set_patient_procedures(state, procedures) {
		state.patient.procedures = procedures
	},
	set_decursus_procedures(state, procedures) {
		state.decursus.procedures = procedures
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}