import session from '@/shared/session'

const state = {
	patient: {
		referrals: [],
	}
}

// getters
const getters = {
	getCurrentReferral: (state) => () => {
		return state.current
	},
	getPatientReferrals: (state) => () => {
		return state.patient.referrals
	}
}

// actions
const actions = {
	async getReferralsByPatientId({ commit, state, rootState }) {
		const referrals  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/referrals/`)

		commit("set_referrals", referrals.data)

		return state.patient.referrals
	},

	createReferral({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "referrals", { root: true })
		return session
				.post(`/api/referrals/`, {...data, decursus: rootState.decursus.current?.id})
				.then((referral) => {
					return referral.data
				})
	      .finally(() => {
	      	dispatch("getReferralsByPatientId")
	        dispatch("loading/end", "referrals", { root: true })
	      })
	},
	
	processReferral({ commit, dispatch }, { id, date }) {
		dispatch("loading/load", "referrals", { root: true })
		return session
			.patch(`/api/referrals/${id}/process/`, { processed_date: date })
			.then(() => {
				return dispatch("getReferralsByPatientId")
			})
      .finally(() => {
        dispatch("loading/end", "referrals", { root: true })
      })
	},

	updateDoctor({ commit, dispatch }, { id, doctor }) {
		dispatch("loading/load", "referrals", { root: true })
		return session
			.patch(`/api/referrals/${id}/update_doctor/`, { consulting_doctor: doctor })
			.then(() => {
				return dispatch("getReferralsByPatientId")
			})
      .finally(() => {
        dispatch("loading/end", "referrals", { root: true })
      })
	},

	referralPrintableById({ commit, state }, id) {
		return session
				.get(`/api/referrals/${id}/printable/`)
				.then(printable => {
					return printable.data
				})
	},
}

// mutations
const mutations = {
	set_referrals(state, referrals) {
		state.patient.referrals = referrals
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}