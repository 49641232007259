import session from '@/shared/session'
import downloadjs from 'downloadjs'

const state = {
	patient: {
		letters: [],
	},

	current: {
		template: null,
		letter: null
	},

	templates: [],
	variables: [],

	// tmp_data: sessionStorage.getItem("ck_tmp_data") ? sessionStorage.getItem("ck_tmp_data") : '',
}

// getters
const getters = {
	getPatientLetters: (state) => () => {
		return state.patient.letters
	},

	getTemplates: (state) => () => {
		return state.templates
	},

	getVariables: (state) => () => {
		return state.variables
	},

	// getTmpData: (state) => () => {
	// 	return state.tmp_data
	// }
}

// actions
const actions = {
	setCurrentTemplate({ commit }, template) {
		commit("set_current_template", template)
	},

	setCurrentLetter({ commit }, letter) {
		commit("set_current_letter", letter)
	},

	// saveTmpData({ commit }, data) {
	// 	commit("set_tmp_data", data)
	// },

	async getLettersByPatientId({ commit, state, rootState }) {
		const letters  = await session
			.get(`/api/patients/${rootState.patients.current.id}/letters/`)

		commit("set_letters", letters.data)

		return state.patient.letters
	},

	async getLetterTemplates({ commit, state }) {
		const templates = await session.get('/api/letters/templates/')
		commit("set_templates", templates.data)
		return state.templates
	},

	createLetterTemplate({ commit, dispatch }, data) {
		dispatch("loading/load", "letters", { root: true })
		return session
				.post(`/api/letters/templates/`, data)
				.then(template => {
					commit("set_current_template", template.data)
					return dispatch("getLetterTemplates")
				})
	      .finally(() => {
	        dispatch("loading/end", "letters", { root: true })
	      })
	},

	updateLetterTemplate({ commit, dispatch }, data) {
		dispatch("loading/load", "letters", { root: true })
		return session
				.patch(`/api/letters/templates/${state.current.template?.id}/`, data)
				.then(template => {
					commit("set_current_template", template.data)
					return dispatch("getLetterTemplates")
				})
	      .finally(() => {
	        dispatch("loading/end", "letters", { root: true })
	      })
	},

	async getTemplatesVariables({ commit, state }) {
		const variables = await session.get('/api/letters/variables/')
		commit("set_variables", variables.data)
		return state.variables
	},
	
	createPatientLetter({ commit, dispatch, rootState }, data) {
		return session
				.post(`/api/letters/patient/`, {...data, patient: rootState.patients?.current?.id})
				.then(letter => {
					commit("set_current_letter", letter.data)
					return dispatch("getLettersByPatientId")
				})
	},

	updatePatientLetter({ commit, state, dispatch }, data) {
		return session
				.patch(`/api/letters/patient/${state.current.letter?.id}/`, data)
				.then(letter => {
					commit("set_current_letter", letter.data)
					return dispatch("getLettersByPatientId")
				})
	},

	deletePatientLetter({ dispatch, commit }, id) {
		dispatch("loading/load", "letters", { root: true })
		return session
				.delete(`/api/letters/patient/${id}/`)
				.then(() => {
					return dispatch("getLettersByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "letters", { root: true })
      })
	},

	async exportToById({ commit, dispatch }, {id, type}) {
		const download = await session
			.post(`/api/letters/patient/${id}/direct_download/`, { type: type})
		
			const { content, filename, mime_type } = download.data
    	downloadjs(content, filename, mime_type)      	
    	return true
	},

	// async exportTo({ commit, state, dispatch }, type) {
	// 	dispatch("loading/load", "letters", { root: true })
	// 	const download = await session
	// 		.post(`/api/letters/patient/${state.current.letter?.id}/direct_download/`, { type: type})
		
	// 	const { content, filename, mime_type } = download.data
  //     	downloadjs(content, filename, mime_type)

	//     dispatch("loading/end", "letters", { root: true })
      	
  //     	return true
	// },

	// toHTML({ commit, state, rootState, dispatch }) {
	// 	dispatch("loading/load", "letters", { root: true })
	// 	return session
	// 			.get(`/api/letters/templates/${state.current.letter?.id}/to_html/`, { params: { patient: rootState.patients.current?.id } })
	// 			.then(letter => {
	// 				return letter.data
	// 			})
  //     .finally(() => {
  //       dispatch("loading/end", "letters", { root: true })
  //     })
	// },

	// toHTML({ commit, state, dispatch }) {
	// 	dispatch("loading/load", "letters", { root: true })
	// 	return session
	// 			.get(`/api/letters/templates/${state.current.letter?.id}/to_html/`)
	// 			.then(letter => {
	// 				return letter.data
	// 			})
  //     .finally(() => {
  //       dispatch("loading/end", "letters", { root: true })
  //     })
	// },

	letterToHTML({ commit, state }) {
		return session
				.get(`/api/letters/patient/${state.current.letter?.id}/to_html/`)
				.then(letter => {
					return letter.data
				})
	},

	letterToHTMLById({ commit, state, rootState }, id) {
		return session
				.get(`/api/letters/patient/${id}/to_html/`)
				.then(letter => {
					return letter.data
				})
	},

	templateToHTMLById({ commit, state, rootState }, id) {
		return session
				.get(`/api/letters/templates/${id}/to_html/`, { params: { patient: rootState.patients.current?.id } })
				.then(letter => {
					return letter.data
				})
	}
}

// mutations
const mutations = {
	set_letters(state, letters) {
		state.patient.letters = letters
	},
	set_current_template(state, current) {
		state.current.template = current
	},
	set_current_letter(state, current) {
		state.current.letter = current
	},
	set_templates(state, templates) {
		state.templates = templates
	},
	set_variables(state, variables) {
		state.variables = variables
	},
	// set_tmp_data(state, data) {
	// 	sessionStorage.setItem("ck_tmp_data", data)
	// },

}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}