import { Plugin, Command, icons } from '@ckeditor/ckeditor5-core'
import { ViewModel, addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui'
import { Collection, first } from '@ckeditor/ckeditor5-utils'
import { LINE_HEIGHT, buildDefinition, normalizeOptions } from './utils'
import lineHeightIcon from '!!raw-loader!./icons/line-height.svg'

class LineHeightCommand extends Command {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'LineHeightEditing'
    }
    constructor(editor) {
        super(editor)
    }
    /**
     * @inheritDoc
     */
    refresh() {
        const model = this.editor.model
        const document = model.document
        const firstBlock = first(document.selection.getSelectedBlocks())
        // As first check whether to enable or disable the command as the value will always be false if the command cannot be enabled.
        this.isEnabled = !!firstBlock && this._canSetLineHeight(firstBlock)
        this.value = (this.isEnabled && firstBlock.hasAttribute(LINE_HEIGHT))
            ? firstBlock.getAttribute(LINE_HEIGHT)
            : 'default'
    }
    execute(options = {}) {
        const editor = this.editor
        const model = editor.model
        const document = model.document
        const value = options.value
        model.change((writer) => {
            const blocks = Array.from(document.selection.getSelectedBlocks())
                .filter(block => this._canSetLineHeight(block))
            const currentLineHeight = blocks[0].getAttribute(LINE_HEIGHT)
            const removeLineHeight = currentLineHeight === value || !value
            if (removeLineHeight)
                removeLineHeightFromSelection(blocks, writer)
            else
                setLineHeightOnSelection(blocks, writer, value)
        })
    }
    _canSetLineHeight(block) {
        return this.editor.model.schema.checkAttribute(block, LINE_HEIGHT)
    }
}
function removeLineHeightFromSelection(blocks, writer) {
    for (const block of blocks)
        writer.removeAttribute(LINE_HEIGHT, block)
}
function setLineHeightOnSelection(blocks, writer, lineHeight) {
    for (const block of blocks)
        writer.setAttribute(LINE_HEIGHT, lineHeight, block)
}


export default class LineHeight extends Plugin {

    static get requires() {
        return [LineHeightEditing, LineHeightUI]
    }

    static get pluginName() {
        return 'LineHeight'
    }
}

class LineHeightEditing extends Plugin {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'LineHeightEditing'
    }
    /**
     * @inheritDoc
     */
    constructor(editor) {
        super(editor)
        // Define default configuration using named presets.
        editor.config.define(LINE_HEIGHT, {
            options: ['default', 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 2, 2.5],
        })
    }
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor
        const schema = editor.model.schema
        const options = normalizeOptions(editor.config.get('lineHeight.options'))
            .filter(option => option.model)
        // Allow LineHeight attribute on all blocks.
        schema.extend('$block', { allowAttributes: LINE_HEIGHT })
        editor.model.schema.setAttributeProperties(LINE_HEIGHT, {
            isFormatting: true,
        })
        // Define view to model conversion.
        const definition = buildDefinition(LINE_HEIGHT, options)
        editor.conversion.attributeToAttribute(definition)
        // Add LineHeight Command.
        editor.commands.add(LINE_HEIGHT, new LineHeightCommand(editor))
    }
}

class LineHeightUI extends Plugin {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'LineHeightUI'
    }
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor
        const componentFactory = editor.ui.componentFactory
        const t = editor.t
        const options = this._getLocalizedOptions()
        const command = editor.commands.get(LINE_HEIGHT)
        // Register UI component.
        componentFactory.add(LINE_HEIGHT, (locale) => {
            const dropdownView = createDropdown(locale)
            addListToDropdown(dropdownView, _prepareListOptions(options, command))
            // Create dropdown model.
            dropdownView.buttonView.set({
                label: t('Line Height'),
                icon: lineHeightIcon,
                tooltip: true,
            })
            dropdownView.extendTemplate({
                attributes: {
                    class: ['ck-line-height-dropdown'],
                },
            })
            dropdownView.bind('isEnabled').to(command)
            // Execute command when an item from the dropdown is selected.
            this.listenTo(dropdownView, 'execute', (evt) => {
                editor.execute(evt.source.commandName, {
                    value: evt.source.commandParam,
                })
                editor.editing.view.focus()
            })
            return dropdownView
        })
    }
    _getLocalizedOptions() {
        const editor = this.editor
        const t = editor.t
        const localizedTitles = {
            Default: t('Default'),
        }
        const options = normalizeOptions(editor.config.get(LINE_HEIGHT).options)
        return options.map((option) => {
            const title = localizedTitles[option.title]
            if (title && title !== option.title) {
                // Clone the option to avoid altering the original `namedPresets` from `./utils.js`.
                option = Object.assign({}, option, { title })
            }
            return option
        })
    }
}
// Prepares LineHeight dropdown items.
function _prepareListOptions(options, command) {
    const itemDefinitions = new Collection()
    for (const option of options) {
        const def = {
            type: 'button',
            model: new ViewModel({
                commandName: LINE_HEIGHT,
                commandParam: option.model,
                label: option.title,
                class: 'ck-line-height-option',
                withText: true,
            }),
        }
        def.model.bind('isOn').to(command, 'value', (value) => {
            return value === option.model
        })
        // Add the option to the collection.
        itemDefinitions.add(def)
    }
    return itemDefinitions
}

