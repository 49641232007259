<template>
    <b-tab lazy title-item-class="mb-1" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
        <template slot="title">
            <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> General
        </template>
        <b-form-group
            label="Profile"
            label-size="lg"
            label-class="font-weight-bold pt-0">
            <b-row>
                <b-col>
                    <validation-observer ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
                            <b-overlay 
                                :show="$store.getters['loading/isLoading']('auth')"
                                rounded="sm"
                                variant="light"
                                :opacity="0.80"
                                blur="2px">
                                <b-form-group :disabled="isLocked">
                                    <b-row>
                                        <b-col cols="12" md="6">
                                            <b-form-group disabled>
                                                <b-input-group prepend="Username">
                                                    <b-form-input type="text"  :value="user?.username" />
                                                </b-input-group>
                                            </b-form-group>
                                            <validation-provider vid="prefix" v-slot="{ errors }">
                                                <b-form-group
                                                    v-if="isPhysician"
                                                    :invalid-feedback="errors[0]"
                                                    :state="errors[0] ? false : null">
                                                    <b-input-group prepend="Prefix">
                                                        <b-form-input type="text" id="prefix" autocomplete="off" name="prefix" placeholder="Enter your prefix" v-model="form.prefix"/>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>          
                                            <validation-provider vid="first_name" v-slot="{ errors }">
                                                <b-form-group
                                                    :invalid-feedback="errors[0]"
                                                    :state="errors[0] ? false : null">
                                                    <b-input-group prepend="First name">
                                                        <b-form-input type="text" id="first_name" autocomplete="off" name="first_name" placeholder="Enter your first name" v-model="form.first_name"/>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider vid="last_name" v-slot="{ errors }">
                                                <b-form-group
                                                    :invalid-feedback="errors[0]"
                                                    :state="errors[0] ? false : null">
                                                    <b-input-group prepend="Surname">
                                                        <b-form-input type="text" id="last_name" autocomplete="off" name="last_name" placeholder="Enter your last name" v-model="form.last_name"/>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <validation-provider name="Email" vid="email" :rules="{ email: true }" v-slot="{ errors }">
                                                <b-form-group
                                                    :invalid-feedback="errors[0]"
                                                    :state="errors[0] ? false : null">
                                                    <b-input-group prepend="Email">
                                                        <b-form-input type="email" id="email" autocomplete="off" name="email" placeholder="Enter your email" v-model="form.email"/>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider vid="identifier" v-slot="{ errors }">
                                                <b-form-group
                                                    v-if="isPhysician"
                                                    :invalid-feedback="errors[0]"
                                                    :state="errors[0] ? false : null">
                                                    <b-input-group prepend="Identifier">
                                                        <b-form-input type="text" id="identifier" autocomplete="off" name="identifier" placeholder="Enter your identifier" v-model="form.identifier"/>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </b-overlay>
                            <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
                            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa-lock"></i> Lock</b-button>
                            <b-button v-show="isDirty | hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
                <b-col cols="3">
                    <b-card class="mb-0" no-body>
                        <b-card-text class="text-center">
                            <b-avatar class="mt-3" size="128px"></b-avatar>
                        </b-card-text>
                        <b-card-text class="text-center">
                            <h5 class="font-italic">{{user?.username}}</h5>
                            <h5>{{user?.name}}</h5>
                            <h5>{{user?.email}}</h5>
                            <h6 v-if="isPhysician">{{user?.identifier}}</h6>
                        </b-card-text>
                        <b-card-footer class="text-right">
                        <small class="text-muted">joined {{toLocaleDate(user?.date_joined)}}</small>
                            </b-card-footer>
                    </b-card>  
                </b-col>
            </b-row>
        </b-form-group>
    </b-tab>
</template>
<script>
import BaseExtension from "@/views/BaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default BaseExtension.extend({
    name: 'user-profile',
    data() {
        return {
            form: new Form({
                prefix: "",
                first_name: "",
                last_name: "",
                email: "",
            }),

            isLocked: true,
            isDirty: false,
            hasErrors: false
        }
    },
    computed: {
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        }
    },
    methods: {
        ...mapActions("auth", ["updateUser"]),
        onFormSubmit() {
            this.updateUser(this.form.data()).then((user) => {
                this.form.withData(user)
                this.notify('User information updated', "User information was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        reset() {
            this.form.reset()
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        }
    },
    mounted() {
        this.form.withData(this.user)
    }
})
</script>