import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default BaseExtension.extend({
	props: ['startCollapsed'],
    data() {
        return {
			visible: false
        }
    },
	watch: {
		startCollapsed: {
			immediate: true,
			handler(val) {
				this.visible = !val
			}
		}
	},
    computed: {
        ...mapState('catalogue', {
            catalogue_defaults: state => state.catalogue_defaults
        })
    },
	methods: {
		...mapActions('catalogue', ['getCatalogueDefaults']),
	},
    async mounted() {
        await this.getCatalogueDefaults()

    }
})