import axios from 'axios'
import router from '@/router'

// const CSRF_COOKIE_NAME = 'XSRF-TOKEN'
// const CSRF_HEADER_NAME = 'X-CSRFToken'

// const session = axios.create({
	// xsrfCookieName: CSRF_COOKIE_NAME,
	// xsrfHeaderName: CSRF_HEADER_NAME,
// })

const instance = axios.create({
	paramsSerializer: {
		indexes: null // array indexes format (null - no brackets, false (default) - empty brackets, true - brackets with indexes)
	}
})

instance.interceptors.response.use(
  response => response,
  error => {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
  	if (error.response) {
			switch (error.response.status) {
				case 403: // forbidden error, logout the user

					if (router.currentRoute.path !== "/login") {
						if (router.currentRoute.query.redirect !== undefined) {
							router.push({ path: '/logout', query: { redirect: router.currentRoute.query.redirect} }).catch(() => {})
						}else{
							router.push('/logout').catch(() => {})
						}
					}
					return Promise.resolve(error)
				break
			}
		}
		
		return Promise.reject(error)
  })

export default instance