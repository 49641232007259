import { capitalize } from "./capitalize"
import { uppercase } from "./uppercase"
import { dateformat } from "./dateformat"
import { timeformat } from "./timeformat"
import { comma2dot } from "./comma2dot"
import { revision } from "./revision"

// Main plugin for installing all directive plugins
const directives = {
	capitalize,
	uppercase,
	dateformat,
	timeformat,
	comma2dot,
	revision
}

import { registerDirectives } from "bootstrap-vue/src/utils/plugins"

export default {
	install(Vue) {
		registerDirectives(Vue, directives)
	}
}
