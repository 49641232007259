<template>
<b-card no-body>
    <template v-slot:header>
        <i class="fa-solid fa-star"></i> Saved Templates
    </template>
    <b-list-group flush>
        <template v-if="hasTemplates">
            <TemplateItem v-for="(item, index) in templates" :item="item" :key="index" :is-active="activeTemplate === item.id" @deleted="onDeleted" @selected="onactive"/>
        </template>
        <template v-else>
            <b-list-group-item class="text-center text-muted">No templates</b-list-group-item>
        </template>
    </b-list-group>
</b-card>
</template>
<script>
import TemplateExtension from "./TemplateExtension"
import { mapGetters, mapState, mapActions } from 'vuex'
import TemplateItem from './TemplateItem'

export default TemplateExtension.extend({
    name: "lab-templates-saved",
    components: {
        TemplateItem,
    },
    data() {
        return {
            activeTemplate: null,
        }
    },
    computed: {
        ...mapGetters('lab', ['getUserDiagnosticTemplates']),
        templates() {
            return this.getUserDiagnosticTemplates()
        },
        hasTemplates() {
            return this.templates.length > 0
        }
    },
    methods: {
        ...mapActions('lab', ['getDiagnosticTemplates', 'deleteDiagnosticTemplate', 'setActiveTemplate']),
        onactive(template, templateId) {
            this.activeTemplate = this.activeTemplate === templateId ? null : templateId
            this.setActiveTemplate(this.activeTemplate ? template : null)
        },
        onDeleted(id) {
            this.deleteDiagnosticTemplate(id).then(() => {
                this.notify("Diagnostic referral template removed", "Diagnostic referral template was removed successfully")
            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
            })
            .finally(() => {
                this.activeTemplate = null
                this.setActiveTemplate(null)
            })
        },
        resetActive() {
            this.activeTemplate = null
            this.setActiveTemplate(null)
        }
    },
    async mounted() {
        await this.getDiagnosticTemplates()
    },
    created() {
        EventBus.$on('lab-reset-active-template', () => {
            this.resetActive()
        })
    }
})
</script>