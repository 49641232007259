<template>
<b-card>
    <TemplateSaved />
    <TemplateForm />
</b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import TemplateForm from './templates/TemplateForm'
import TemplateSaved from './templates/TemplateSaved'

export default ComponentExtension.extend({
    name: "lab-templates",
    components: {
        TemplateSaved,
        TemplateForm
    }
})
</script>