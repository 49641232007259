import Vue from 'vue'

export const AppActivity = {
    install(Vue, options) {

        var mountedModals = []

        const store = options && options.store
        const router = options && options.router
        let seconds = options && options.seconds
        let logoutLimit = options && options.logoutLimit

        if (!store) {
            console.error('Vuex store is required for the custom plugin.')
            return
        }

        if (!router) {
            console.error('Vue Router is required for the custom plugin.')
            return
        }

        if (!seconds) {
            seconds = 3000  // 50 MINUTES
        }

        if (!logoutLimit) {
            logoutLimit = 600  // 10 MINUTES
        }

        Vue.mixin({
            beforeCreate() {
                // Access the $root instance
                const rootInstance = this.$root

                // Subscribe to the $root event
                rootInstance.$on('bv::modal::show', (bvEvent, modalId) => {
                    mountedModals.push(modalId)
                })
            },

            beforeRouteLeave(to, from, next) {
                // Access the $root instance
                const rootInstance = this.$root

                mountedModals.map(id => {
                    try {
                        rootInstance.$emit('bv::hide::modal', id)
                    }
                    catch(err) {
                        //model already closed
                    }
                })

                next()
            }
        })

        Vue.prototype.$appactivity = new Vue({
            data() {
                return {
                    seconds: seconds,
                    logoutLimit: logoutLimit,
                    // seconds: 10,
                    // logoutLimit: 5,
                    timeRemaining: 0,
                    timerInterval: null,
                    mountedModals: [],
                    toastIsShowing: false
                }
            },
            methods: {
                forceLogout() {
                    this.hideToast()
                    clearInterval(this.timerInterval)
                    router.push('/logout').catch(() => {})
                },
                showToast() {
                    this.$bvToast.toast(`Your will be logged out shortly. Move the mouse to prevent from logging out.`, {
                        id: 'user-idle',
                        title: 'Inactive Warning',
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'warning',
                        noCloseButton: true,
                        noAutoHide: true
                    })

                    this.toastIsShowing = true
                },
                hideToast() {
                    this.$bvToast.hide('user-idle')
                    this.toastIsShowing = false
                },
                checkTimeRemaining () {
                    // console.log(this.timeRemaining)
                    if (this.timeRemaining < this.logoutLimit) {
                        if (this.timeRemaining < 0) {
                            clearInterval(this.timerInterval)
                            this.forceLogout()
                        }else{ 
                            //countdown is in the logout limit. show toast
                            if (!this.toastIsShowing) this.showToast()
                        }
                    }
                },
                activateTimer() {
                    this.timeRemaining = this.seconds

                    this.timerInterval = setInterval(function () {
                        this.timeRemaining -= 1
                         this.checkTimeRemaining()
                    }.bind(this), 1000)
                },
                deactivateTimer() {
                    clearInterval(this.timerInterval)
                    this.hideToast()
                },
                destroy() {
                    this.mountedModals.map(id => {
                        this.$root.$emit('bv::hide::modal', id)
                    })
                }
            },
            onIdle() {
                const { anonymous, bypass } = router.currentRoute.meta;
                if (!anonymous && !bypass) this.activateTimer()
            },
            onActive() {
                this.deactivateTimer()
                const { anonymous, bypass } = router.currentRoute.meta;
                if (!anonymous && !bypass) store.dispatch('auth/userActive')
            }
        })
    }
}