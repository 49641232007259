<template>
    <b-card class="card-accent-danger" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-syringe"></i> <strong>Vaccinations</strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE VACCINATION ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('vaccinations')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    borderless
                    striped
                    hover
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('vaccinations')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(ended)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? toLocaleDate(data.value) : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)" class="mr-1"><i class="fa-light fa-xmark"></i></b-button>
                    </template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>
                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="6" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer>
                <b-button @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <b-card class="mt-3">
                        <validation-observer ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
                        <validation-provider vid="vaccine_id" v-slot="{ errors }">
                            <b-form-group
                                label-cols-sm="2"
                                label-cols-lg="3"
                                label="Vaccine"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <vue-bootstrap-autocomplete name="vaccine_id" v-model="query" :data="vaccines" placeholder="* Search Vaccine..." :minMatchingChars="1" :serializer="vaccineSerializer" @hit="onHitVaccine">
                                    <template slot="suggestion" slot-scope="{ data, htmlText }">
                                        <div class="d-flex align-items-center">
                                            <!-- Note: the v-html binding is used, as htmlText contains
                                            the suggestion text highlighted with <strong> tags -->
                                            <span class="ml-1" v-html="htmlText"></span>
                                        </div>
                                    </template>
                                </vue-bootstrap-autocomplete>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider vid="dosage_form_id" v-slot="{ errors }">
                        <b-form-group
                            label-cols-sm="2"
                            label-cols-lg="3"
                            label="Dosage form"
                            label-for="dosage_form_id"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-select id="dosage_form_id" name="dosage_form_id" plain v-model="form.dosage_form_id">
                                <b-form-select-option disabled :value="null">* Select Dosage form</b-form-select-option>
                                <b-form-select-option v-for="dosage_form in dosage_forms" :key="dosage_form.id" :value="dosage_form.id">{{ dosage_form.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        </validation-provider>

                       <validation-provider vid="route_text_id" v-slot="{ errors }">
                        <b-form-group
                            label-cols-sm="2"
                            label-cols-lg="3"
                            label="Route"
                            label-for="route_text_id"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <!-- <b-input-group :append="routeID"> -->
                            <b-input-group>

                                <b-form-select id="route_text_id" name="route_text_id" plain v-model="form.route_text_id">
                                <b-form-select-option disabled :value="null">* Select Route Text</b-form-select-option>
                                <b-form-select-option v-for="route_text in route_texts" :key="route_text.id" :value="route_text.id">{{ route_text.name }}
                                </b-form-select-option>
                                </b-form-select>
                                <b-form-select disabled id="route_identifyer_id" name="route_identifyer_id" plain v-model="form.route_identifyer_id">
                                <b-form-select-option disabled :value="null"></b-form-select-option>
                                <b-form-select-option v-for="route_id in route_identifiers" :key="route_id.id" :value="route_id.id">{{ route_id.name }}
                                </b-form-select-option>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                        </validation-provider>
                       <validation-provider vid="administered_site_id" v-slot="{ errors }">
                        <b-form-group
                            label-cols-sm="2"
                            label-cols-lg="3"
                            label="Administered Site"
                            label-for="administered_site_id"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-select id="administered_site_id" name="administered_site_id" plain v-model="form.administered_site_id">
                                <b-form-select-option disabled :value="null">* Select Administered Site</b-form-select-option>
                                <b-form-select-option v-for="administered_site in administered_sites" :key="administered_site.id" :value="administered_site.id">{{ administered_site.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        </validation-provider>

                        <validation-provider name="Amount" vid="amount" rules="measurement" v-slot="{ errors }">
                            <b-form-group
                                label-cols-sm="2"
                                label-cols-lg="3"
                                label="Administered Amount"
                                label-for="adm-amount"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <b-input-group>
                                    <b-form-input type="number" id="adm-amount" v-comma2dot name="amount" placeholder=".00" v-model="form.amount" />
                                    <template #append>
                                        <b-form-select id="unit" name="unit" plain v-model="form.unit">
                                            <b-form-select-option disabled :value="null">Unit Amount</b-form-select-option>
                                            <b-form-select-option value="ml">ml</b-form-select-option>
                                            <b-form-select-option value="mg">mg</b-form-select-option>
                                            <b-form-select-option value="IU">IU/IE</b-form-select-option>
                                        </b-form-select>
                                    </template>    
                                </b-input-group>  
                                 <validation-provider vid="unit" v-slot="{ errors }">
                                    <b-form-invalid-feedback :state="errors[0] ? false : null">
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                </validation-provider>                                  
                            </b-form-group>
                        </validation-provider>

                        <validation-provider vid="start" v-slot="{ errors }">
                            <b-form-group
                                label-cols-sm="2"
                                label-cols-lg="3"
                                label="Vaccination Date"
                                label-for="start"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <b-form-row>
                                    <b-col>
                                        <b-form-datepicker id="start" placeholder="Set start date..." v-model="form.start" :locale="currentLang" :max="maxDate" @input="onStartSelected"></b-form-datepicker>
                                    </b-col>
                                    <b-col>
                                        <b-form-datepicker id="end" placeholder="Set end date..." v-model="form.end" :locale="currentLang" :min="form.start"></b-form-datepicker>
                                        
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </validation-provider>

                        <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                        <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

                        </b-form>
                        </validation-observer>
                    </b-card>
                </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "vaccinations",
    data() {
        return {
            addNew: false,
            form: null,
            fields: [
                { key: 'start', label: 'Start Date', class: 'align-middle', formatter: (value) => this.toLocaleDate(value) },
                { key: 'vaccine.name', label: 'Vaccine', class: 'align-middle text-left' },
                { key: 'dosage_form.name', label: 'Dosage form', class: 'align-middle text-left' },
                { key: 'route_full', label: 'Route', class: 'align-middle text-left' },
                { key: 'amount_full', label: 'Administered', class: 'align-middle text-center' },
                { key: 'administered_site.name', label: 'Administered Site', class: 'align-middle text-left' },
                { key: 'end', label: 'End Date', class: 'align-middle text-center', formatter: (value) => this.toLocaleDate(value) },
                
                { key: "actions", label: "", class: "align-middle text-center" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,
            
            query: "",

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            processing: false,
            selected: null,

            maxDate: new Date(),

            routeID: ""
        }
    },
    computed: {
        ...mapGetters('vaccinations', ['getPatientVaccinations']),
        items() {
            return this.getPatientVaccinations()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        ...mapState('catalogue', {
            vaccines: state => state.vaccines,
            dosage_forms: state => state.dosage_forms,
            route_identifiers: state => state.route_identifiers,
            route_texts: state => state.route_texts,
            administered_sites: state => state.administered_sites,
            side_effects_types: state => state.side_effects_types,
            side_effects_outcomes: state => state.side_effects_outcomes
        }),

        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                if(!this.bypass) {
                    this.isDirty = this.form.dirty()
                    this.hasErrors = !this.formValid

                    if (this.isDirty) {
                        this.dirty(this.$vnode.key)
                    }else{
                        if (this.formValid)
                            this.saved(this.$vnode.key)
                    }
                }
                this.bypass = false 
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        'form.route_text_id': {
            handler(id) {
                if(id) {
                    const rt = this.route_texts.find(item => item.id === id)
                    const ri = this.route_identifiers.find(item => item.code == rt.code)
                }else{
                    this.form.populate({route_identifyer_id: null})
                }
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('vaccinations', ['getVaccinationsByPatientId', 'createVaccination', 'deleteVaccination']),
        
        onRemoveItem(item) {
            this.confirm = true
            this.processing = false
            this.selected = item
        },
        onConfirmed(item) {
            this.processing = true
            this.deleteVaccination(item.id).then(() => {
                this.notify("Patient vaccination removed", "Patient vaccination was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.confirm = false
                    this.processing = false
                    this.selected = null
                }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },
        onStartSelected(date) {
            this.form.populate({end: date})
        },
        vaccineSerializer(item) {
            return item.name
        },
        onHitVaccine(item) {
            this.form.populate({vaccine_id: item.id})
        },
        onFormSubmit() {
            this.createVaccination(this.form.data()).then(() => {
                this.reset()
                this.notify("Vaccination created", "Vaccination was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                vaccine_id: null,
                dosage_form_id: defaults.vaccine_dosage_form,
                route_identifyer_id: defaults.vaccine_route_identifyer,
                route_text_id: defaults.vaccine_route_text,
                administered_site_id: null,
                side_effects_type_id: null,
                side_effects_outcome_id: null,
                amount: null,
                unit: defaults.vaccine_administered_unit,
                start: null,
                end: null,
                organization_id: null,
                consistency_token: null,
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.query = ""
                this.hasErrors = false
                this.addNew = false
                this.saved(this.$vnode.key)
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('vaccines')
        await this.getCatalogueItem('dosage_forms')
        await this.getCatalogueItem('route_identifiers')
        await this.getCatalogueItem('route_texts')
        await this.getCatalogueItem('administered_sites')
        await this.getCatalogueItem('side_effects_types')
        await this.getCatalogueItem('side_effects_outcomes')
        await this.getVaccinationsByPatientId()

    }
})
</script>