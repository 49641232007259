<template>
    <b-list-group>
        <ResultUploadForm :referral="referral" />

        <Columns :columns="3">
            <ReferralTestItem v-for="item in items" :item="item" :key="item.id"  />
        </Columns>
	</b-list-group>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import ReferralTestItem from './forms/ReferralTestItem'
import ResultUploadForm from './forms/ResultUploadForm'

import { Columns } from 'vue-columns'

export default ComponentExtension.extend({
	name: "lab-referrals-details",
    props: ['referral', 'items'],
    components: {
        ReferralTestItem,
        ResultUploadForm,
        Columns
    }
})
</script>