<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/free.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: './assets/fontawesome/webfonts';
  @import './assets/fontawesome/scss/fontawesome.scss';
  @import './assets/fontawesome/scss/solid.scss';
  @import './assets/fontawesome/scss/regular.scss';
  @import './assets/fontawesome/scss/light.scss';
  @import './assets/fontawesome/scss/sharp-light.scss';
  @import './assets/fontawesome/scss/sharp-regular.scss';
  @import './assets/fontawesome/scss/sharp-solid.scss';
  // /* Import Simple Line Icons Set */
  // $simple-line-font-path: '~simple-line-icons/fonts/';
  // @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';

  /* Import Main styles for this application  */
  @import './assets/scss/style';

</style>