<template>
	<b-overlay :show="$store.getters['loading/isLoading']('viewcreator')" spinner-variant="primary">
		<template v-if="viewMode === 'render'">
			<template v-for="row in components">
				<template v-if="row.children.length > 1">
					<b-row>
						<b-col cols="12" md="6" v-for="element in row.children" :key="element.tag">
							<component :is="element.tag" :start-collapsed="element.collapsed"></component>
						</b-col>
					</b-row>
				</template>
				<template v-else>
					<component v-for="element in row.children" :is="element.tag" :key="element.tag" :start-collapsed="element.collapsed"></component>
				</template>
			</template>
		</template>
		<template v-else-if="viewMode === 'no-template'">
			<b-alert show variant="warning">
				<h4 class="alert-heading">No Template Found</h4>
				<p>No template had been created for this view.</p>
				<hr>
				<p class="mb-0">
					<b-button variant="warning" @click="setViewMode('new')"><i class="fa-regular fa-circle-dot" aria-hidden="true"></i> Create</b-button>
					&nbsp<b-button variant="warning" @click="createDefault"><i class="fa-solid fa-paper-plane" aria-hidden="true"></i> Use Default</b-button>
				</p>
			</b-alert>
		</template>
		<template v-else>
			<vc-template-builder :view-mode="viewMode" @done="setViewMode('render')"></vc-template-builder>
		</template>
	</b-overlay>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"

export default {
	name: 'vc-view-template',
	props: {
		view: {
			type: String,
			required: true,
			default: ''
		}
	},
	data() {
		return {
			viewMode: 'render'
		}
	},
	computed: {
        ...mapGetters('viewcreator', ['getDefaultTemplate', 'getUserTemplateComponents']),
        defaultTemplate() {
        	return this.getDefaultTemplate()
        },
        components() {
            return this.getUserTemplateComponents()
        }
	},
	methods: {
		...mapActions('viewcreator', ['setCurrentViewAttributes', 'createUserTemplate']),
		setViewMode(mode) {
			this.viewMode = mode
		},
		createDefault() {
			const cps = [...this.defaultTemplate.components]
			const indexed = cps.map((c, i)=> ({index: ++i, children: [{...c, collapsed: false }]}))
			const form = Form.create({ view: this.defaultTemplate.id, components: indexed, consistency_token: null })
			this.createUserTemplate(form.data()).then(template => {
				this.setViewMode('render')
            })
        },
	},
	async created() {
		const currentView = await this.setCurrentViewAttributes(this.view)
		this.setViewMode(currentView)

		EventBus.$on('edit-vc-template', () => {
			this.setViewMode('edit')
		})
	}
}
</script>