import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            unSaved: []
        }
    },
    computed: {
        currentView() {
            return this.$options.name
        }
    },
    methods: {
        notify(title, msg, variant = 'success') {
            EventBus.$emit('showToast', { title: title, message: msg, variant: variant})
        },
        dirty(id) {
            EventBus.$emit('componentDirty', id)
        },
        saved(id) {
            EventBus.$emit('componentSaved', id)
        }
    }
})