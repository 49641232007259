<template>
<b-tab lazy class="pt-0 px-0">
    <template slot="title">
        <i class="fa-regular" :class="faIcon"></i> {{title}}
    </template>
    <b-overlay :show="confirm" no-wrap @shown="onShown">
        <template #overlay>
            <div v-if="processing" class="d-flex align-items-center">
                <strong>Trashing...</strong>
                <b-spinner class="ml-auto"></b-spinner>
            </div>
            <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                <p class="text-center">
                    <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE MESSAGE ON {{ toLocaleDate(selected?.created) }} BY {{ selected?.type?.toUpperCase() }} </strong>
                </p>
                <div class="text-center">
                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                    <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                </div>
            </div>
        </template>
    </b-overlay>
    <b-table
        ref="table"
        thead-class="d-none"
        class="m-0"
        responsive
        show-empty
        hover
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :items="itemsProvider"
        :fields="fields"
        :filter-function="filter"
        @row-hovered="onHovered"
        @row-unhovered="active = null"
        @row-clicked="rowClicked"
        :no-sort-reset="true"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        :no-border-collapse="false"
        :tbody-tr-class="rowClass">

        <template v-slot:cell(type)="data">
                <b-badge variant="secondary" class="mr-1">{{ data.value }}</b-badge>
        </template>

        <template v-slot:cell(message)="data">
            <div class="d-flex align-items-center">
                <b-badge v-if="!data.item.is_read" variant="primary" class="mr-1">new</b-badge>
                <span :class="{'font-weight-bold': !data.item.is_read}" class="text-wrap">{{ data.item.message }}</span>
            </div>
        </template>

        <template #table-colgroup="scope">
            <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'message' ? '300px' : '30px' }"
            >
        </template>

        <template v-slot:empty="scope">
            <div class="text-center my-2">
                <span>No messages</span>
            </div>
        </template>
        
        <template v-slot:emptyfiltered="scope">
            <div class="text-center my-2">
                <span>No messages found matching your criteria</span>
            </div>
        </template>

        <template v-slot:cell(created)="data">
            <b-overlay :show="data.item === active" variant="transparent">
                <template #overlay>
                    <b-button-group size="sm">
                        <b-button v-if="data.item.type !== 'system'" variant="primary"><i class="fa-solid fa-reply"></i></b-button>
                        <b-button variant="secondary" class="mx-1" @click="markReadUnread(data.item)">
                            <i class="fa-solid" :class="data.item.is_read ? 'fa-envelope' : 'fa-envelope-open'"></i>
                        </b-button>
                        <b-button variant="danger" @click="onRemoveItem(data.item)"><i class="fa-solid fa-trash-can"></i></b-button>
                    </b-button-group>
                </template>
                <span :class="{'invisible': data.item === active }">{{data.value}}</span>
            </b-overlay>
        </template>
    </b-table>
</b-tab>
</template>
<script>
import BaseExtension from "@/views/BaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default BaseExtension.extend({
    name: 'inbox-tab',
    props: ['title', 'faIcon', 'typeFiltering'],
    data () {
        return {
            fields: [
                // { key: 'id', label: '#', class: 'align-middle', },
                { key: 'message', label: 'Message', class: 'align-middle text-truncate', },
                { key: 'type', label: 'Type', class: 'text-right align-middle', },
                { key: 'created', label: 'Date', class: 'text-right align-middle', formatter: (value) => this.dynamicDateTime(value) },
                // { key: 'actions', label: '', class: 'text-right align-middle' }
            ],
            isBusy: false,
            currentPage: 1,
            perPage: 10,
            sortedBy: 'id',
            sortedDesc: true,

            filters: {
                internal_number: null,
                is_deleted: false,
                type: this.typeFiltering
            },

            startIndex: 0,
            endIndex: 0,
            totalRows: 0,

            confirm: false,
            processing: false,
            selected: null,
            active: null
        }
    },
    methods: {
        ...mapActions('messaging', ['filterMessages', 'updateMessage']),
        onRemoveItem(item) {
            this.confirm = true
            this.processing = false
            this.selected = item
        },
        onConfirmed(item) {

            this.processing = true

            this.updateMessage({id: item.id, form: {is_deleted: true}}).then(() => {
                this.notify("Message deleted", "Message was deleted successfully")
                this.confirm = false
                this.processing = false
                this.selected = null
                this.$refs.table.refresh()

            })
            .catch((errors) => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.processing = false
                this.selected = null
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.is_read) return 'table-secondary'
        },

        async markReadUnread(item) {
            await this.updateMessage({id: item.id, form: {is_read: !item.is_read}})
            this.$refs.table.refresh()
        },

        async rowClicked(item, index, event) {
            if (!item.is_read) {
                await this.updateMessage({id: item.id, form: {is_read: true}})
                this.$refs.table.refresh()
            }
        },
        async itemsProvider(ctx) {
            try {
                this.filters['page'] = ctx.currentPage
                this.filters['per_page'] = ctx.perPage
                this.filters['sort_by'] = (ctx.sortDesc ? '-' : '') + ctx.sortBy
                const response = await this.filterMessages(this.filters)
                this.startIndex = response.start_index
                this.endIndex = response.end_index
                this.totalRows = response.tot_rows
                return response.items
            } catch (error) {
                return []
            }
        },
        filter() {
            this.$refs.table.refresh()
        },
        onHovered(item, index, event) {
            this.active = item
        }
    },

    mounted() {
        EventBus.$on('refreshMessages', () => {
            this.filter()
        })
    },
    beforeDestroy() {
        EventBus.$off("refreshMessages")
    }
})
</script>