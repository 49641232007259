<template>
    <b-tab lazy title-item-class="mb-1" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
        <template slot="title">
            <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Permissions
        </template>
        <b-form-group
            label="User Role Permissions"
            label-size="lg"
            label-class="font-weight-bold pt-0">
            <b-card no-body>
            <b-tabs pills card vertical content-class="border-right-0 border-bottom-0 border-left-1 border-top-1">
                <b-tab lazy v-for="role in groupRoles" :title="role.name" :key="`group-roles-${role.id}`">
                    <b-row>
                        <b-col>
                            <b-form-group
                                label="View Permissions">
                                <b-input-group v-for="permission in permissions.view_permissions" :key="`view-permissions-${permission.id}`">
                                    <b-form-input class="mb-1" size="sm" disabled type="text" :value="permission.name"  />
                                    <b-input-group-append>
                                        <b-button size="sm" class="mb-1" variant="light" @click="setPermission(role.id, permission)">
                                            <i :class="{ 'text-secondary fa-regular fa-ban': !hasPermission(role.id, permission), 'text-success fa-solid fa-check': hasPermission(role.id, permission) }"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                label="Add Permissions">
                                <b-input-group v-for="permission in permissions.add_permissions" :key="`add-permissions-${permission.id}`">
                                    <b-form-input class="mb-1" size="sm" disabled type="text" :value="permission.name"  />
                                    <b-input-group-append>
                                        <b-button size="sm" class="mb-1" variant="light" @click="setPermission(role.id, permission)">
                                            <i :class="{ 'text-secondary fa-regular fa-ban': !hasPermission(role.id, permission), 'text-success fa-solid fa-check': hasPermission(role.id, permission) }"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                label="Change Permissions">
                                <b-input-group v-for="permission in permissions.change_permissions" :key="`change-permissions-${permission.id}`">
                                    <b-form-input class="mb-1" size="sm" disabled type="text" :value="permission.name"  />
                                    <b-input-group-append>
                                        <b-button size="sm" class="mb-1" variant="light" @click="setPermission(role.id, permission)">
                                            <i :class="{ 'text-secondary fa-regular fa-ban': !hasPermission(role.id, permission), 'text-success fa-solid fa-check': hasPermission(role.id, permission) }"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                label="Delete Permissions">
                                <b-input-group v-for="permission in permissions.delete_permissions" :key="`delete-permissions-${permission.id}`">
                                    <b-form-input class="mb-1" size="sm" disabled type="text" :value="permission.name"  />
                                    <b-input-group-append>
                                        <b-button size="sm" class="mb-1" variant="light" @click="setPermission(role.id, permission)">
                                            <i :class="{ 'text-secondary fa-regular fa-ban': !hasPermission(role.id, permission), 'text-success fa-solid fa-check': hasPermission(role.id, permission) }"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
            </b-card>
        </b-form-group>
    </b-tab>
</template>
<script>
import BaseExtension from "@/views/BaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default BaseExtension.extend({
    name: 'user-permissions',
    data() {
        return {
            form: new Form({
                view_permissions: [],
                add_permissions: [],
                change_permissions: [],
                delete_permissions: []
            }),
            isLocked: true,
            isDirty: false,
            hasErrors: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['getPermissions', 'getRoles']),
        permissions() {
            return this.getPermissions()
        },
        groupRoles() {
            return this.getRoles()
        }
    },
    methods: {
        ...mapActions("auth", ["getFrontendPermissions", 'getFrontendRoles', 'updateFrontendRoles']),
        hasPermission(id, permission) {
            const role = this.groupRoles.find(role => role.id === id)

            if (role) {
                return role.permissions.some(p => p.id === permission.id)
            }

            return false
        },
        async setPermission(id, permission) {
            const role = this.groupRoles.find(role => role.id === id)

            if (role) {
                const hasPermission = role.permissions.some(p => p.id === permission.id)
                if (hasPermission) {
                    const newPermissions = role.permissions.filter(p => p.id !== permission.id)
                    const newPermissionsIds = newPermissions.map(p => p.id)

                    await this.updateFrontendRoles({id: id, data: { permission_ids: newPermissionsIds }})

                }else{
                    const newPermissions = [...role.permissions]
                    const newPermissionsIds = newPermissions.map(p => p.id)
                    newPermissionsIds.push(permission.id)

                    await this.updateFrontendRoles({id: id, data: { permission_ids: newPermissionsIds }})
                }   
            }
            
        }
    },
    async mounted() {
        await this.getFrontendPermissions()
        await this.getFrontendRoles()
    }
})
</script>