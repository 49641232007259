<template>
	<b-card class="card-accent-info" header-tag="header" footer-tag="footer" no-body>
		<template v-slot:header>
		    <b-button-group>
		      <b-button variant="primary" :pressed="!showDecursus" @click="showDecursus = false"><i class="fa-regular fa-clock"></i> PMH</b-button>
		      <b-button variant="primary" :pressed="showDecursus" @click="showDecursus = true"><i class="fa-regular fa-notes-medical"></i> Previous Decursus</b-button>
		    </b-button-group>

			<div class="card-header-actions">
				<b-link class="card-header-action btn-setting" @click="$emit('minimized', true)"><i class="fa-regular fa-xmark"></i></b-link>
			</div>
		</template>
		<PreviousDecursus v-if="showDecursus" />
        <PMH v-else/>
	</b-card>
</template>
<script>
import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapState, mapActions } from "vuex"
import PreviousDecursus from "./PreviousDecursus"
import PMH from "../pmh/PMH"

export default BaseExtension.extend({
	name: "decursus-past-information",
	components: {
		PreviousDecursus,
		PMH
	},
	data() {
		return {
			showDecursus: false
		}
	}
})
</script>