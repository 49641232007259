import session from '@/shared/session'
import _ from 'lodash'

// initial state
const initialState = {
	plugins: [],
	current: {
		id: null,
		demographics: {},
		memo: {},
		medicalcards: [],
		decursus: [],
		logs: [],
		exports: [],
		current_consult: null,
	}
}

const state = _.cloneDeep(initialState)

// getters
const getters = {
	getPatientCurrentConsult: (state) => () => {
		return state.current.current_consult
	},
	getPatientDemographics: (state) => () => {
		return state.current.demographics
	},
	getPatientMemo: (state) => () => {
		return state.current.memo
	},
	getPatientMedicalCards: (state) => () => {
		return state.current.medicalcards
	},
	getPatientLogs: (state) => () => {
		return state.current.logs
	},
	getPatientExports: (state) => () => {
		return state.current.exports
	},
}

// actions
const actions = {
	async resetState ({ commit, dispatch }) {
			// await dispatch("insurances/resetState", null, { root: true })
			commit('reset_state')
			return
	},

	async filterPatients({ commit }, data) {
		const patients = await session
			.get("/api/patients/", {
				params: data,
			})
		return patients.data
	},

	async getMedicalCardsForCurrentPatient({ commit, state }) {
		const medicalcards = await session
			.get(`/api/patients/${state.current.id}/medicalcards/`)

		commit("set_current", {key: 'medicalcards', value: medicalcards.data})

		return state.current.medicalcards
	},

	async getMemoForCurrentPatient({ commit, state }) {
		const memo = await session
			.get(`/api/patients/${state.current.id}/memo/`)

		commit("set_current", {key: 'memo', value: memo.data})

		return state.current.memo
	},

	async getDecursusForCurrentPatient({ commit, state }) {
		const decursus = await session
			.get(`/api/patients/${state.current.id}/decursus/`)

		commit("set_current", {key: 'decursus', value: decursus.data})

		return state.current.decursus
	},

	async getLogsForCurrentPatient({ commit, dispatch, state }) {
		dispatch("loading/load", "logs", { root: true })
		const logs = await session
			.get(`/api/patients/${state.current.id}/logs/`)

		commit("set_current", {key: 'logs', value: logs.data})
		dispatch("loading/end", "logs", { root: true })
		return state.current.logs
	},

	async getExportsForCurrentPatient({ commit, dispatch, state }) {
		dispatch("loading/load", "exports", { root: true })
		const exports = await session
			.get(`/api/patients/${state.current.id}/exports/`)

		commit("set_current", {key: 'exports', value: exports.data})
		dispatch("loading/end", "exports", { root: true })
		return state.current.exports
	},

	async getRunningExportsForCurrentPatient({ commit, dispatch, state }) {
		const exports_running = await session
			.get(`/api/patients/${state.current.id}/running_exports/`)

		return exports_running.data.running
	},

	async getPatientById({ commit, dispatch, state }, id) {
		const [demographics, memo, medicalcards, logs, current_consult] = await Promise.all([
			session.get(`/api/patients/${id}/`),
			session.get(`/api/patients/${id}/memo/`),
			session.get(`/api/patients/${id}/medicalcards/`),
			session.get(`/api/patients/${id}/logs/`),
			session.get(`/api/patients/${id}/current_consult/`),
		])

		await dispatch("insurances/getInsurancesByPatientId", id, { root: true })

		commit("set_current", {key: 'id', value: id})
		commit("set_current", {key: 'demographics', value: demographics.data})
		commit("set_current", {key: 'memo', value: memo.data})
		commit("set_current", {key: 'medicalcards', value: medicalcards.data})
		commit("set_current", {key: 'logs', value: logs.data})
		commit("set_current", {key: 'current_consult', value: current_consult.data})

		return state.current
	},

	exportCurrentPatient({ commit, state, dispatch }, { type, episodes }) {
		dispatch("loading/load", "export_patient", { root: true })
		return session
				.post(`/api/patients/${state.current.id}/export/`, {type: type, episodes: episodes})
				.then(() => {
					return dispatch("getExportsForCurrentPatient")
				})
	      .finally(() => {
	        dispatch("loading/end", "export_patient", { root: true })
	      })
	},

	createPatient({ commit, state, dispatch }, data) {
		dispatch("loading/load", "create_patient", { root: true })
		return session
				.post(`/api/patients/`, data)
				.then((demographics) => {
					commit("set_current", {key: 'id', value: demographics.data.id})
					commit("set_current", {key: 'demographics', value: demographics.data})

					return state.current
				})
	      .finally(() => {
	        dispatch("loading/end", "create_patient", { root: true })
	      })
	},

	updatePatient({ commit, state, dispatch }, data) {
		dispatch("loading/load", "update_patient", { root: true })
		return session
			.put(`/api/patients/${state.current.id}/`, data)
			.then((demographics) => {
				commit("set_current", {key: 'demographics', value: demographics.data})

				return demographics.data
			})
      .finally(() => {
        dispatch("loading/end", "update_patient", { root: true })
      })
	},

	async getPatientLastConsultation({ commit }, id) {
		const last_consult  = await session
			.get(`/api/patients/${id}/last_consult/`)
		return last_consult.data
	},

	async getPatientCurrentConsultation({ commit }, id) {
		const current_consult  = await session
			.get(`/api/patients/${id}/current_consult/`)
		return current_consult.data
	},

	async getPatientFutureConsultations({ commit }, id) {
		const consultations  = await session
			.get(`/api/patients/${id}/future_consults/`)
		return consultations.data
	},

	createPatientMemo({ commit, state, dispatch }, data) {
		dispatch("loading/load", "memo", { root: true })
		return session
			.post(`/api/patients/memo/`, {...data, patient: state.current.id})
			.then(memo => {
				commit("set_current", {key: 'memo', value: memo.data})

			})
		.finally(() => {
				dispatch("loading/end", "memo", { root: true })
		})
	},

	updatePatientMemo({ commit, dispatch }, { id , data}) {
		dispatch("loading/load", "memo", { root: true })
		return session
				.put(`/api/patients/memo/${id}/`, data)
				.then(memo => {
					commit("set_current", {key: 'memo', value: memo.data})

				})
		.finally(() => {
				dispatch("loading/end", "memo", { root: true })
		})
	},

	uploadMedicalCard({ commit, dispatch }, data) {
    dispatch("loading/load", "medicalcards", { root: true })
		return session
			.post(`/api/patients/medicalcards/`, data)
			.then(() => {
				return dispatch("getMedicalCardsForCurrentPatient")
			})
			.finally(() => {
				dispatch("loading/end", "medicalcards", { root: true })
			})
	},

	deleteMedicalCard({ commit, dispatch }, id) {
    	dispatch("loading/load", "medicalcards", { root: true })
		return session
			.delete(`/api/patients/medicalcards/${id}/`)
			.then(() => {
				return dispatch("getMedicalCardsForCurrentPatient")
			})
			.finally(() => {
				dispatch("loading/end", "medicalcards", { root: true })
			})
	},

	updatePatientStatus({ commit, dispatch }, status) {
		dispatch("loading/load", "update_patient", { root: true })
		return session
		.patch(`/api/patients/${state.current.id}/status/`, {blocked: status})
		.then((demographics) => {
			commit("set_current", {key: 'demographics', value: demographics.data})

			return demographics.data
		})
    .finally(() => {
      dispatch("loading/end", "update_patient", { root: true })
    })

	},

}

// mutations
const mutations = {
	set_plugins(state, plugins) {
		state.plugins = plugins
	},
	set_current(state, obj) {
		state.current[obj.key] = obj.value
	},
	reset_state(state) {
		Object.assign(state['current'], initialState['current'])
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
