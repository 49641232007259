<template>
		<FullCalendar ref="fullCalendar" :options="calendarOptions" />
</template>

<script>
import moment from "moment"
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from "@fullcalendar/interaction"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import bootstrapPlugin from "@fullcalendar/bootstrap"
import { toMoment, toDuration } from "@fullcalendar/moment"

import nlLocale from "@fullcalendar/core/locales/nl"
import enLocale from "@fullcalendar/core/locales/en-gb"

export default {
	name: "base-calendar",
	components: {
		FullCalendar,
	},
	props: {
		selectAllow: { 
			default() {
				return (info) => { return true }
			},
			type: Function 
		},
		eventAllow: { 
			default() {
				return (info) => { return true }
			},
			type: Function 
		},
		classNames: { 
			default() {
				return (args) => {}
			},
			type: Function 
		},

		eventSources: {
			default() {
				return []
			}
		},
		editable: {
			default() {
				return false
			}
		},
		selectable: {
			default() {
				return false
			}
		},
		droppable: {
			default() {
				return false
			}
		},
		allDaySlot: {
			default() {
				return true
			}
		},
		allDayContent: {
			default() {
				return "all-day"
			}
		},
		noEventsContent: {
			default() {
				return "No events to display"
			}
		},
		header: {
			default() {
				return {
					left: 'prev,next today',
					center: 'title',
					right: 'timeGridDay,timeGridWeek,dayGridMonth'
				}
			}
		},
		footer: {
			default() {
				return {
					left: 'prev,next today',
					center: '',
					right: 'timeGridDay,timeGridWeek,dayGridMonth'
				}
			}
		},
		customButtons: {
			default() {
				return {}
			}
		},
		defaultView: {
			default() {
				return "timeGridDay"
			}
		},
		defaultDate: {
			default() {
				return new Date()
			}
		},
		sync: {
			default() {
				return false
			}
		},
		config: {
			default() {
				return {}
			}
		},
		locale: {
			default() {
				return this.currentLang
			}
		}
	},
	data() {
		return {
			calendarOptions: {
				plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, multiMonthPlugin, interactionPlugin, bootstrapPlugin, momentTimezonePlugin ],
				themeSystem: 'bootstrap',
				height: "auto",
				initialView: this.defaultView,

				initialDate: this.defaultDate,
				
				customButtons: this.customButtons,

				headerToolbar: this.header,
				footerToolbar: this.footer,
				navLinks: true, // can click day/week names to navigate views

				locales: [ enLocale, nlLocale ],
				locale: this.locale, // the initial locale. if not specified, uses the first one
				// timeZone: "America/Paramaribo",
				firstDay: 1,
				// titleFormat: (dateObj) => {
				//     return moment(dateObj.date).locale('nl').format('DD MMMM YYYY')
				// },
				// titleFormat: 'dddd, MMMM D, YYYY', // with moment plugin

				// slotLabelInterval: "00:05",
				// slotMinTime: "07:00:00",
				// slotMaxTime: "19:00:00",
				// slotDuration: "00:05",
				slotLabelFormat: {
					hour12: false,
					hour: "2-digit",
					minute: "2-digit",
					omitZeroMinute: false,
					meridiem: false
				},
				selectConstraint: "businessHours",
				eventConstraint: "businessHours", // events can only be dropped/resized on businesshours
				// businessHours: {
				// 	// days of week. an array of zero-based day of week integers (0=Sunday)
				// 	daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday

				// 	startTime: "07:30",
				// 	endTime: "18:30"
				// },
				
				// businessHours: [ // specify an array instead
				//   {
				//     daysOfWeek: [ 1, 2, 3 ], // Monday, Tuesday, Wednesday
				//     startTime: '08:00', // 8am
				//     endTime: '18:00' // 6pm
				//   },
				//   {
				//     daysOfWeek: [ 4, 5 ], // Thursday, Friday
				//     startTime: '10:00', // 10am
				//     endTime: '16:00' // 4pm
				//   }
				// ],

				weekNumbers: true,
				weekNumberCalculation: "ISO",
				fixedWeekCount: true, //default
				weekends: true,
				noEventsContent: this.noEventsContent,
				displayEventTime: true,
				displayEventEnd: true,
				nowIndicator: true,
				dayMaxEvents: true,
				dayMaxEventRows: true,
				showNonCurrentDates: false,
				eventTimeFormat: {
					hour12: false,
					hour: "2-digit",
					minute: "2-digit",
					omitZeroMinute: false,
					meridiem: false
				},
	            buttonText: {
	                listDay: 'Day',
	                listWeek: 'Week'
	            },
				// listDayFormat: {
				// 	day: "2-digit",
				// 	month: "long",
				// 	year: "numeric"
				// },
				// listDaySideFormat: {
				// 	hour12: false,
				// 	hour: "2-digit",
				// 	minute: "2-digit",
				// 	omitZeroMinute: false,
				// 	meridiem: false
				// },
				editable: this.editable,
				// selectable: this.selectable,
				// droppable: this.droppable,
				selectMirror: true,
				
				allDaySlot: this.allDaySlot,
				allDayContent: this.handleAllDayContent,
								
				views: {
				    dayGrid: {
				      // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
						selectable: false,
				      	droppable: this.droppable
				    },
				    timeGrid: {
						selectable: this.selectable,
						droppable: this.droppable
				      // options apply to timeGridWeek and timeGridDay views
				    },
				    listDay: {
						selectable: false,
						droppable: false
				    },
					// day: {
					// 	titleFormat: (date) => {
					// 		return toMoment(date.date, this.$refs.fullCalendar.getApi()).format("ddd DD/MM")
					// 		// return moment(date.date).locale('nl').format('dddd')
					// 		// let d = toMoment(date.date, this.$refs.fullCalendar.getApi()).format("DD MMMM YYYY")
					// 		// return d
					// 	},
					// 	dayHeaderFormat: (date) => {
					// 		return toMoment(date.date, this.$refs.fullCalendar.getApi()).format("ddd DD/MM")
					// 		// return moment(date.date).locale('nl').format('dddd')
					// 		// let d = toMoment(date.date, this.$refs.fullCalendar.getApi()).format("dddd")
					// 		// return d
					// 	}
					// },

					// week: {
					// 	dayHeaderFormat: (date) => {
					// 		// return moment(date.date).locale('nl').format('ddd DD/MM')
					// 		return toMoment(date.date, this.$refs.fullCalendar.getApi()).format("ddd DD/MM")
					// 		// return d
					// 	}
					// }
				},

				selectAllow: this.selectAllow,
				eventAllow: this.eventAllow,
				eventClassNames: this.classNames,				
				select: this.handleDateSelect,
				eventClick: this.handleEventClick,
				eventDrop: this.handleEventDrop,
				eventReceive: this.handleEventReceive,
				eventResize: this.handleEventResize,
				eventDidMount: this.handleEventDidMount,
				eventOverlap: this.handleEventOverlap,
				eventSources: this.eventSources,
				windowResize: this.handleWindowResize
			}
		}
	},
	methods: {
		getApi() {
			return this.$refs.fullCalendar.getApi()
		},
		gotoDate(date) {
			this.getApi().gotoDate(date)
		},
		addEvent(event) {
			this.getApi().addEvent(event)
		},
		setOption(prop, val) {
			this.getApi().setOption(prop, val)
		},
		changeView(view) {
			this.getApi().changeView(view)
		},
		refetchResources() {
			const calendar = this.getApi()
			calendar.batchRendering(() => {
				const sources = calendar.getEventSources()
				sources.map(source => {
				    source.refetch()
				})
			})
			// this.getApi().refetchResources() // fullcalendar premium only
		},
		refetchResource(resourceId) {
			const calendar = this.getApi()
			const resource = calendar.getEventSourceById( resourceId )
			resource.refetch()
		},
		refetchEvents() {
			const calendar = this.getApi()
			calendar.batchRendering(() => {
				calendar.removeAllEvents()
				calendar.refetchEvents()

				// var sources = this.calendar.getEventSources()
				//     sources.map(source => {
				//     source.refetch()
				// })
			})
		},
		addEventSource(resource) {
			const calendar = this.getApi()
			calendar.addEventSource( resource )
		},
		removeEventResources(exclude) {
			const calendar = this.getApi()
			var sources = calendar.getEventSources()
		    sources.map(source => {
		    	if (!exclude.includes(source.id)) {
		    		source.remove()
		    	}
			})
		},
		handleAllDayContent(args) {
			 return this.allDayContent
		},
		// handleSelectAllow(info) {
		// 	return this.selectAllow(info)
		// },
		// handleEventAllow(info) {
		// 	return eventAllow(info)
		// },
		// handleEventClassNames(args) {
		// 	return this.classNames(args)
		// },
		handleDateSelect(selectInfo) {
			this.$emit('select', selectInfo)
		},
		handleEventClick(clickInfo) {
			this.$emit('event-click', clickInfo)
		},
		handleEventDrop(dropInfo) {
			this.$emit('event-drop', dropInfo)
		},
		handleEventDidMount(info) {
			this.$emit('event-did-mount', info)
	    },
		handleEventOverlap(stillEvent, movingEvent) {
			this.$emit('event-overlap', stillEvent, movingEvent)
		},
		handleEventReceive(receiveInfo) {
			this.$emit('event-receive', receiveInfo)
		},
		handleEventResize(resizeInfo) {
			this.$emit('event-resize', resizeInfo)
		},
		handleWindowResize(arg) {
			this.$emit('window-resize', arg)
		},

		// addEventSource(id, source) {
		// 	this.calendar.addEventSource({ id: id, events: [source] })
		// 	this.gotoDate(source["start"])
		// },
		// removeEventSourceById(id) {
		// 	this.calendar.batchRendering(() => {
		// 		var oldSources = this.calendar.getEventSources()
		// 		oldSources.map((source) => {
		// 			if (source.id == id) {
		// 				source.remove()
		// 				this.rerenderEvents()
		// 			}
		// 		})
		// 	})
		// },
		// rebuildSources() {
		// 	this.calendar.batchRendering(() => {
		// 		var oldSources = this.calendar.getEventSources()
		// 		oldSources.map((source) => {
		// 			source.remove()
		// 		})

		// 		this.eventSources.map((source) => {
		// 			this.calendar.addEventSource(source)
		// 		})
		// 	})
		// },
		// refetchEvents() {
		// 	this.calendar.batchRendering(() => {
		// 		this.calendar.removeAllEvents()
		// 		this.calendar.refetchEvents()

		// 		// var sources = this.calendar.getEventSources()
		// 		//     sources.map(source => {
		// 		//     source.refetch()
		// 		// })
		// 	})
		// },
		// rerenderEvents() {
		// 	this.calendar.rerenderEvents()
		// }
		// fireMethod(...options) {
		//     return this.calendar(...options)
		// },
	},
	watch: {
		// eventSources: {
		// 	deep: true,
		// 	handler(val) {
		// 		// this.rebuildResources()
		// 		this.refetchEvents()
		// 	}
		// },
		selectable: {
			handler(val) {
				this.setOption("selectable", val)
			}
		},
		editable: {
			handler(val) {
				this.setOption("editable", val)
			}
		},
		droppable: {
			handler(val) {
				this.setOption("droppable", val)
			}
		},
		defaultView: {
			handler(val) {
				this.changeView(val)
			}
		},
		config: {
			handler(options) {
				if (Object.keys(options).length > 0) {
					const calendar = this.getApi()
					calendar.batchRendering(() => {
						Object.keys(options).map(option => {
							calendar.setOption(option, options[option])
						})
					})

					this.$emit('config-loaded')
				}
			}
		}
	}
}
</script>