<template>
	<b-modal size="lg" title="Referrals" v-model="showModal" no-close-on-esc no-close-on-backdrop body-class="p-0" modal-class="modal-info" ok-variant="info" :ok-only="true" ok-title="Dismiss" @hidden="onHidden">
		<b-container fluid>
			<b-overlay :show="$store.getters['loading/isLoading']('referrals')" rounded="sm">

		        <b-table ref="table" borderless striped responsive show-empty :items="list" :fields="fields" sort-by="created" sort-desc>
		            <div slot="table-busy" class="text-center text-danger my-2">
		                <b-spinner class="align-middle"></b-spinner>
		                <strong>Loading...</strong>
		            </div>

		            <template v-slot:empty="scope">
		                <div class="small text-muted text-center my-2">
		                    <span>n/a</span>
		                </div>
		            </template>

		            <template v-slot:cell(actions)="row">
		                <b-button-group size="sm">
		                    <b-button v-if="row.item.printable" variant="secondary" @click="printRow(row)"><i class="fa-solid fa-print"></i></b-button>
		                    <b-button variant="primary" @click="row.toggleDetails"><i class="fa fa-ellipsis-h"></i></b-button>
		                </b-button-group>
		            			            </template>

		            <template #row-details="row">
		            	<h6>Reason for referral: </h6>
		               <span class="small" v-html="row.item.reason"></span>
		            </template>
		        </b-table>
			</b-overlay>
		</b-container>
	</b-modal>
</template>
<script>
import Vue from "vue"
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapGetters, mapState, mapActions } from "vuex"
import _ from 'lodash'
import printJS from 'print-js'

export default ModalExtension.extend({
	name: 'referral-modal',
	data() {
		return {
			list: [],
            fields: [
                { key: 'created', label: 'Date/Time', class: 'align-middle small', formatter: (value) => this.toLocaleDate(value) },
                { key: 'physician.name', label: 'Referrer', class: 'align-middle small text-center' },
                { key: 'type.name', label: 'Type', class: 'align-middle small text-center' },
                { key: 'priority.name', label: 'Priority', class: 'align-middle small text-center' },
                { key: 'receiving', label: 'Receiving', class: 'align-middle small text-center' },
                { key: 'consulting_doctor', label: 'Consulting', class: 'align-middle small text-center', formatter: (value) => value ? value : 'n/a' },
                { key: "actions", label: "", class: 'align-middle small text-right' },
            ],
			isBusy: false,

			minDate: new Date()
		}
	},
	computed: {		
		...mapGetters('referrals', ['getPatientReferrals']),
		referrals() {
			return _.cloneDeep(this.getPatientReferrals())
		},
		noReferrals() {
			return this.list.length == 0
		}
	},
	watch: {
		referrals: {
			immediate: true,
			handler(val) {
				this.list = val
			}
		}
	},
	methods: {
        ...mapActions('referrals', ['processReferral', 'updateDoctor', 'referralPrintableById']),
		async updateProcessedDate(item, value) {
			if (value) {
				await this.processReferral({id: item.id, date: value })
			}
		},

		async updateConsultingDoctor(item, value) {
			if (value) {
				await this.updateDoctor({id: item.id, doctor: value })
			}
		},

        async printRow(row) {
            const referral = await this.referralPrintableById(row.item.id)
            printJS({
                printable: referral,
                type: 'pdf',
                base64: true
            })
        }
	}
})
</script>