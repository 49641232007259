import Vue from "vue"
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from "./App"
import router from "./router"
import store from "./store"

import IdleVue from 'idle-vue'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import Form from "@/core/utils/Form"
import { EventBus } from "@/core/utils/EventBus"
import Mixins from "@/core/mixins"
import Components from "@/core/components"
import Directives from "@/core/directives"
import VueScrollTo from 'vue-scrollto'
import VCalendar from 'v-calendar'
import { Socket } from '@/core/utils/Socket'
import { AppActivity } from '@/core/utils/AppActivity'
import SpeechRecognition from '@/core/utils/SpeechRecognition'

import * as Sentry from "@sentry/vue"

Vue.use(Socket)

window.EventBus = EventBus
window.Form = Form

Vue.mixin(Mixins)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(Components)
Vue.use(Directives)
Vue.use(VueScrollTo)

// trigger onIdle after 30 seconds of inactivity
Vue.use(IdleVue, { eventEmitter: new Vue(), idleTime: 30000, startAtIdle: false, store })

Vue.use(VueViewer, {
	defaultOptions: {
		zIndexInline: 2021,
		focus: false,
		toolbar: false,
		navbar: false
	}
})

Vue.use(VCalendar, {
	componentPrefix: "vc",
	locales: {
		"nl-NL": {
			firstDayOfWeek: 2,
			masks: {
				L: "DD-MM-YYYY",
			}
		}
	}
})

// place after other components
Vue.use(AppActivity, { store, router, seconds: 3000, logoutLimit: 600 })
Vue.use(SpeechRecognition)

Vue.config.productionTip = false

if (process.env.VUE_APP_SENTRY_URL) {
	Sentry.init({
	  Vue,
	  dsn: process.env.VUE_APP_SENTRY_URL,
	  tracesSampleRate: 0.1,
	  integrations: [
	    new Sentry.Replay(),
	    new Sentry.Feedback({
		  useSentryUser: {
		    email: "email",
		    name: "fullName",
		  },
	      colorScheme: "light",
	      themeLight: {
		      submitBackground: "#63c2de",
		      submitBackgroundHover: "#20a8d8",
		      submitBorder: "#63c2de",
	      },
	      autoInject: false,
	    }),
	  ],
	  // Session Replay
	  replaysSessionSampleRate: 0.1,
	  replaysOnErrorSampleRate: 1.0,
	  environment: process.env.NODE_ENV,
	  debug: false
	})
}

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
