<template>
   <div>
        <validation-observer ref="observer" v-slot="{ passes }">
        <span v-if="!updateItem" @dblclick="updateItem = true">{{ item?.name }}</span>
        <b-form v-if="updateItem" inline @submit.prevent="passes(onFormSubmit)" novalidate>
            <b-button size="sm" class="mr-1" variant="warning" @click="updateItem = false">Undo</b-button>
            <validation-provider vid="name" v-slot="{ errors }">
                <b-form-input size="sm" :state="errors[0] ? false : null" id="name" name="name" v-model="form.name" placeholder="Add reason..." />
            </validation-provider>
            <b-button size="sm" type="submit" class="ml-1" variant="success">Update</b-button>
            <!-- <b-button size="sm" type="button" class="ml-1" variant="danger" @click="onDelete">Delete</b-button> -->
        </b-form>
        </validation-observer>
    </div>
</template>
<script>
import BaseExtension from "@/views/BaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default BaseExtension.extend({
    name: 'user-update-consultation-reason',
    props: ['item'],
    data() {
        return {
            form: new Form({
                name: "",
                consistency_token: null
            }),

            updateItem: false
        }
    },
    computed: {
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        },
        item: {
            immediate: true,
            handler(val) {
                this.form.withData(val)
            }
        },
    },
    methods: {
        ...mapActions('planning', ['updateConsultReasonTypeById', 'deleteConsultReasonTypeById']),
        onFormSubmit() {
            this.updateConsultReasonTypeById({id: this.item.id, data: this.form.data()}).then(() => {
                this.reset()
                this.notify('Consultation reasons update', "Consultation reasons was update successfully")
                this.$emit('updated')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                }
            })
        },
        onDelete() {
            this.deleteConsultReasonTypeById(this.item?.id).then(() => {
                this.notify('Consultation reasons deleted', "Consultation reasons was deleted successfully")
                this.updateItem = false
                this.saved(this.$vnode.key)
                this.$emit('updated')
            })
        },
        reset() {
            this.form.reset()
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.updateItem = false
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        }
    }
})
</script>