import session from '@/shared/session'

const state = {
	patient: {
		insurances: [],
	}
}

// getters
const getters = {
	getPatientInsurances: (state) => () => {
		return state.patient.insurances
	},
	getPrimaryInsurance: (state) => () => {
		return state.patient.insurances.find(insurance => insurance.is_primary)
	},
	getSecondaryInsurance: (state) => () => {
		return state.patient.insurances.find(insurance => !insurance.is_primary)
	},
}

// actions
const actions = {
	async getInsurancesByPatientId({ commit, state }, id) {
		const insurances = await session
			.get(`/api/patients/${id}/insurances/`)

			commit("set_insurances", insurances.data)
		return state.patient.insurances
	},

	async getInsurancesForCurrentPatient({ commit, state, rootState }) {
		const insurances = await session
			.get(`/api/patients/${rootState.patients.current?.id}/insurances/`)

		commit("set_insurances", insurances.data)

		return state.patient.insurances
	},

	createPatientInsurance({ commit, dispatch, rootState }, data) {
		dispatch("loading/load", "insurance", { root: true })
		return session
			.post(`/api/insurances/`, {...data, patient: rootState.patients.current?.id})
			.then(() => {
				return dispatch("getInsurancesForCurrentPatient")
			})
      .finally(() => {
        dispatch("loading/end", "insurance", { root: true })
      })
	},

	updatePatientInsurance({ commit, dispatch}, data) {
		dispatch("loading/load", "insurance", { root: true })
		return session
			.put(`/api/insurances/${data.id}/`, data.insurance)
			.then(() => {
				return dispatch("getInsurancesForCurrentPatient")
			})
      .finally(() => {
        dispatch("loading/end", "insurance", { root: true })
      })
	},
}

// mutations
const mutations = {
	set_insurances(state, insurances) {
		state.patient.insurances = insurances
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
