const update = el => {
	if(el.value) {
	    var event = new Event('input')
	    el.value = el.value.charAt(0).toUpperCase() + el.value.slice(1)
	    el.dispatchEvent(event)
	}
}
/*
 * Export our directive
 */
export const capitalize = {
  update
}