const state = {
	activeLoaders: []
}

// getters
const getters = {
	isLoading: (state) => (module) => {
		return state.activeLoaders.indexOf(module) > -1
	},

	anyLoading: (state) => () => {
		return state.activeLoaders.length > 0
	}
}

// actions
const actions = {
	load({ commit }, module) {
		commit(MUTATION_TYPE.LOAD, module)
	},

	end({ commit }, module) {
		commit(MUTATION_TYPE.END, module)
	}
}

// Base Utils
const uniq = array => {
	return array.filter((el, index, arr) => index == arr.indexOf(el))
}

const MUTATION_TYPE = {
	LOAD: 'LOAD',
	END: 'END'
}

const mutations = {
	[MUTATION_TYPE.LOAD](state, module) {
		state.activeLoaders.push(module)
		state.activeLoaders = uniq(state.activeLoaders)
	},
	[MUTATION_TYPE.END](state, module) {
		state.activeLoaders = uniq(state.activeLoaders).filter(p => p !== module)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}