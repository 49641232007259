<template>
<b-tab title-item-class="mb-1" class="p-0" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Insurances
    </template>
    <b-tabs pills card content-class="border-left-0 border-right-0 border-bottom-0">
        <primary-insurance @dirty="onDirty" @saved="onSaved" @errors="onErrors"></primary-insurance>
        <secondary-insurance @dirty="onDirty" @saved="onSaved" @errors="onErrors"></secondary-insurance>
    </b-tabs>
</b-tab>
</template>
<script>
import TabExtension from './TabExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import PrimaryInsurance from "./insurances/PrimaryInsurance"
import SecondaryInsurance from "./insurances/SecondaryInsurance"

export default TabExtension.extend({
    name: 'insurances',
    data() {
        return {
            unSaved: [],
            errors: []
        }
    },
    components: {
        'primary-insurance': PrimaryInsurance,
        'secondary-insurance': SecondaryInsurance
    },
    computed: {
        isDirty() {
            return this.unSaved.length > 0
        },
        hasErrors() {
            return this.errors.length > 0
        }
    },
    methods: {
        onSaved(id) {
            this.unSaved = this.unSaved.filter((component) => component.id !== id)
            this.errors = this.errors.filter((component) => component.id !== id)
            this.saved(id)
        },
        onDirty(id) {
            let exists = this.unSaved.find((component) => component.id == id)
            if (!exists) this.unSaved.push({ id: id })
            this.dirty(id)
        },
        onErrors(id) {
            let exists = this.errors.find((component) => component.id == id)
            if (!exists) this.errors.push({id: id})
        }
    }
})
</script>