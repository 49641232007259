<template>
<b-tab active-tab-class="text-secondary" lazy>
	<template slot="title">
		<i class="fa-regular fa-notes-medical"></i> <small>{{ toLocaleDate(decursus.date) }}</small>
	</template>

	<b-form-group
		label="Decursus Date"
		:label-for="`date-decursus-${decursus.id}`">
		<b-input-group>			
			<b-form-input :id="`date-decursus-${decursus.id}`" readonly v-dateformat :value="decursus.date" />
    	</b-input-group>
	</b-form-group>

	<b-form-group
		label="Subjective"
		label-for="subjective">					
		<b-input-group>
			<b-form-textarea class="squared-bottom" readonly id="subjective" name="subjective" :value="decursus.subjective" placeholder="n/a" rows="4" />
            <b-input-group-append>
                <b-button type="button" class="squared-bottom" :disabled="!decursus.subjective" variant="primary" @click="copyToCurrentDecursus('subjective', decursus.subjective)"><i class="fa-solid fa-arrow-right-from-bracket"></i></b-button>
            </b-input-group-append>
    	</b-input-group>
    	<b-button block disabled type="button" class="squared-top" :variant="decursus.icpc_subjective ? 'success' : 'light'" >
    		<i class="fa-solid fa-seal"></i> {{ decursus.icpc_subjective ? `${decursus.icpc_subjective.code}: ${decursus.icpc_subjective.name}` : 'No ICPC' }}
    	</b-button>
	</b-form-group>

	<b-form-group
		label="Objective"
		label-for="objective">					
		<b-input-group>
			<b-form-textarea readonly id="objective" name="objective" :value="decursus.objective" placeholder="n/a" rows="4" />
            <b-input-group-append>
                <b-button type="button" :disabled="!decursus.objective" variant="primary" @click="copyToCurrentDecursus('objective', decursus.objective)"><i class="fa-solid fa-arrow-right-from-bracket"></i></b-button>
            </b-input-group-append>
    	</b-input-group>
	</b-form-group>

	<b-form-group
		label="Evaluation"
		label-for="evaluation">
		<b-input-group>					
			<b-form-textarea readonly class="squared-bottom" id="evaluation" name="evaluation" :value="decursus.evaluation" placeholder="n/a" rows="4" />
            <b-input-group-append>
                <b-button type="button" class="squared-bottom" :disabled="!decursus.evaluation" variant="primary" @click="copyToCurrentDecursus('evaluation', decursus.evaluation)"><i class="fa-solid fa-arrow-right-from-bracket"></i></b-button>
            </b-input-group-append>
    	</b-input-group>
    	<b-button block disabled type="button" class="squared-top" :variant="decursus.icpc_evaluation ? 'success' : 'light'" >
    		<i class="fa-solid fa-seal"></i> {{ decursus.icpc_evaluation ? `${decursus.icpc_evaluation.code}: ${decursus.icpc_evaluation.name}` : 'No ICPC' }}
    	</b-button>
	</b-form-group>

	<b-form-group
		label="Policy"
		label-for="policy">
		<b-input-group>					
			<b-form-textarea readonly id="policy" name="policy" :value="decursus.policy" placeholder="n/a" rows="4" />
            <b-input-group-append>
                <b-button type="button" :disabled="!decursus.policy" variant="primary" @click="copyToCurrentDecursus('policy', decursus.policy)"><i class="fa-solid fa-arrow-right-from-bracket"></i></b-button>
            </b-input-group-append>
    	</b-input-group>
	</b-form-group>

	<b-form-group
		label="Comments"
		label-for="comments">
		<b-input-group>				
			<b-form-textarea readonly id="comments" name="comments" :value="decursus.comments" placeholder="n/a" rows="4" />
            <b-input-group-append>
                <b-button type="button" :disabled="!decursus.comments" variant="primary" @click="copyToCurrentDecursus('comments', decursus.comments)"><i class="fa-solid fa-arrow-right-from-bracket"></i></b-button>
            </b-input-group-append>
    	</b-input-group>
	</b-form-group>

	<b-form-group v-if="hasProcedureTypes"
	    label="Procedures">
	    <b-list-group disabled>
	        <b-list-group-item variant="secondary" class="p-0 flex-column align-items-start" v-for="procedure in procedures" :key="`procedure-${procedure.id}`">
	            <div class="ml-2 p-2">
	                <div class="d-flex w-100 justify-content-between">
	                    <h6 class="mb-1"><strong>{{ procedure.code }}</strong> {{ procedure.name }}</h6>
	                </div>

	                <small>{{ procedure.resource }} - {{ procedure.tariff }}</small>
	            </div>
	        </b-list-group-item>
	    </b-list-group>
	</b-form-group>


	<b-form-group>
		<b-input-group size="sm" prepend="Created" :append="decursus.created_by?.name">
			<b-form-input :id="`created-decursus-${decursus.id}`" size="sm" readonly v-dateformat :value="decursus.created_date" />
    	</b-input-group>
	</b-form-group>

	<b-form-group>
		<b-input-group size="sm" prepend="Updated" :append="decursus.updated_by?.name">
			<b-form-input :id="`updated-decursus-${decursus.id}`" size="sm" readonly v-dateformat :value="decursus.updated_date" />
    	</b-input-group>
	</b-form-group>

</b-tab>
</template>
<script>
import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapState, mapActions } from 'vuex'

export default BaseExtension.extend({
    name: 'decursus-tab',
    props: ['decursus'],
    data() {
    	return {
    		range: 0,
    		min: 0,
    		max: 5,
    		procedure: {}
    	}
    },
    computed: {
        hasProcedureTypes() {
            return this.procedure?.types?.length > 0
        },
    },
    watch: {
    	decursus: {
    		immediate: true,
    		handler(val) {
    			this.getProcedures(val.id)
    		}
    	}
    },
	methods: {
        ...mapActions('procedures', ['getProceduresByDecursusId']),
		copyToCurrentDecursus(field, value) {
			EventBus.$emit('copied', { field: field, value: value})
		},
		async getProcedures(id) {
			this.procedure = await this.getProceduresByDecursusId(id)
		}
	}
})
</script>