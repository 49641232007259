// // Patients
const PatientsContainer = () => import("@/views/patients/containers/DefaultContainer")
const PatientsView = () => import("@/views/patients/views/PatientsView")
const EditPatientView = () => import("@/views/patients/views/EditPatientView")
const NewPatientView = () => import("@/views/patients/views/NewPatientView")

const routes = [
    {
        path: "/patients",
        component: PatientsContainer,
        meta: {
            label: "Patients"
        },
        children: [
            {
                path: "",
                component: PatientsView,
                meta: {
                    roleRequired: ["administration", "physician", "nurse"],
                    label: "All Patients"
                }
            },
            {
                path: "new",
                component: NewPatientView,
                meta: {
                    roleRequired: ["administration", "physician", "nurse"],
                    label: "New Patient"
                }
            },
            {
                path: ":patientId/view",
                component: EditPatientView,
                meta: {
                    roleRequired: ["administration", "physician", "nurse"],
                    label: "Patient"
                }
            },
            {
                path: ":patientId/edit",
                component: EditPatientView,
                meta: {
                    roleRequired: ["administration", "physician", "nurse"],
                    label: "Patient"
                }
            }
        ]
    }
]

export default routes
