<template>
	<b-card class="card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
		<template v-slot:header>
			<i class="fa-regular fa-files-medical"></i> <strong>General History</strong>
			<div class="card-header-actions">
				<b-link class="card-header-action btn-minimize" @click="visible = !visible">
					<i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
				</b-link>
			</div>
		</template>
		<b-collapse v-model="visible">
			<b-card-body>
				<validation-observer ref="observer" v-slot="{ passes }">
				<b-form @submit.prevent="passes(onFormSubmit)" novalidate>
			        <b-overlay 
			            :show="$store.getters['loading/isLoading']('history')"
			            rounded="sm"
			            variant="light"
			            :opacity="0.80"
			            blur="2px">
			            <b-form-group :disabled="isLocked">
			                <validation-provider vid="description" v-slot="{ errors }">
                            <speech-area id="description" v-capitalize name="description" :errors="errors[0]" v-model="form.description" placeholder="Add history..." :state="errors[0]" rows="8" />
			                </validation-provider>
			            </b-form-group>
			        </b-overlay>
		            <b-button class="mr-1" :disabled="isLocked || !isDirty" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
		            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
		            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa-lock"></i> Lock</b-button>
                    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
		            <b-button :disabled="noRevisions" type="button" class="float-right" variant="primary" @click="historyRevisions" v-b-tooltip.hover title="Browse Revisions">
                        <i class="fa-solid fa-clock-rotate-left"></i>      
                    </b-button>
				</b-form>
				</validation-observer>
			</b-card-body>
		</b-collapse>
	</b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default ComponentExtension.extend({
	name: "history",
	data() {
		return {
			form: new Form({
				description: "",
				consistency_token: null
			}),

            isLocked: true,
            isDirty: false,
            hasErrors: false
		}
	},
    computed: {
        ...mapGetters('health', ['getPatientHistory']),
        ...mapGetters('revisions', ['getRevisionsForHistory']),
        revisions() {
            return this.getRevisionsForHistory()
        },
        history() {
            return this.getPatientHistory()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        noRevisions() {
            return this.revisions.length < 2
        }
    },
    watch: {
        history: {
            handler(val) {
                if (val) {
                    this.form.withData(val)
                    this.getHistoryRevisions(val.id)
                }
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        }
    },
    methods: {

        ...mapActions('health', ['getHistoryByPatientId', 'createPatientHistory', 'updatePatientHistory']),
        ...mapActions('revisions', ['getHistoryRevisions', 'revertHistoryRevision']),
        create() {
            this.createPatientHistory(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient history created", "Patient history was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        update() {
            this.updatePatientHistory({id: this.history.id, history: this.form.data() }).then(() => {
                this.reset()
                this.notify("Patient history updated", "Patient history was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        onFormSubmit() {
            if (this.history) {
                this.update()
            }else{
                this.create()
            }
        },
        reset() {
            this.form.reset()
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        },
        historyRevisions() {
            this.browseRevisions('General History', this.revisions, this.history.revision_fields)
        },
        async reverted(version) {
            await this.revertHistoryRevision({id: this.history.id, version: version})
        }
    },
    async mounted() {
        await this.getHistoryByPatientId()
    }
})
</script>