<template>
    <b-modal body-class="p-0" :return-focus="target" size="lg" title="Write Referral" v-model="showModal" no-close-on-esc no-close-on-backdrop modal-class="modal-info" ok-variant="info" ok-title="Done" @ok="onOk" cancel-title="Dismiss" @hidden="onHidden">
        <ck-simple-editor v-model="editorData" />
    </b-modal>
</template>
<script>
import ModalExtension from "@/core/components/modal/ModalExtension"

export default ModalExtension.extend({
    name: 'referral-reason-modal',
    props: ['target', 'reason'],
    data() {
        return {
            editorData: '',
        }
    },
    watch: {
        reason: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.editorData = val
                }
            }
        }
    },
    methods: {
        onOk(evt) {
            evt.preventDefault()
            this.$emit('done', this.editorData)
            this.hide()
        }
    },
})
</script>