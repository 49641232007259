<template>
    <b-list-group-item class="px-0 py-2 flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1"><small class="font-weight-bold">{{ node.name }}</small></h6>
        <small class="font-weight-bold">{{ node.created }}</small>
      </div>
      <template v-for="(detail, index) in node.details">
      	 <p class="mb-0 break-word small" :key="index">{{ detail }}</p>
      </template>
      <!-- <small>Additional details or info.</small> -->
      <b-list-group v-if="node.children" class="ml-4 my-1">
        <tree-node v-for="(child, index) in node.children" :key="index" :node="child"/>
      </b-list-group>
    </b-list-group-item>
</template>

<script>
export default {
	name: "tree-node",
	props: {
		node: {
			type: Object,
			default: {}
		}
	}
}
</script>
<style>
.ml-4 {
  margin-left: 1.5rem;
}
</style>