<template>
<b-tab lazy title-item-class="mb-1" class="p-0" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Manage
    </template>
    <b-tabs pills card content-class="border-left-0 border-right-0 border-bottom-0">        
        <export-patient v-if="checkUserPermission('export', 'can_view')" @dirty="onDirty" @saved="onSaved" @errors="onErrors"></export-patient>
        <logs v-if="checkUserPermission('log', 'can_view')" @dirty="onDirty" @saved="onSaved" @errors="onErrors"></logs>

        <template v-if="user?.is_staff" #tabs-end>
            <b-overlay
                class="ml-auto"
                :show="$store.getters['loading/isLoading']('update_patient')"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="success">
                <b-nav-item active link-classes="bg-warning" role="presentation" @click="activateConfirm" href="#"><i class="fa-solid fa-power-off"></i> Deactivate Patient</b-nav-item>
            </b-overlay>
        </template>
    </b-tabs>
</b-tab>
</template>
<script>
import TabExtension from './TabExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import Logs from "./management/Logs"
import Export from "./management/Export"

export default TabExtension.extend({
    name: 'manage-patient',
    data() {
        return {
            unSaved: [],
            errors: []
        }
    },
    components: {
        'logs': Logs,
        'export-patient': Export,
    },
    computed: {
        isDirty() {
            return this.unSaved.length > 0
        },
        hasErrors() {
            return this.errors.length > 0
        }
    },
    methods: {
        ...mapActions('patients', ['updatePatientStatus']),
        async changeStatus(status) {
            await this.updatePatientStatus(status)
        },
        onSaved(id) {
            this.unSaved = this.unSaved.filter((component) => component.id !== id)
            this.errors = this.errors.filter((component) => component.id !== id)
            this.saved(id)
        },
        onDirty(id) {
            let exists = this.unSaved.find((component) => component.id == id)
            if (!exists) this.unSaved.push({ id: id })
            this.dirty(id)
        },
        onErrors(id) {
            let exists = this.errors.find((component) => component.id == id)
            if (!exists) this.errors.push({id: id})
        },
        activateConfirm() {
            this.$bvModal
                .msgBoxConfirm("Are you sure you want to deactivate this patient?", {
                    title: "Deactivate Patient",
                    size: "sm",
                    buttonSize: "lg",
                    okVariant: "danger",
                    okTitle: "Deactivate",
                    cancelTitle: "Cancel",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        this.changeStatus(true)
                    }
                })
        }
    }
})
</script>