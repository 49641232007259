<template>
	<b-card class="mb-0" header-tag="header" footer-tag="footer" no-body>
	    <template v-slot:header>
	        <i class="fa-regular fa-file-plus"></i> <strong>{{ formHeader }}</strong>
<!-- 	        <div class="card-header-actions">
	        </div> -->
	    </template>
        <template v-if="hasTests">
	    <div class="scrollable-test-list">
		    <b-list-group flush>
				<TemplateTestItem v-for="item in filterItems" :test="item" :key="item.id" @remove="onRemoveTest"/>
			</b-list-group>
		</div>
        </template>
        <template v-else>
        	<b-list-group flush>
            	<b-list-group-item class="text-center text-muted">No tests selected</b-list-group-item>
        	</b-list-group>
        </template>
		<b-card-body>
		    <validation-observer ref="observer" v-slot="{ passes }">
		    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
		    	<validation-provider vid="name" v-slot="{ errors }">
                    <b-input-group>
                    <b-form-input id="name" :state="errors[0] ? false : null" v-model="form.name" autocomplete="off" placeholder="Set template name..." />
                    <template #append>
		                <b-overlay
		                    :show="$store.getters['loading/isLoading']('test-template')"
		                    rounded
		                    opacity="0.6"
		                    spinner-small
		                    spinner-variant="success">
	                		<b-button :disabled="!hasTests || $store.getters['loading/isLoading']('test-template')" type="submit" :variant="isExistingTemplate ? 'warning' : 'success'" class="px-4" tabindex="3">{{ isExistingTemplate ? 'Update' : 'Save'}}</b-button>
	            		</b-overlay>
                    	</template>
                    </b-input-group>
                </validation-provider>
            </b-form>
        	</validation-observer>
    	</b-card-body>
<!--     	<b-card-footer class="text-right">
    		<b-button variant="warning" @click="$emit('update:selectedTemplate', null)">Clear</b-button>
    	</b-card-footer> -->
	</b-card>
</template>
<script>
import TemplateExtension from "./TemplateExtension"
import { mapGetters, mapState, mapActions } from 'vuex'

import TemplateTestItem from './TemplateTestItem'

export default TemplateExtension.extend({
	name: "lab-template-form",
    components: {
        TemplateTestItem
    },
    data() {
    	return {
    		form: new Form({
                name: "",
                type_id: null,
                tests: [],
                consistency_token: null
            }),
    		items: []
    	}
    },
    computed: {
        ...mapState('lab', {
            activeTemplate: (state) => state.templates.active
        }),
        formHeader() {
            return this.form.name || 'New Template'
        },
    	isExistingTemplate() {
    		return this.activeTemplate?.hasOwnProperty('id')
    	},
    	tests() {
    		return this.form.tests || []
    	},
    	filterItems() {
    		return this.items.filter(item => this.form.tests.includes(item.id))
    	},
    	hasTests() {
    		return this.filterItems.length > 0
    	},
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                const isDirty = this.form.dirty()

                if (isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        },
    	activeTemplate: {
            immediate: true,
    		handler(val) {
    			if(val){
    				this.form.populate(val)
    			}else{
    				this.reset()
    			}

                EventBus.$emit('lab-update-form', val)
    		}
    	}
    },
    methods: {
        ...mapActions('lab', [
            'filterDiagnosticTests',
            'createDiagnosticTemplate', 
            'updateDiagnosticTemplate', 
        ]),
		onFormSubmit() {
			if(this.isExistingTemplate) {
				this.updateDiagnosticTemplate({id: this.activeTemplate.id, data: this.form.data()}).then(() => {
					this.reset()
					this.notify("Diagnostic referral template updated", "Diagnostic referral template was updated successfully")

				})
	            .catch(errors => {
	                if (errors.response) {
	                    this.$refs.observer?.setErrors(errors.response.data)
	                }
	            })
			}else{
				this.createDiagnosticTemplate(this.form.data()).then(() => {
					this.reset()
					this.notify("Diagnostic referral template created", "Diagnostic referral template was created successfully")

				})
	            .catch(errors => {
	                if (errors.response) {
	                    this.$refs.observer?.setErrors(errors.response.data)
	                }
	            })
			}
		},
        onRemoveTest(testId) {
            const filtered = this.form.tests.filter(item => item !== testId)
            this.form.populate({tests: filtered})

            EventBus.$emit('lab-update-form', this.form.data())
        },
        newFormWithDefaults() {
            this.form = Form.create({
                name: "",
                type_id: null,
                tests: [],
                consistency_token: null
            })
        },
        reset() {
        	this.newFormWithDefaults()
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        },
        updateTemplate(data) {
            this.form.populate({
                tests: data.tests,
                type_id: data.type_id
            })
        }
    },
    async mounted() {
        this.newFormWithDefaults()
        this.items = await this.filterDiagnosticTests()
    },
    created() {
        EventBus.$on('lab-update-template', (data) => {
            this.updateTemplate(data)
        })
    }
})
</script>

<style scoped>
.scrollable-test-list {
    overflow-y: scroll;
    max-height: 180px;
}

</style>