<template>
	<b-modal no-close-on-esc no-close-on-backdrop modal-class="modal-revision" body-bg-variant="light" v-model="showModal" step="1" size="xl" ok-only :title="modalTitle" @hidden="onHidden" :busy="isBusy">
		<b-container fluid>
			<b-form-group description="Use the slider to view revisions.">
				<b-input-group>
				    <template #prepend>
				      <b-input-group-text class="bg-danger">Earliest Version</b-input-group-text>
				    </template>

				    <template #append>
				      <b-input-group-text class="bg-success">Latest Version</b-input-group-text>
				    </template>

					<b-form-input v-model="range" type="range" :min="minRevisions" :max="maxRevisions" style="direction:rtl;"></b-form-input>
				</b-input-group>
			</b-form-group>
			<b-row>
				<b-col cols="6">
					<b-skeleton-wrapper :loading="isBusy">
						<template #loading>
							<b-card class="border-0">
								<b-skeleton variant="danger" animation="wave" width="85%"></b-skeleton>
								<b-skeleton variant="danger" animation="wave" width="55%"></b-skeleton>
								<b-skeleton variant="danger" animation="wave" width="70%"></b-skeleton>
							</b-card>
						</template>

						<b-table-simple v-if="hasRevisions" small striped outlined dark no-border-collapse>
						    <b-tbody>
						      <b-tr>
						        <b-td style="width: 25%"><strong>User:</strong></b-td>
						        <b-td>{{ revisions[range]['history_user'] }}</b-td>
						      </b-tr>
						      <b-tr>
						        <b-td style="width: 25%"><strong>Date:</strong></b-td>
						        <b-td>{{ toLocaleDateTime(revisions[range]['history_date']) }}</b-td>
						      </b-tr>
						      <b-tr>
						        <b-td style="width: 25%"><strong>Version:</strong></b-td>
						        <b-td>{{ revisions[range]['history_id'] }}</b-td>
						      </b-tr>
						    </b-tbody>
						  </b-table-simple>

						<p v-revision:deleted="diffHtml" style="white-space: pre-wrap;" class="bg-dark text-light p-2 border border-danger"></p>
					</b-skeleton-wrapper>
				</b-col>
				<b-col cols="6">
					<b-skeleton-wrapper :loading="isBusy">
						<template #loading>
							<b-card class="border-0">
								<b-skeleton variant="success" animation="wave" width="85%"></b-skeleton>
								<b-skeleton variant="success" animation="wave" width="55%"></b-skeleton>
								<b-skeleton variant="success" animation="wave" width="70%"></b-skeleton>
							</b-card>
						</template>

						<b-table-simple v-if="hasRevisions" small striped outlined dark no-border-collapse>
						    <b-tbody>
						      <b-tr>
						        <b-td style="width: 25%"><strong>User:</strong></b-td>
						        <b-td>{{ revisions[0]['history_user'] }}</b-td>
						      </b-tr>
						      <b-tr>
						        <b-td style="width: 25%"><strong>Date:</strong></b-td>
						        <b-td>{{ toLocaleDateTime(revisions[0]['history_date']) }}</b-td>
						      </b-tr>
						      <b-tr>
						        <b-td style="width: 25%"><strong>Latest Version:</strong></b-td>
						        <b-td>{{ revisions[0]['history_id'] }} <span v-if="revisions[0]['history_change_reason']" class="font-italic">({{ revisions[0]['history_change_reason'] }})</span>
						        </b-td>
						      </b-tr>
						    </b-tbody>
						  </b-table-simple>

						<p v-revision:inserted="diffHtml" style="white-space: pre-wrap;" class="bg-dark text-light p-2 border border-danger"></p>
					</b-skeleton-wrapper>
				</b-col>
			</b-row>
		</b-container>
		<template #modal-footer="{ ok, cancel, hide }">
			<b-button v-show="Number(range)" :disabled="!hasRevisions" class="mr-auto" variant="danger" @click="revert">Revert to this Version</b-button>
			<b-button variant="secondary" @click="cancel()">Dismiss</b-button>
		</template>
	</b-modal>
</template>
<script>
import ModalExtension from '@/core/components/modal/ModalExtension'
import { mapActions } from "vuex"
import diff_match_patch from 'diff-match-patch'

export default ModalExtension.extend({
	props: ['title', 'revisions'],
    data() {
        return {
        	range: 0,
        	isBusy: false
        }
	},
    computed: {
    	modalTitle() {
    		return `${this.maxRevisions} ${this.title} Revision(s)`
    	},
    	currentContent() {
    		return this.revisions[0].changes.map((change) => {
    			return `${change.field}\n${change.value}\n`
    		}).join('\n')

    	},
    	oldContent() {
    		return this.revisions[this.range].changes.map((change) => {
    			return `${change.field}\n${change.value}\n`
    		}).join('\n')

    	},
    	diffHtml() {
    		this.isBusy = true
    		if (!this.hasRevisions) return ""

			const dmp = new diff_match_patch()
			const diffs = dmp.diff_main(this.oldContent, this.currentContent)
			dmp.diff_cleanupSemantic(diffs)

    		this.isBusy = false

			return diffs
    	},
    	hasRevisions() {
    		return this.revisions.length > 0
    	},
    	minRevisions() {
    		return 0
    	},
    	maxRevisions() {
    		return this.revisions.length -1
    	}
    },
    methods: {
        revert() {
            this.hide()
            const version = this.revisions[this.range].history_id
            this.$emit('reverted', version)
        }
    }

})
</script>