import moment from "moment"

const update = el => {

	const value = el.value

	if(value) {
		if(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value) {
			el.value = moment(value, "YYYY-MM-DD").format("DD-MM-YYYY")
		}

		if (value.length === 2 || value.length === 5) {
			el.value = value + "-"
		}

		if (value.length > 10) {
			el.value = value.substr(0, 10)
		}
	}
}

const inserted = el => {

	const value = el.value

	if(value) {

		if(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value) {
			el.value = moment(value, "YYYY-MM-DD").format("DD-MM-YYYY")
		}

		if (value.length === 2 || value.length === 5) {
			el.value = value + "-"
		}

		if (value.length > 10) {
			el.value = value.substr(0, 10)
		}
	}
}

/*
 * Export our directive
 */
export const dateformat = {
	update, inserted
}