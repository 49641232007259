// Schedule
const ScheduleContainer = () => import("@/views/schedule/containers/DefaultContainer")
const ScheduleIndex = () => import("@/views/schedule/views/StartingPage")
const ScheduleView = () => import("@/views/schedule/views/ScheduleView")

const routes = [
    {
        path: "/schedule",
        component: ScheduleContainer,
        meta: {
            label: "Schedule"
        },
        children: [
            {
                path: "",
                component: ScheduleIndex,
                meta: {
                    roleRequired: ["physician"],
                    label: "Welcome"
                }
            },
            {
                path: ":consultationId",
                name: 'schedule-view',
                component: ScheduleView,
                meta: {
                    roleRequired: ["physician"],
                    label: "Patient"
                }
            },
        ]
    }
]

export default routes
