<template>
	<b-navbar-nav class="ml-auto d-md-down-none">
		<b-nav-form>
		    <b-overlay
				:show="$store.getters['loading/isLoading']('viewcreator')"
				rounded="circle"
				opacity="0.6"
				spinner-small
				spinner-variant="primary"
				class="d-inline-block">
					<b-button :disabled="!userHasTemplate" class="rounded-circle px-2" variant="outline-info" @click="onEdit"><i class="fa-light fa-display"></i></b-button>
			</b-overlay>
		</b-nav-form>
	</b-navbar-nav>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"

export default {
	name: 'vc-navbar-button',
	computed: {
		...mapGetters('viewcreator', ['userHasTemplate'])
	},
	methods: {
		onEdit() {
			EventBus.$emit('edit-vc-template')
		},
	},
}
</script>