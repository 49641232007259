import diff_match_patch from 'diff-match-patch'


const initalize = (el, binding) => {
	const dmp = new diff_match_patch()
	const diffs = binding.value

	var html = []
	for (var x = 0; x < diffs.length; x++) {
		let flag = diffs[x][0]
		let data = diffs[x][1]

		var text = (data.replace("&", "&amp;").replace("<", "&lt;").replace(">", "&gt;").replace("\n", "<br/>"))

		if (binding.arg == "inserted") {
			if (flag == diff_match_patch.DIFF_INSERT) {
				html.push("<span class=\"revision-inserted text-dark\">")
				html.push(text)
				html.push("</span>")
			}else if (flag == diff_match_patch.DIFF_DELETE) {
				// if (text.trim().indexOf(' ') >= 0) {
				// 	html.push("<span class=\"revision-inserted\">")
				// 	html.push("<span style=\"visibility:hidden;\">")
				// 	html.push(text)
				// 	html.push("</span>")
				// 	html.push("</span>")
				// }
				
			}else if(flag == diff_match_patch.DIFF_EQUAL) {
				// html.push("<span class=\"revision-equal\">")
				html.push(text)
				// html.push("</span>")
			}
		}else if (binding.arg == "deleted") {
			if (flag == diff_match_patch.DIFF_DELETE) {
				html.push("<span class=\"revision-deleted text-dark\">")
				html.push(text)
				html.push("</span>")
			}else if (flag == diff_match_patch.DIFF_INSERT) {
				// if (text.trim().indexOf(' ') >= 0) {
				// 	html.push("<span class=\"revision-deleted\">")
				// 	html.push("<span style=\"visibility:hidden;\">")
				// 	html.push(text)
				// 	html.push("</span>")
				// 	html.push("</span>")
				// }
			
			}else if(flag == diff_match_patch.DIFF_EQUAL) {
				// html.push("<span class=\"revision-equal\">")
				html.push(text)
				// html.push("</span>")
			}
		}
	}

	el.innerHTML = html.join('')	
}

const bind = (el, binding) => {

	initalize(el, binding)

	// var s = JSON.stringify
	// el.innerHTML =
    //   'value: '      + s(binding.value) + '<br>' +
    //   'argument: '   + s(binding.arg) + '<br>' +
    //   'modifiers: '  + s(binding.modifiers) + '<br>'

}

const update = (el, binding) => {
	initalize(el, binding)
}

/*
 * Export our directive
 */
export const revision = {
	bind, update
}