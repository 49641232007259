import Vue from 'vue'

import ComponentExtension from '../ComponentExtension'
import { mapState, mapGetters, mapActions } from "vuex"

export default ComponentExtension.extend({
	methods: {
        createPendingAction(editor, actionName) {
            const pendingActions = editor.plugins.get( 'PendingActions' )
            const action = pendingActions.add( actionName )

            return action
        },

        stopPendingAction(editor, action, status) {
            const pendingActions = editor.plugins.get( 'PendingActions' )
            pendingActions.remove( action )
        }
	}
})