import Vue from "vue"
import Vuex from "vuex"


import loading from "@/store/modules/loading"
import auth from "@/store/modules/auth"
import catalogue from "@/store/modules/catalogue"
import procedures from "@/store/modules/procedures"
import vaccinations from "@/store/modules/vaccinations"
import lab from "@/store/modules/lab"
import health from "@/store/modules/health"
import prescriptions from "@/store/modules/prescriptions"
import letters from "@/store/modules/letters"
import decursus from "@/store/modules/decursus"
import referrals from "@/store/modules/referrals"
import messaging from "@/store/modules/messaging"
import patients from "@/store/modules/patients"
import insurances from "@/store/modules/insurances"
import episodes from "@/store/modules/episodes"
import planning from "@/store/modules/planning"
import schedule from "@/store/modules/schedule"
import revisions from "@/store/modules/revisions"
import viewcreator from "@/store/modules/viewcreator"
import interchange from "@/store/modules/interchange"

Vue.use(Vuex)

export default new Vuex.Store({
	strict: true,
	modules: {
		loading,
		auth,
		catalogue,
		procedures,
		vaccinations,
		lab,
		health,
		prescriptions,
		letters,
		decursus,
		referrals,
		messaging,
		patients,
		insurances,
		episodes,
		planning,
		schedule,
		revisions,
		viewcreator,
		interchange
	}
})
