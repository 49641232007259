// Messaging
const ReportsContainer = () => import("@/views/reports/containers/DefaultContainer")
const ReportsIndex = () => import("@/views/reports/views/StartingPage")

const routes = [
    {
        path: "/reports",
        component: ReportsContainer,
        meta: {
            label: "Reports"
        },
        children: [
            {
                path: "",
                component: ReportsIndex,
                meta: {
                    roleRequired: ["physician"],
                    // label: "Reports"
                }
            }
        ]
    }
]

export default routes
