<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Vital Functions
    </template>

    <div class="d-flex mb-3" v-if="checkUserPermission('vitals', 'can_add')">
        <b-button class="ml-auto" @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
    </div>

    <b-collapse id="add" v-model="addNew" variant="primary">
        <b-card>
            <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

            <validation-provider vid="non_field_errors" v-slot="{ errors }">
              <b-form-invalid-feedback :state="errors[0] ? false : null">
                <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider name="Temperature" vid="temperature" rules="measurement" v-slot="{ errors }">
            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Temperature"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-row>
                    <b-col>
                         <b-input-group append="Celsius">
                            <b-form-input id="temperature" v-comma2dot name="temperature" placeholder=".00" v-model="form.temperature"  />
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <b-form-select type="number" id="temperature_method_id" name="temperature_method_id" plain v-model="form.temperature_method_id">
                            <b-form-select-option :value="null">Select Method</b-form-select-option>
                            <b-form-select-option v-for="temperature_method in body_temperature_measurement_methods" :key="temperature_method.id" :value="temperature_method.id">{{ temperature_method.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-form-row>
            </b-form-group>
            </validation-provider>

            <validation-provider vid="heart_rhythm" v-slot="{ errors }">
            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Hearth Rhythm"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-row>
                    <b-col>
                        <b-form-select id="heart_rhythm_id" name="heart_rhythm_id" plain v-model="form.heart_rhythm_id">
                            <b-form-select-option :value="null">Select Rhythm</b-form-select-option>
                            <b-form-select-option v-for="heart_rhythm in heart_rhythms" :key="heart_rhythm.id" :value="heart_rhythm.id">{{ heart_rhythm.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col>
                        <b-form-select id="heart_rhythm_type_id" name="heart_rhythm_type_id" plain v-model="form.heart_rhythm_type_id">
                            <b-form-select-option :value="null">Select Type</b-form-select-option>
                            <b-form-select-option v-for="heart_rhythm_type in heart_rhythm_measurement_types" :key="heart_rhythm_type.id" :value="heart_rhythm_type.id">{{ heart_rhythm_type.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-form-row>
            </b-form-group>
            </validation-provider>

            <validation-provider name="Pulse rate" vid="pulse_rate" rules="numeric" v-slot="{ errors }">
            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Pulse Rate"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-row>
                    <b-col>
                         <b-input-group append="beats per minute">
                            <b-form-input type="number" id="pulse_rate" v-comma2dot name="pulse_rate" placeholder="000" v-model="form.pulse_rate"  />
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <b-form-select id="pulse_strength_id" name="pulse_strength_id" plain v-model="form.pulse_strength_id">
                            <b-form-select-option :value="null">Select Strength</b-form-select-option>
                            <b-form-select-option v-for="pulse_strength in pulse_strengths" :key="pulse_strength.id" :value="pulse_strength.id">{{ pulse_strength.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col>
                        <b-form-select id="pulse_rate_method_id" name="pulse_rate_method_id" plain v-model="form.pulse_rate_method_id">
                            <b-form-select-option :value="null">Select Method</b-form-select-option>
                            <b-form-select-option v-for="pulse_rate_method in pulse_rate_measurement_methods" :key="pulse_rate_method.id" :value="pulse_rate_method.id">{{ pulse_rate_method.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-form-row>
            </b-form-group>
            </validation-provider>
            <validation-provider name="Respiration rate" vid="respiration_rate" rules="numeric" v-slot="{ errors }">
            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Respiration"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-row>
                    <b-col>
                         <b-input-group append="breaths per minute">
                            <b-form-input type="number" id="respiration_rate" v-comma2dot name="respiration_rate" placeholder="000" v-model="form.respiration_rate"  />
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <b-form-select id="respiration_rhythm_id" name="respiration_rhythm_id" plain v-model="form.respiration_rhythm_id">
                            <b-form-select-option :value="null">Select Rhythm</b-form-select-option>
                            <b-form-select-option v-for="respiration_rhythm in respiration_rhythms" :key="respiration_rhythm.id" :value="respiration_rhythm.id">{{ respiration_rhythm.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col>
                        <b-form-select id="respiration_type_id" name="respiration_type_id" plain v-model="form.respiration_type_id">
                            <b-form-select-option :value="null">Select Type</b-form-select-option>
                            <b-form-select-option v-for="respiration_type in respiration_measurement_types" :key="respiration_type.id" :value="respiration_type.id">{{ respiration_type.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-form-row>
            </b-form-group>
            </validation-provider>
            <validation-provider name="Blood pressure systolic" vid="blood_pressure_systolic" rules="numeric" v-slot="{ errors }">

            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Blood pressure (systolic)"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-row>
                    <b-col>
                         <b-input-group append="mm Hg">
                            <b-form-input type="number" id="blood_pressure_systolic" v-comma2dot name="blood_pressure_systolic" placeholder="000" v-model="form.blood_pressure_systolic"  />
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <b-form-select id="blood_pressure_systolic_method_id" name="blood_pressure_systolic_method_id" plain v-model="form.blood_pressure_systolic_method_id">
                            <b-form-select-option :value="null">Select Method</b-form-select-option>
                            <b-form-select-option v-for="systolic_method in systolic_measurement_methods" :key="systolic_method.id" :value="systolic_method.id">{{ systolic_method.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-form-row>
            </b-form-group>
            </validation-provider>
            <validation-provider name="Blood pressure diastolic" vid="blood_pressure_diastolic" rules="numeric" v-slot="{ errors }">
            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Blood pressure (diastolic)"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-row>
                    <b-col>
                         <b-input-group append="mm Hg">
                            <b-form-input type="number" id="blood_pressure_diastolic" v-comma2dot name="blood_pressure_diastolic" placeholder="000" v-model="form.blood_pressure_diastolic"  />
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <b-form-select id="blood_pressure_diastolic_method_id" name="blood_pressure_diastolic_method_id" plain v-model="form.blood_pressure_diastolic_method_id">
                            <b-form-select-option :value="null">Select Method</b-form-select-option>
                            <b-form-select-option v-for="diastolic_method in diastolic_measurement_methods" :key="diastolic_method.id" :value="diastolic_method.id">{{ diastolic_method.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-form-row>
            </b-form-group>
            </validation-provider>
            <validation-provider vid="palpation_location" v-slot="{ errors }">
            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Palpation Location"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-row>
                    <b-col>
                        <b-form-select id="palpation_location_id" name="palpation_location_id" plain v-model="form.palpation_location_id">
                            <b-form-select-option :value="null">Select Location</b-form-select-option>
                            <b-form-select-option v-for="palpation_location in palpation_locations" :key="palpation_location.id" :value="palpation_location.id">{{ palpation_location.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-form-row>
            </b-form-group>
            </validation-provider>
            <validation-provider name="Saturation" vid="saturation" rules="measurement" v-slot="{ errors }">
            <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Saturation"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                    <b-form-input type="number" id="saturation" v-comma2dot name="saturation" placeholder=".00" v-model="form.saturation"  />
            </b-form-group>
            </validation-provider>
            <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

            </b-form>
            </validation-observer>
        </b-card>
    </b-collapse>

    <b-overlay :show="confirm" no-wrap @shown="onShown">
        <template #overlay>
            <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                <p class="text-center">
                    <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE VITAL INFO ON {{ toLocaleDate(selected?.created) }} </strong>
                </p>
                <div class="text-center">
                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                    <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                </div>
            </div>
        </template>
    </b-overlay>
    <b-overlay 
        :show="$store.getters['loading/isLoading']('vitals')"
        rounded="sm"
        variant="light"
        :opacity="0.80"
        blur="2px">
        <b-table
            ref="table"
            class="m-0"
            responsive
            stacked="sm"
            show-empty
            striped
            hover
            fixed
            small
            primary-key="id"
            :current-page="currentPage"
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :busy.sync="$store.getters['loading/isLoading']('vitals')"
            :no-sort-reset="true"
            :sort-by.sync="sortedBy"
            :sort-desc.sync="sortedDesc"
            :no-border-collapse="false">

            <template v-if="checkUserPermission('vitals', 'can_delete')" v-slot:cell(actions)="row">
                <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)" class="mr-1"><i class="fa-solid fa-xmark"></i></b-button>
            </template>

            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template v-slot:empty="scope">
                <div class="small text-muted text-center my-2">
                    <span>n/a</span>
                </div>
            </template>

            <template v-slot:custom-foot="scope">
                <b-tr class="border-top">
                    <b-td colspan="7" class="pt-3 align-middle" variant="light" style="background: #fff;">
                        Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                    </b-td>
                    <b-td colspan="2" class="pt-3 text-right align-middle" variant="light" style="background: #fff;">
                        <b-pagination small class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                    </b-td>
                </b-tr>
            </template>
        </b-table>
    </b-overlay>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"

export default TabExtension.extend({
    name: "vital-functions",
    data() {
        return {
            addNew: false,
            form: null,

            fields: [
                { key: 'created', label: 'Date/Time', class: 'small align-middle', formatter: (value) => this.toLocaleDate(value) },
                { key: 'temperature_full', label: 'Temperature', class: 'small align-middle text-center', tdClass: (value) => !value ? 'font-italic text-muted' : '', formatter: (value) => value || 'n/a' },
                { key: 'heart_rhythm_full', label: 'Heart Rhythm', class: 'small align-middle text-center', tdClass: (value) => !value ? 'font-italic text-muted' : '', formatter: (value) => value || 'n/a' },
                { key: 'pulse_rate_full', label: 'Pulse Rate', class: 'small align-middle text-center', tdClass: (value) => !value ? 'font-italic text-muted' : '', formatter: (value) => value || 'n/a' },
                { key: 'respiration_rate_full', label: 'Respiration Rate', class: 'small align-middle text-center', tdClass: (value) => !value ? 'font-italic text-muted' : '', formatter: (value) => value || 'n/a' },
                { key: 'blood_pressure_systolic_full', label: 'BP systolic', class: 'small align-middle text-center', tdClass: (value) => !value ? 'font-italic text-muted' : '', formatter: (value) => value || 'n/a' },
                { key: 'blood_pressure_diastolic_full', label: 'BP diastolic', class: 'small align-middle text-center', tdClass: (value) => !value ? 'font-italic text-muted' : '', formatter: (value) => value || 'n/a' },
                { key: 'saturation', label: 'Saturation', class: 'small align-middle text-center', tdClass: (value) => !value ? 'font-italic text-muted' : '', formatter: (value) => value || 'n/a' },
                
                { key: "actions", label: "", class: "align-middle text-right" },
            ],
            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            processing: false,
            selected: null
        }
    },
    computed: {
        ...mapState('catalogue', {
            catalogue_defaults: state => state.catalogue_defaults,
            body_temperature_measurement_methods: state => state.body_temperature_measurement_methods,
            pulse_rate_measurement_methods: state => state.pulse_rate_measurement_methods,
            heart_rhythms: state => state.heart_rhythms,
            pulse_strengths: state => state.pulse_strengths,
            heart_rhythm_measurement_types: state => state.heart_rhythm_measurement_types,
            palpation_locations: state => state.palpation_locations,
            respiration_measurement_types: state => state.respiration_measurement_types,
            respiration_rhythms: state => state.respiration_rhythms,
            systolic_measurement_methods: state => state.systolic_measurement_methods,
            diastolic_measurement_methods: state => state.diastolic_measurement_methods
        }),
        ...mapGetters('health', ['getPatientVitals']),
        items() {
            return this.getPatientVitals()
        },
        ...mapState('patients', {
            current_consult: state => state.current.current_consult,
        }),
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },

        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.$emit('dirty', this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.$emit('saved', this.$vnode.key)
                }
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem', 'getCatalogueDefaults']),
        ...mapActions('health', ['getVitalsByPatientId', 'createPatientVitalsWithOrWithoutConsult', 'deletePatientVitals']),
        onRemoveItem(item) {
            this.confirm = true
            this.processing = false
            this.selected = item
        },
        onConfirmed(item) {

            this.processing = true

            this.deletePatientVitals(item.id).then(() => {
                this.notify("Patient vitals removed", "Patient vitals was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.processing = false
                this.selected = null
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },
        onFormSubmit() {
            this.createPatientVitalsWithOrWithoutConsult(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient vitals created", "Patient vitals was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                heart_rhythm_id: null,
                heart_rhythm_type_id: defaults.heart_rhythm_measurement_type,
                palpation_location_id: null,
                temperature: null,
                temperature_method_id: defaults.body_temperature_measurement_method,
                pulse_rate: null,
                pulse_strength_id: null,
                pulse_rate_method_id: defaults.pulse_rate_measurement_method,
                respiration_rate: null,
                respiration_type_id: defaults.respiration_measurement_type,
                respiration_rhythm_id: null,
                blood_pressure_systolic: null,
                blood_pressure_systolic_method_id: defaults.systolic_measurement_method,
                blood_pressure_diastolic: null,
                blood_pressure_diastolic_method_id: defaults.diastolic_measurement_method,
                saturation: null,
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.addNew = false
                this.$emit('saved', this.$vnode.key)
            })
        }
    },
    async mounted() {
        await this.getCatalogueDefaults()
        await this.getCatalogueItem('body_temperature_measurement_methods')
        await this.getCatalogueItem('pulse_rate_measurement_methods')
        await this.getCatalogueItem('heart_rhythms')
        await this.getCatalogueItem('pulse_strengths')
        await this.getCatalogueItem('heart_rhythm_measurement_types')
        await this.getCatalogueItem('palpation_locations')
        await this.getCatalogueItem('respiration_measurement_types')
        await this.getCatalogueItem('respiration_rhythms')
        await this.getCatalogueItem('systolic_measurement_methods')
        await this.getCatalogueItem('diastolic_measurement_methods')

        await this.getVitalsByPatientId()
    }
})
</script>