<template>
    <Bar :options="chartOptions" :data="chartData" />
</template>
<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'bar-chart',
    props: {
        chartData: {
            type: Object,
            required: true
          },
        chartOptions: {
          type: Object,
          default: () => {}
        }
    },
    components: { Bar }
}
</script>