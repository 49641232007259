<template>
   <div>
        <b-button v-if="!newItem" size="sm" variant="success" @click="newItem = true">New Reason</b-button>
        <template v-if="newItem">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form inline @submit.prevent="passes(onFormSubmit)" novalidate>
                    <b-button size="sm"  class="mr-1" variant="warning" @click="newItem = false">Undo</b-button>
                    <validation-provider vid="name" v-slot="{ errors }">
                        <b-form-input size="sm"  :state="errors[0] ? false : null" id="name" name="name" v-model="form.name" placeholder="Add reason..." />
                    </validation-provider>
                    <b-button size="sm"  type="submit" class="ml-1" variant="success">Create</b-button>
                </b-form>
            </validation-observer>
        </template>
    </div>
</template>
<script>
import BaseExtension from "@/views/BaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default BaseExtension.extend({
    name: 'user-new-consultation-reason',
    data() {
        return {
            form: new Form({
                name: "",
                consistency_token: null
            }),

            newItem: false
        }
    },
    computed: {
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        }
    },
    methods: {
        ...mapActions('planning', ['createConsultReasonType', 'deleteConsultReasonTypeById']),
        onFormSubmit() {
            this.createConsultReasonType({user: this.user, data: this.form.data()}).then(() => {
                this.reset()
                this.notify('Consultation reasons created', "Consultation reasons was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                }
            })
        },
        reset() {
            this.form.reset()
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.newItem = false
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        }
    }
})
</script>