<template>
<b-card>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <validation-provider vid="priority_id" v-slot="{ errors }">
        <b-form-group
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
             <b-input-group prepend="Priority">
            <b-form-select id="priority_id" name="priority_id" plain v-model="form.priority_id">
                <b-form-select-option disabled :value="null">* Select Referral Priority</b-form-select-option>
                <b-form-select-option v-for="priority in referral_priorities" :key="priority.id" :value="priority.id">{{ priority.name }}
                </b-form-select-option>
            </b-form-select>
            </b-input-group>
        </b-form-group>
        </validation-provider>
        <validation-provider vid="type_id" v-slot="{ errors }">
        <b-form-group
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <b-input-group prepend="Type">
                <b-input-group-prepend>
                    <b-form-select class="squared-left squared-right" id="type_id" name="type_id" plain v-model="form.type_id" @change="onReferralTypeSelected">
                        <b-form-select-option disabled :value="null">* Select Referral Type</b-form-select-option>
                        <b-form-select-option v-for="type in filteredTypes" :key="type.id" :value="type.id">{{ type.name }}
                        </b-form-select-option>
                    </b-form-select>
                </b-input-group-prepend>
                <b-form-input placeholder="Filter tests..." v-model="filterQuery"/>
                <b-input-group-prepend>
                    <b-button variant="warning" @click="filterQuery = ''"><i class="fa-regular fa-arrows-rotate-reverse"></i> Clear</b-button>
                </b-input-group-prepend>
            </b-input-group>
        </b-form-group>
        </validation-provider>

        <b-form-group>
            <ReferralTestList v-model="form.tests" :items="filteredItems" />
        </b-form-group>

        <b-button :disabled="!hasReferrals" class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
        <b-button v-if="isDirty || hasErrors" class="mr-1" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
    </b-form>
    </validation-observer>
    </b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"
import ReferralTestList from "./forms/ReferralTestList"

export default ComponentExtension.extend({
    name: "lab-referral-form",
    props: {
        refresh: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ReferralTestList
    },
    data() {
        return {
            form: null,
            isDirty: false,
            hasErrors: false,

            filterQuery: "",
            filteredItems: [],
        }
    },
    computed: {
        filteredTypes() {
            return this.referral_types.filter(type => ['Laboratory', 'Radiology'].includes(type.name))
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        ...mapState('catalogue', {
            referral_types: state => state.referral_types,
            referral_priorities: state => state.referral_priorities,
        }),
        hasReferrals() {
            return this.form.tests.length > 0
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }

                EventBus.$emit('lab-update-template', val.data())
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        filterQuery: {
            handler(val) {
                this.filterItems()
            }
        },
        refresh: {
            immediate: true,
            handler(val) {
                if(val) {
                   this.reset()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('lab', ['filterDiagnosticTests', 'createDiagnostic',]),
        onFormSubmit() {            
            this.createDiagnostic(this.form.data()).then(() => {
                this.reset()
                this.notify("Lab referral created", "Lab referral was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                type_id: defaults.referral_type,
                priority_id: defaults.referral_priority,
                tests: [],
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.filterQuery = ""
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })

            EventBus.$emit('lab-reset-active-template', this.form.data())
        },
        async filterItems() {
            const referralType = this.referral_types.find(type => type.id === this.form.type_id)
            const parentName = referralType ? referralType.name : "Laboratory"

            const data = await this.filterDiagnosticTests({ parent: parentName, search: this.filterQuery })

            const groupedItems = new Map()

            data.forEach(item => {
                const { parent_id: parentId, parent_name: parentName } = item

                if (!groupedItems.has(parentId)) {
                    groupedItems.set(parentId, { parent_name: parentName, items: [] })
                }
                groupedItems.get(parentId).items.push(item)
            })

            // Convert back to an object if needed
            this.filteredItems = Object.fromEntries(groupedItems)
        },
        async onReferralTypeSelected(id) {
            this.filterQuery = ""
            await this.filterItems()
        },

        updateForm(data) {
            const form_data = this.form.only(['priority_id'])
            if(data) {
                this.form.withData({
                    type_id: data.type_id,
                    priority_id: form_data.priority_id,
                    tests: data.tests,
                    consistency_token: null
                })
            }else{
                this.reset()
            }
        }
    },
    async mounted() {
        await this.getCatalogueItem('referral_types')
        await this.getCatalogueItem('referral_priorities')
        await this.filterItems()
    },
    created() {
        EventBus.$on('lab-update-form', (data) => {
            this.updateForm(data)
        })
    }
})
</script>
<style>
/* Optional: Add styles for better appearance */
.referral-tests {
    height: 320px;
    overflow-y: scroll;
    border: 1px solid #c8ced3; /* Highlight scrollable area */
    border-radius: 0.25rem;
}
</style>