import BaseExtension from '@/views/BaseExtension'

export default BaseExtension.extend({
	props: ['limit'],
	data() {
		return {
            useLimit: true
		}
	},
    components: {
        "pmh-history": () => import("./History"),
        "pmh-blood-type": () => import("./BloodType"),
        "pmh-disabilities": () => import("./Disabilities"),
        "pmh-episodes": () => import("./Episodes"),
        "pmh-decursus": () => import("./Decursus"),
        "pmh-referrals": () => import("./Referrals"),
        "pmh-allergies": () => import("./Allergies"),
        "pmh-prescriptions": () => import("./Prescriptions"),
        "pmh-chronic-prescriptions": () => import("./ChronicPrescriptions"),
        "pmh-vital-functions": () => import("./VitalFunctions"),
        "pmh-measurements": () => import("./Measurements"),
        "pmh-medicalcards": () => import("./MedicalCards"),
        "pmh-diagnostic-referrals": () => import("./DiagnosticReferrals"),
        "pmh-vaccinations": () => import("./Vaccinations"),
        "pmh-procedures": () => import("./Procedures"),
        "pmh-attests": () => import("./Attests"),
    },
    computed: {
        plugins() {
            return [
                { key: 'history', icon: 'fa-files-medical', title: 'General History'},
                { key: 'blood-type', icon: 'fa-droplet', title: 'Blood Type'},
                { key: 'disabilities', icon: 'fa-circle-exclamation', title: 'Disabilities'},
                { key: 'episodes', icon: 'fa-heart-pulse', title: 'Episodes'},
                { key: 'decursus', icon: 'fa-notes-medical', title: 'Decursus'},
                { key: 'referrals', icon: 'fa-forward', title: 'Referrals'},
                { key: 'allergies', icon: 'fa-hand-dots', title: 'Allergies'},
                { key: 'prescriptions', icon: 'fa-prescription-bottle-pill', title: 'Prescriptions'},
                { key: 'chronic-prescriptions', icon: 'fa-prescription-bottle-medical', title: 'Chronic Prescriptions'},
                { key: 'measurements', icon: 'fa-weight-scale', title: 'Measurements'},
                { key: 'vital-functions', icon: 'fa-stethoscope', title: 'Vital Functions'},
                { key: 'medicalcards', icon: 'fa-folder-medical', title: 'Medical Cards'},
                { key: 'diagnostic-referrals', icon: 'fa-flask-round-potion', title: 'Diagnostic Referrals'},
                { key: 'vaccinations', icon: 'fa-syringe', title: 'Vaccinations'},
                { key: 'procedures', icon: 'fa-scalpel', title: 'Procedures'},
                { key: 'attests', icon: 'fa-calendar-range', title: 'Attests'},
            ]
        }
    }
})