<template>
	<b-tab :title="item.code" active-tab-class="text-secondary" lazy>
	    <b-form-group>
	         <b-form-input id="query" :placeholder="`Filter in ${item.name}...`" v-model="filters.search" />
	    	<!-- <b-badge href="#" @click="byTag(tag)" variant="light" v-for="tag in tags">{{ tag }}</b-badge> -->
	    </b-form-group>
	    <b-form-group class="text-center d-none d-md-block" v-slot="{ ariaDescribedby }">
	      	<b-form-radio-group
	      		size="sm"
		        v-model="filters.tag"
		        :aria-describedby="ariaDescribedby"
		        name="tags"
		        button-variant="light"
		        buttons>
				<b-form-radio value="">ALL</b-form-radio>
				<b-form-radio v-for="tag in tags" :value="tag" :key="`tag-${tag}`">
					{{ tag }}
				</b-form-radio>
	        </b-form-radio-group>
	    </b-form-group>
	    <b-list-group flush>
	        <b-list-group-item button v-for="icpc in codes" :key="`icpc-${icpc.id}`" class="d-flex justify-content-between align-items-center" @click="$emit('selected', icpc)">
	            <span><strong>{{icpc.code}}</strong> - {{icpc.name}}</span>
	            <b-badge :variant="`${icpc.color}-icpc`" pill>{{ icpc.tag }}</b-badge>
	        </b-list-group-item>

			<b-list-group-item v-show="!hasCodes" class="text-center">No ICPC Codes found.</b-list-group-item>
	    </b-list-group>

	</b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
	props: ['item'],
    data() {
        return {
        	tags: [],
        	filters: {
        		search: "",
        		tag: ""
        	},
        	codes: []
        }
	},
	watch: {
		filters: {
			immediate: true,
			deep: true,
			handler(val) {
				this.filterItems()
			}
		}
	},
    computed: {
    	...mapState('catalogue', {
    		icpc: state => state.icpc_codes,
    	}),
        hasCodes() {
        	return this.codes.length > 0
        }
    },
	methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),

		searchFilter(data, searchString, tag) {
		    return data.filter(item => {
		        // Modify the condition to include properties you want to search
		        const searchStringMatch = item.parent !== null &&
		            Object.values(item).some(value =>
		                typeof value === 'string' &&
		                value.toLowerCase().includes(searchString.toLowerCase())
		            )

		        if (tag !== "" && typeof tag === "string") {
		            return searchStringMatch && item.tag === tag
		        } else {
		            return searchStringMatch
		        }
		    })
		},

        filterItems() {
        	const codes = this.icpc.filter(child => child.parent === this.item.code)
        	this.codes = this.searchFilter(codes, this.filters.search, this.filters.tag)
        },
        async filterTags() {
        	this.tags = await [...new Set(this.codes.map((item) => item.tag))] 
        }
	},

	async mounted() {
		await this.getCatalogueItem('icpc_codes')
		await this.filterItems()
		await this.filterTags()
	}
}
</script>