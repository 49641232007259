<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> BloodType
    </template>
     <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('bloodtype')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-form-group :disabled="isLocked">
                <validation-provider vid="type_id" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group prepend="Type">
                            <b-form-select id="type_id" name="type_id" plain v-model="form.type_id">
                                <b-form-select-option disabled :value="null">* Select Type</b-form-select-option>
                                <b-form-select-option v-for="blood_type in blood_types" :key="blood_type.id" :value="blood_type.id">{{ blood_type.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </validation-provider>
            </b-form-group>
        </b-overlay>
        <template v-if="checkUserPermission('bloodtype', 'can_change')">
            <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa fa-lock"></i> Lock</b-button>
            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
        </template>
    </b-form>
    </validation-observer>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"

export default TabExtension.extend({
    name: "blood-type",
    data() {
        return {
            form: new Form({
                type_id: null,
                consistency_token: null
            }),

            isLocked: true,
            isDirty: false,
            hasErrors: false
        }
    },
    computed: {
        ...mapState('catalogue', {
            blood_types: state => state.blood_types,
        }),
        ...mapGetters('health', ['getPatientBloodType']),
        bloodtype() {
            return this.getPatientBloodType()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        bloodtype: {
            handler(val) {
                if (val) {
                    this.form.withData(val)
                }
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.$emit('dirty', this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.$emit('saved', this.$vnode.key)
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('health', ['getBloodTypeByPatientId', 'createPatientBloodType', 'updatePatientBloodType']),
        create() {
            this.createPatientBloodType(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient blood type created", "Patient blood type was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                    this.$emit('errors', this.$vnode.key)
                }
            })
        },
        update() {
            this.updatePatientBloodType({id: this.bloodtype.id, bloodtype: this.form.data() }).then(() => {
                this.reset()
                this.notify("Patient blood type updated", "Patient blood type was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                    this.$emit('errors', this.$vnode.key)
                }
            })
        },
        onFormSubmit() {
            if (this.bloodtype) {
                this.update()
            }else{
                this.create()
            }
        },
        reset() {
            this.form.reset()
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.$emit('saved', this.$vnode.key)

            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('blood_types')
        await this.getBloodTypeByPatientId()
    }
})
</script>