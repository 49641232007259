<template>
<b-card no-body>
    <template v-slot:header>
        <i class="fa-solid fa-star"></i> Saved Templates
    </template>
    <b-list-group flush>
        <b-list-group-item class="text-center text-muted">
            <b-input-group>
                <b-form-input placeholder="Search..." v-model="filterQuery"/>
                <template #append>
                    <b-button v-if="filterQuery" variant="warning" @click="filterQuery = ''"><i class="fa-regular fa-arrows-rotate-reverse"></i> Clear</b-button>
                </template>
            </b-input-group>
        </b-list-group-item>
        <template v-if="hasTemplates">
            <TemplateItem v-for="(item, index) in filteredItems" :item="item" :key="index" :is-active="activeTemplate === item.id" @deleted="onDeleted" @selected="onactive"/>
        </template>
        <template v-else>
            <b-list-group-item class="text-center text-muted">No templates</b-list-group-item>
        </template>
    </b-list-group>
</b-card>
</template>
<script>
import TemplateExtension from "./TemplateExtension"
import { mapGetters, mapState, mapActions } from 'vuex'
import TemplateItem from './TemplateItem'

import { debounce } from 'lodash'

export default TemplateExtension.extend({
    name: "prescription-templates-saved",
    components: {
        TemplateItem,
    },
    data() {
        return {
            activeTemplate: null,
            filteredItems: [],
            filterQuery: "",
            items: [],
        }
    },
    computed: {
        hasTemplates() {
            return this.filteredItems.length > 0
        }
    },
    watch: {
        filterQuery: {
            handler() {
                this.filterItems()
            }
        },
        items(val) {
            const initial = Array.from(val)
            this.filteredItems = initial.length >= 10 ? initial.slice(0, 10) : [...initial]
        }
    },
    methods: {
        ...mapActions('prescriptions', ['filterPrescriptionTemplates', 'deletePrescriptionTemplate', 'setActiveTemplate']),
        onactive(template, templateId) {
            this.activeTemplate = this.activeTemplate === templateId ? null : templateId
            this.setActiveTemplate(this.activeTemplate ? template : null)
        },
        onDeleted(id) {
            this.deletePrescriptionTemplate(id).then(() => {
                this.notify("Prescription template removed", "Prescription template was removed successfully")
            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
            })
            .finally(() => {
                this.activeTemplate = null
                this.setActiveTemplate(null)
            })
        },
        async filterItems() {
            if (this.filterQuery) {
                return this.lookupItem()
            }else{
                const initial = Array.from(this.items)
                this.filteredItems = initial.length >= 10 ? initial.slice(0, 10) : [...initial]
            }
        },
        lookupItem: debounce(function () {
            this.filterPrescriptionTemplates({ search: this.filterQuery })
                .then((items) => {
                const initial = Array.from(items)
                this.filteredItems = initial.length >= 10 ? initial.slice(0, 10) : [...initial]
            })
        }, 100),
        resetActive() {
            this.activeTemplate = null
            this.setActiveTemplate(null)
        }
    },
    async mounted() {
        this.items = await this.filterPrescriptionTemplates()
    },
    created() {
        EventBus.$on('prescription-reset-active-template', () => {
            this.resetActive()
        })
    }
})
</script>