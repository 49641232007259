import Vue from 'vue'

export default Vue.extend({
	data() {
		return {
			showModal: false,
		}
	},
	methods: {
		show() {
			this.showModal = true
		},
		hide() {
			this.showModal = false
		},
		onHidden() {
			this.$emit('hidden')
		}
	},
    mounted() {
        this.show()
    }
})