import Vue from 'vue'
import RevisionsModal from '@/core/components/revisions/RevisionsModal'

export default {
	methods: {
        browseRevisions(title, revisions, fields) {
            var ComponentClass = Vue.extend(RevisionsModal)
            var instance = new ComponentClass({ parent: this, propsData: { title: title, revisions: revisions, fields: fields } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$on("reverted", (version) => {
                this.reverted(version)
            })

            instance.$mount()
        },
        reverted(version) {
            throw new Error('reverted() method must be implemented.')
        }
	}
}