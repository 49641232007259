<template>
    <b-tab title="PMH">
        <template v-for="plugin in plugins">
            <h6><i class="fa-solid" :class="plugin.icon"></i> {{ plugin.title }}</h6>
            <component :is="'pmh-' + plugin.key" :key="plugin.key" :limit="3"></component>
        </template>
    </b-tab>
</template>
<script>
import PMHExtension from "@/core/components/pmh/PMHExtension"
import { mapActions } from "vuex"

export default PMHExtension.extend({
    name: "patient-pmh",
    methods: {
        ...mapActions('health', [
            'getHistoryByPatientId', 'getAllergiesByPatientId', 'getBloodTypeByPatientId',
            'getVitalsByPatientId', 'getMeasurementsByPatientId', 'getAttestsByPatientId',
        ]),
        ...mapActions('lab', [
            'getDiagnosticsByPatientId',
        ]),
        ...mapActions('vaccinations', [
            'getVaccinationsByPatientId',
        ]),
        ...mapActions('prescriptions', [
            'getPrescriptionsByPatientId',
        ]),
        ...mapActions('patients', [
            'getMedicalCardsForCurrentPatient',
        ]),
        ...mapActions('decursus', [
            'getDecursusByPatientId',
        ]),
        ...mapActions('referrals', [
            'getReferralsByPatientId',
        ]),
        ...mapActions('episodes', [
            'getEpisodesByCurrentPatient',
        ])
    },

    async mounted() {
        await this.getHistoryByPatientId()
        await this.getAllergiesByPatientId()
        await this.getBloodTypeByPatientId()
        await this.getVitalsByPatientId()
        await this.getMeasurementsByPatientId()
        await this.getAttestsByPatientId()
        await this.getDiagnosticsByPatientId()
        await this.getPrescriptionsByPatientId()
        await this.getVaccinationsByPatientId()
        await this.getDecursusByPatientId()
        await this.getReferralsByPatientId()
        await this.getEpisodesByCurrentPatient()
        await this.getMedicalCardsForCurrentPatient()
    }
})
</script>