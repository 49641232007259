<template>
<b-row>
	<b-col cols="3">
		<b-card no-body header-tag="header" footer-tag="footer">
			<template v-slot:header>
				<h6 class="mb-0">Components</h6>
			</template>
			<draggable class="list-group list-group-flush" handle=".handle" :sort="false" :clone="cloneComponent" :group="{ name: 'components', pull: true, put: false }" v-model="availableComponents" :move="checkDragMove">

				<b-list-group-item class="flex-column align-items-start" v-for="(element, index) in availableComponents" :key="element.tag">
				<div class="d-flex w-100 justify-content-between">
					<div >
						{{ element.name }}
					</div>
					<div>
					    <i class="fa-solid fa-grip-dots-vertical fa-xl align-middle handle"></i>
					</div>
				</div>
				</b-list-group-item>
				<b-list-group-item class="text-center" v-if="noComponents"><i class="fa-solid fa-ban fa-xl"></i></b-list-group-item>
			</draggable>

		</b-card>
	</b-col>
	<b-col>
		<draggable :style="{ backgroundImage: comp.length > 0 ? 'none' : 'url(/img/layer-plus.svg)' }" class="canvas-bg-icon canvas mb-3" handle=".tool-handle" v-model="comp" v-bind="dragOptions" :group="{ name: 'tools'}">
			<b-list-group-item class="tr-list-group" v-for="(element, index) in comp" :key="index">

				<b-button-toolbar class="mb-1">
					<b-button-group class="mr-1 tool-handle">
						<b-button title="Move Row">
							<i class="fa-solid fa-up-down-left-right"></i>
						</b-button>
						<b-button title="Remove Row" @click="removeRowAt(index)">
							<i class="fa-solid fa-trash-can"></i>
						</b-button>
					</b-button-group>
				</b-button-toolbar>

				<draggable
					class="el-row m-0"
					handle=".handle"
		            :list="element.children"
		            tag="b-row"
		            :move="checkDragMove"
		            v-bind="dragOptions" :group="{ name: 'components'}">
		            <b-col class="p-0" v-for="child in element.children" :key="child.id">
									<vc-list-group-component :value="child" @remove="removeChildbyId(child.id)" @toggle-collapse="toggleCollapse">
										{{ child.name }}
									</vc-list-group-component>
								</b-col>
		      	</draggable>
			</b-list-group-item>
		</draggable>
	</b-col>
</b-row>
</template>
<script>

import draggable from "vuedraggable"
import { mapGetters, mapActions } from "vuex"
import Vue from 'vue'
import _ from 'lodash'

export default {
	name: 'vc-builder',
	components: {
		draggable
	},
	data() {
		return {
			comp: []
		}
	},
	computed: {
        ...mapGetters('viewcreator', ['getDefaultTemplate']),
        defaultTemplate() {
        	return this.getDefaultTemplate()
        },
        ...mapGetters('viewcreator', ['getAvailableComponents']),
        availableComponents: {
	        get () {
	        	return this.getAvailableComponents()
	    	},
	        set (value) { 
	        	this.updateAvailableComponents(value) 
	        }
        },
        ...mapGetters('viewcreator', ['getRegisteredComponents']),
        registeredComponents() {
        	return this.getRegisteredComponents()
        },
		dragOptions() {
			return {
				swap: true,
				animation: 200,
				disabled: false,
				ghostClass: "ghost"
			}
		},

		noComponents() {
			return this.availableComponents.length < 1
		}
	},
	watch: {
		comp: {
			deep: true,
			handler(val) {
				this.updateRegisteredComponents(val)
			}
		}
	},
	methods: {
		...mapActions('viewcreator', ['updateAvailableComponents', 'updateRegisteredComponents']),
		checkDragMove(evt) {
			if (!evt.relatedContext.list.every(element => element.can_columnize)) return false
			if ((!evt.draggedContext.element.can_columnize) && evt.relatedContext.list.length > 0) return false
			if (evt.from === evt.to) return true

			return (evt.relatedContext.list.length < this.defaultTemplate.max_columns)

		},
		cloneComponent(component) {
			return {...component, collapsed: false}
		},
		addRow() {
			this.comp.unshift({children: [], index: this.comp.length + 1})
		},
		removeRowAt(idx) {
			this.comp.splice(idx, 1)
		},
		removeChildbyId(id) {
			this.comp.forEach(cp => {
				cp.children = cp.children.filter(c => c.id != id);
			})
		},
		toggleCollapse(element) {
			this.comp.forEach(cp => {
				cp.children = cp.children.map(c => c.id !== element.id ? c : element)
			})
		},
		loadComponents() {
			this.comp = _.cloneDeep(this.registeredComponents)
		}
	},
	beforeMount() {
		this.loadComponents()
	},
}
</script>
<style scoped>
.canvas {
	outline: 2px dashed #c8ced3; /* the dash box */
	outline-offset: -2px;
	background: transparent;
	color: dimgray;
	padding: 8px;
	min-height: 200px; /* minimum height */
}

.tr-list-group {
	background: transparent !important;
/*	outline: 2px dashed #c8ced3 !important; */
	border: 0;
	border-radius: 0;
}

.el-row {
	outline: 2px dashed #c8ced3; /* the dash box */
	min-height: 50px; /* minimum height */
}

.handle, .tool-handle {
	cursor: move;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.canvas-bg-icon {
  background-repeat: no-repeat;
  background-position: center;
}
</style>