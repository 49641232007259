const update = el => {

	const value = el.value

	if(value) {
		if (value.includes(',')) {
			el.value = value.replace(',', '.')
		}
	}
}
/*
 * Export our directive
 */
export const comma2dot = {
	update
}