<template>
	<Insurance title="Primary" :insurance="primary" is_primary />
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"
import Insurance from './Insurance'

export default TabExtension.extend({
	name: "primary-insurance",
	components: {
		Insurance
	},
	computed: {
        ...mapGetters('insurances', ['getPrimaryInsurance']),
        primary() {
            return this.getPrimaryInsurance()
        },
	},
	methods: {
		...mapActions("insurances", ["getInsurancesForCurrentPatient"]),
    },
    async mounted() {
        // Insurances
        await this.getInsurancesForCurrentPatient()
    }
})
</script>