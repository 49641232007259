import session from '@/shared/session'

const state = {
	current: sessionStorage.getItem("userdata") ? JSON.parse(sessionStorage.getItem("userdata")) : null,
	last_activity: null,
	permissions: {},
	roles: []
}

// getters
const getters = {
	getPermissions: (state) => () => {
		return state.permissions
	},
	getRoles: (state) => () => {
		return state.roles
	}
}

// actions
const actions = {
	setUser: ({commit, state}, user) => {
		commit('set_user', user)
		return state.current
	},

	async current ({ state, commit }) {
		try {
			const user = await session.get('/api/auth/current/')
			commit('set_user', user.data)
		} catch(err) {
			commit('logout')
		}

		return state.user
	},

	userActive ({ state, commit }) {
		return session
			.get('/api/auth/session/user/active/')
			.then(response => {
				const last_activity = response.data
				commit('set_last_activity', last_activity)
				return last_activity
			})
	},

	login ({ commit, dispatch }, credentials) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.post('/api/auth/login/', credentials)
            .then(response => {
				const user = response.data
				commit('set_user', user)
				dispatch("schedule/resetDate", "", { root: true })
				return user
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	logout ({ commit, dispatch }) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.post('/api/auth/logout/')
			.then(() => {
				commit('logout')
				return
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	updateUser({ commit, dispatch }, data) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.put('/api/auth/current/', data)
            .then(response => {
				const user = response.data
				commit('set_user', user)

				return user
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	requestResetPassword({ commit, dispatch }, data) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.post('/api/auth/request-password-reset/', data)
			.then(() => {
				return
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	verifyToken({ commit, dispatch }, data) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.post('/api/auth/verify-token/', data)
			.then(() => {
				return
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	confirmResetPassword({ commit, dispatch }, data) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.post('/api/auth/confirm-password-reset/', data)
			.then(() => {
				return dispatch('logout')
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	changePassword({ commit, dispatch }, data) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.post('/api/auth/change-password/', data)
			.then(() => {
				return dispatch('logout')
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	changePasswordWithoutLogout({ commit, dispatch }, data) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.post('/api/auth/change-password/', data)
			.then(() => {
				return
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},

	async getFrontendPermissions({ commit, state }) {
		const permissions = await session.get('/api/auth/permissions/')
		commit("set_permissions", permissions.data)
		return state.permissions
	},

	async getFrontendRoles({ commit, state }) {
		const roles = await session.get('/api/auth/roles/')
		commit("set_roles", roles.data)
		return state.roles
	},

	updateFrontendRoles({ commit, dispatch }, {id, data}) {
		dispatch("loading/load", "auth", { root: true })
		return session
			.patch(`/api/auth/roles/${id}/`, data)
            .then(() => {
				return dispatch('getFrontendRoles')
			})
			.finally(() => {
				dispatch("loading/end", "auth", { root: true })
			})
	},
}

// mutations
const mutations = {
	set_user(state, user) {
		state.current = user
		sessionStorage.setItem("userdata", JSON.stringify(user))
	},
	set_last_activity(state, last_activity) {
		state.last_activity = last_activity
	},
	logout(state){
		state.current = null
		sessionStorage.removeItem("userdata")
		sessionStorage.clear()
	},
	set_permissions(state, permissions) {
		state.permissions = permissions
	},
	set_roles(state, roles) {
		state.roles = roles
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}