<template>
    <LineChartGenerator :options="chartOptions" :data="chartData" />
</template>
<script>
import { Line as LineChartGenerator } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
    name: 'line-chart',
    props: {
        chartData: {
            type: Object,
            required: true
          },
        chartOptions: {
          type: Object,
          default: () => {}
        }
    },
    components: { LineChartGenerator }
}
</script>