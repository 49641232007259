<template>
	<b-modal hide-footer no-close-on-esc no-close-on-backdrop v-model="showModal" size="xl" title="Add ICP Code" @hidden="onHidden" :busy="$store.getters['loading/isLoading']('catalogue')" body-class="p-0">
		<b-card class="border-0" header-tag="header" footer-tag="footer" no-body>
			<b-tabs pills card v-model="tabIndex" class="border-0" content-class="border-0">
				<template #tabs-end>
					<b-nav-item v-show="$store.getters['loading/isLoading']('catalogue')" disabled role="presentation"><b-spinner type="border" small></b-spinner></b-nav-item>
				</template>
				<b-tab active-tab-class="text-secondary" lazy>
					<template slot="title">
						<i class="fa fa-search"></i> Quick Search
					</template>
				    <b-form-group>
				        <b-form-input id="query" placeholder="Search ICP Code..." v-model="filters.search"  />
				    </b-form-group>
				    <b-form-group class="text-center d-none d-md-block" v-slot="{ ariaDescribedby }">
				      	<b-form-radio-group
				      		size="sm"
					        v-model="filters.tag"
					        :aria-describedby="ariaDescribedby"
					        name="tags"
					        button-variant="light"
					        buttons>
							<b-form-radio value="">ALL</b-form-radio>
							<b-form-radio v-for="tag in tags" :value="tag" :key="`tag-${tag}`">
								{{ tag }}
							</b-form-radio>
				        </b-form-radio-group>
				    </b-form-group>
				    <b-list-group flush>
				        <b-list-group-item button v-for="icpc in codes" :key="`icpc-${icpc.id}`" class="d-flex justify-content-between align-items-center" @click="selected(icpc)">
				            {{icpc.code}} {{icpc.name}}
				            <b-badge :variant="`${icpc.color}-icpc`" pill>{{ icpc.tag }}</b-badge>
				        </b-list-group-item>

						<b-list-group-item v-show="!hasCodes" class="text-center">No ICP Codes found.</b-list-group-item>
				    </b-list-group>

				</b-tab>
				<ICPCTab v-for="item in parents" :key="`main-${item.code}`" :item="item" @selected="selected"/>
			</b-tabs>
		</b-card>
	</b-modal>
</template>
<script>
import ModalExtension from '@/core/components/modal/ModalExtension'
import { mapGetters, mapState, mapActions } from 'vuex'

import ICPCTab from './ICPCTab'

export default ModalExtension.extend({
	components: {
		ICPCTab
	},
    data() {
        return {
        	tabIndex: 0,
        	filters: {
        		search: "",
        		tag: ""
        	},
        	codes: []
        }
	},
    computed: {
    	...mapState('catalogue', {
    		icpc: state => state.icpc_codes,
    	}),
        parents() {
            return this.icpc.filter(item => item.parent === null)
        },
		tags() {
		    const uniqueTags = new Set()

		    this.icpc.forEach(item => {
		        if (item.tag && typeof item.tag === 'string') {
		            uniqueTags.add(item.tag)
		        }
		    })

		    return Array.from(uniqueTags)
		},
        hasCodes() {
        	return this.codes.length > 0
        }
    },
	watch: {
		filters: {
			deep: true,
			handler(val) {
				this.filterItems()
			}
		}
	},
	methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
		searchFilter(searchString, tag) {
		    return this.icpc.filter(item => {
		        // Modify the condition to include properties you want to search
		        const searchStringMatch = item.parent !== null &&
		            Object.values(item).some(value =>
		                typeof value === 'string' &&
		                value.toLowerCase().includes(searchString.toLowerCase())
		            )

		        if (tag !== "" && typeof tag === "string") {
		            return searchStringMatch && item.tag === tag
		        } else {
		            return searchStringMatch
		        }
		    })
		},
        async filterItems() {
        	this.codes = []
			if (this.filters.search === "" || this.filters.search.length < 2) { // min 2 chars
				return
			}

        	this.codes = this.searchFilter(this.filters.search, this.filters.tag)
        },
        selected(icpc) {
        	this.$emit('selected', icpc)
        	this.hide()
        }
	},
    async mounted() {
        await this.getCatalogueItem('icpc_codes')
        this.show()
    }
})
</script>