<template>
	<b-list-group-item class="flex-column align-items-start">
	<div class="d-flex w-100 justify-content-between">
		<div >
			<i class="mr-2 fa-solid fa-grip-dots-vertical fa-xl handle"></i> <slot/>
		</div>
		<div class="d-flex">
		    <b-form-checkbox v-if="value.can_collapse" size="sm" v-model="form.collapsed" @change="onToggleCollapse">
		   		<i class="fa-solid fa-arrows-to-line"></i>
		    </b-form-checkbox>

	        <b-link class="ml-5" @click="$emit('remove')">
	            <i class="fa-solid fa-trash-can align-middle"></i>
	        </b-link>
		</div>
	</div>
	</b-list-group-item>
</template>
<script>
export default {
	name: 'vc-list-group-component',
	props: {
		value: {
			default: {}
		}
	},
	data() {
		return {
			form: new Form({
				collapsed: false
			})
		}
	},
	methods: {
		onToggleCollapse() {
			this.$emit('toggle-collapse', this.form.data())
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.form.withData(val)
			}
		}
	}
}
</script>