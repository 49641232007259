<template>
	<b-modal size="xl" title="Chart: Lab Results" v-model="showModal" ok-only ok-title="Dismiss" @hidden="onHidden" button-size="sm">
		<b-card class="border-0" header-tag="header" footer-tag="footer" no-body>
			<line-chart v-if="items" :chart-options="chartOptions" :chart-data="chartData" />
		</b-card>
	</b-modal>
</template>
<script>
import ModalExtension from '@/core/components/modal/ModalExtension'
import { mapState, mapGetters, mapActions } from 'vuex'

export default ModalExtension.extend({
	props: ['items'],
	computed: {
        chartOptions() {
        	return {
				plugins: {
					legend: {
						align: 'start'
					}
				},
			    // scales: {
			    //   y: {
			    //     stacked: true
			    //   }
			    // },
			    // scales: {
			    //   y: {
			    //     type: 'linear',
			    //     display: true,
			    //     position: 'left',
			    //   },
			    //   y1: {
			    //     type: 'linear',
			    //     display: true,
			    //     position: 'right',

			    //     // grid line settings
			    //     grid: {
			    //       drawOnChartArea: false, // only want the grid lines for one axis to show up
			    //     },
			    //   },
			    // },
			    // interaction: {
			    //   mode: 'index',
			    //   intersect: false,
			    // },
			    // stacked: false,

			    // plugins: {
			    //   title: {
			    //     display: true,
			    //     text: 'Chart.js Line Chart - Multi Axis'
			    //   }
			    // },

			    // plugins: {
			    //   decimation: {
			    //   	enabled: true
			    //   }
			    // },
	            // parsing: {
	            //   xAxisKey: 'x',
	            //   yAxisKey: 'y'
	            // },
			    responsive: true,
	            maintainAspectRatio: true
	        }
	    },
	    chartData() {
            const datasets = this.items.data.map((item) => ({
				label: item.label, // Assuming item has a label property
				backgroundColor: item.color.backgroundColor,
				borderColor: item.color.borderColor,
				borderWidth: 2,
				data: this.combineData(item),
				cubicInterpolationMode: 'monotone',
				// tension: 0.4,
				// fill: 1,
				// stepped: true,
				// pointStyle: 'circle',
				// pointRadius: 5,
				pointHoverRadius: 10
            }))

            return {
                labels: this.items.labels.map(item => this.toLocaleDate(item.date_updated)),
                datasets: datasets
            }
	    }
	},
    methods: {
        combineData(item) {
        	let { data, dates } = item

			let mappedData = []

			for (let i = 0; i < data.length; i++) {
			    let mappedObject = {
			        x: this.toLocaleDate(dates[i]),
			        y: data[i]
			    }
			    
			    mappedData.push(mappedObject)
			}

        	return mappedData
        }
    }
})
</script>