<template>
	<b-tabs pills card content-class="border-left-0 border-bottom-0 border-right-0">
		<!-- <DecursusTab v-for="decursus in decursustabs" :decursus="decursus" :key="`decursus-${decursus.id}`" /> -->
		<DecursusTab v-if="currentObject" :decursus="currentObject" />

		<template #tabs-end>
			<b-nav-item :disabled="prevDisabled"class="ml-auto" role="presentation" @click="first" href="#"><i class="fa-solid fa-chevrons-left"></i> </b-nav-item>
			<b-nav-item :disabled="prevDisabled" role="presentation" @click="prev" href="#"><i class="fa-solid fa-chevron-left"></i> </b-nav-item>
			<b-nav-item :disabled="nextDisabled" role="presentation" @click="next" href="#"><i class="fa-solid fa-chevron-right"></i> </b-nav-item>
			<b-nav-item :disabled="nextDisabled" role="presentation" @click="last" href="#"><i class="fa-solid fa-chevrons-right"></i> </b-nav-item>
		</template>


	    <template v-slot:empty>
	      <div class="text-center text-muted">
	        No previous Decursus found.
	      </div>
	    </template>
	</b-tabs>
</template>
<script>
import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapState, mapActions } from "vuex"
import DecursusTab from "./DecursusTab"

export default BaseExtension.extend({
	name: "decursus-previous",
	components: {
		DecursusTab
	},
	data() {
		return {
			currentIndex: 0
		}
	},
    computed: {
        ...mapGetters('decursus', ['getPatientPreviousDecursus']),
        decursustabs() {
            return this.getPatientPreviousDecursus()
        },
     	currentObject() {
      		return this.decursustabs[this.currentIndex]
    	},
    	nextDisabled() {
    		return (this.currentIndex > 0) == false
    	},
    	prevDisabled() {
    		return (this.currentIndex < this.decursustabs.length - 1) == false
    	}
    },
	methods: {
		prev() {
			if (this.currentIndex < this.decursustabs.length - 1) {
				this.currentIndex++;
			}
		},
		next() {
			if (this.currentIndex > 0) {
				this.currentIndex--
			}
		},
		last() {
			this.currentIndex = 0
		},
		first() {
			this.currentIndex = this.decursustabs.length - 1
		}
	}
})
</script>