import session from '@/shared/session'

const state = {
	patient: {
		vaccinations: [],
	}
}

// getters
const getters = {
	getPatientVaccinations: (state) => () => {
		return state.patient.vaccinations
	}
}

// actions
const actions = {
	async getVaccinationsByPatientId({ commit, state, rootState }) {
		const vaccinations  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/vaccinations/`)

		commit("set_vaccinations", vaccinations.data)

		return state.patient.vaccinations
	},

	createVaccination({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "vaccinations", { root: true })
		return session
				.post(`/api/vaccinations/`, {...data, decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getVaccinationsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "vaccinations", { root: true })
	      })
	},

	deleteVaccination({ dispatch, commit }, id) {
		dispatch("loading/load", "vaccinations", { root: true })
		return session
				.delete(`/api/vaccinations/${id}/`)
				.then(() => {
					return dispatch("getVaccinationsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "vaccinations", { root: true })
      })
	}
}

// mutations
const mutations = {
	set_vaccinations(state, vaccinations) {
		state.patient.vaccinations = vaccinations
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}