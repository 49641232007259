// baseeditor.js
import { ClassicEditor as ClassicEditorBase } from '@ckeditor/ckeditor5-editor-classic'

import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import { Bold, Italic, Strikethrough, Underline } from '@ckeditor/ckeditor5-basic-styles'
import { Clipboard } from '@ckeditor/ckeditor5-clipboard'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office'
import { PendingActions } from '@ckeditor/ckeditor5-core'
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format'
import { SelectAll } from '@ckeditor/ckeditor5-select-all'
import { ShowBlocks } from '@ckeditor/ckeditor5-show-blocks'
import { TextTransformation } from '@ckeditor/ckeditor5-typing'
import { Undo } from '@ckeditor/ckeditor5-undo'

import LineHeight from './plugins/lineheight/lineheight'

export default class SimpleEditor extends ClassicEditorBase {}

SimpleEditor.builtinPlugins = [
    GeneralHtmlSupport,
    Alignment,
    Autoformat,
    Bold,
    Clipboard,
    Essentials,
    Italic,
    Paragraph,
    PasteFromOffice,
    PendingActions,
    RemoveFormat,

    SelectAll,
    ShowBlocks,
    Strikethrough,
    TextTransformation,
    Underline,
    Undo,

]

SimpleEditor.defaultConfig = {
    htmlSupport: {
        allow: [
            {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true
            }
        ]
    },
    toolbar: {
        items: [
            '|', 'bold', 'italic', 'strikethrough', 'underline',
            '|', 'removeformat',
            '|', 'undo', 'redo',

        ],
        shouldNotGroupWhenFull: true
    },
    language: 'en'
}
