import session from '@/shared/session'

const state = {
	revisions: {
		history: [],
		bloodtype: [],
		decursus: []
	}
}

// getters
const getters = {
	getRevisionsForHistory: (state) => () => {
		return state.revisions.history
	},
	getRevisionsForBloodType: (state) => () => {
		return state.revisions.bloodtype
	},
	getRevisionsForDecursus: (state) => () => {
		return state.revisions.decursus
	}
}

// actions
const actions = {

	async getHistoryRevisions({ commit, dispatch }, id) {
		dispatch("loading/load", "revision", { root: true })

		const revisions = await session.get(`/api/health/history/${id}/revisions/`)
		commit("set_revisions", {module: 'history', revisions: revisions.data})

		dispatch("loading/end", "revision", { root: true })

		return revisions.data
	},

	revertHistoryRevision({ commit, dispatch }, {version, id}) {
		dispatch("loading/load", "revision", { root: true })
		return session
			.patch(`/api/health/history/${id}/revert/`, {version: version})
			.then((reverted) => {
				commit("health/set_health", {key: 'history', value: reverted.data}, { root: true })
			})
			  .finally(() => {
			  	dispatch("loading/end", "revision", { root: true })
			})
	},

	async getBloodTypeRevisions({ commit, dispatch }, id) {
		dispatch("loading/load", "revision", { root: true })

		const revisions = await session.get(`/api/health/bloodtype/${id}/revisions/`)
		commit("set_revisions", {module: 'bloodtype', revisions: revisions.data})

		dispatch("loading/end", "revision", { root: true })

		return revisions.data
	},

	revertBloodTypeRevision({ commit, dispatch }, {id, version}) {
		dispatch("loading/load", "revision", { root: true })
		return session
			.patch(`/api/health/bloodtype/${id}/revert/`, {version: version})
			.then((reverted) => {
				commit("health/set_health", {key: 'bloodtype', value: reverted.data}, { root: true })
			})
			  .finally(() => {
			  	dispatch("loading/end", "revision", { root: true })
			})
	},

	async getDecursusRevisions({ commit, dispatch }, id) {
		dispatch("loading/load", "revision", { root: true })

		const revisions = await session.get(`/api/decursus/${id}/revisions/`)
		commit("set_revisions", {module: 'decursus', revisions: revisions.data})

		dispatch("loading/end", "revision", { root: true })

		return revisions.data
	},

	revertDecursusRevision({ commit, dispatch }, {id, version}) {
		dispatch("loading/load", "revision", { root: true })
		return session
			.patch(`/api/decursus/${id}/revert/`, {version: version})
			.then((reverted) => {
				commit("decursus/set_decursus", reverted.data, { root: true })
			})
			  .finally(() => {
			  	dispatch("loading/end", "revision", { root: true })
			})
	}
}

// mutations
const mutations = {
	set_revisions(state, {module, revisions}) {
		state.revisions[module] = revisions
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}