<template>
    <b-card id="procedure-container" no-body class="card-accent-danger rounded-0 border-left-0 border-right-0 mb-0 bg-gradient-light">
        <validation-observer ref="observer" v-slot="{ passes }">
            <b-tabs pills card content-class="border-left-0 border-bottom-0 border-right-0" active-nav-item-class="bg-danger">
                <template #tabs-end>

                </template>
                <b-tab active>
                    <template slot="title">
                        <i class="fa-sharp fa-solid fa-scalpel"></i> Procedures
                    </template>

                    <b-form-group>
                        <vue-bootstrap-autocomplete prepend="Procedure" v-model="query" :data="items" placeholder="Search..." :minMatchingChars="1" :serializer="itemSerializer" @hit="onHit" @input="lookupItem">
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                                <div>
                                    <!-- Note: the v-html binding is used, as htmlText contains
                                    the suggestion text highlighted with <strong> tags -->                                    
                                    <div class="d-flex w-100 justify-content-between">
                                        <small class="ml-1" v-html="htmlText"></small>
                                        <small class="ml-auto">{{ data.resource }} - {{ data.tariff }}</small>
                                    </div>

                                    <small class="ml-1">{{ data.code }}</small>
                                </div>
                            </template>
                        </vue-bootstrap-autocomplete>
                    </b-form-group>

                    <b-form-group v-if="hasProcedures"
                        label="Current Decursus:">
                        <ProcedureItem v-for="procedure in procedures" :key="`procedure-${procedure.id}`" :item="procedure" />
                        </b-list-group>
                    </b-form-group>
                </b-tab>
            </b-tabs>
        </validation-observer>
    </b-card>
</template>
<script>
import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapState, mapActions } from "vuex"

import ProcedureItem from './ProcedureItem'

import { debounce } from 'lodash'

export default BaseExtension.extend({
    name: 'decursus-procedures',
    components: {
        ProcedureItem
    },
    data() {
        return {
            form: new Form({
                description: "",
                type_id: null,
                consistency_token: null
            }),

            items: [],
            query: "",
        }
    },
    computed: {
        ...mapGetters('patients', ['getPatientCurrentConsult']),
        consult() {
            return this.getPatientCurrentConsult()
        },
        ...mapGetters('procedures', ['getDecursusProcedures']),
        procedures() {
            return this.getDecursusProcedures()
        },
        hasProcedures() {
            return this.procedures.length > 0
        },
        ...mapGetters('insurances', ['getPatientInsurances']),
        patient_insurances() {
            return this.getPatientInsurances()
        },
        hasInsurance() {
            return this.patient_insurances.length > 0
        },
        activeInsurance() {
            const active = this.patient_insurances.find(insurance => insurance.id == this.consult?.insurance && insurance.is_active)
            if (active) {
                return active.company.name === "SZF" ? "SZF" : "SURVAM"
            }

            return false
        },
        filterByInsurance() {
            return this.hasInsurance ? this.activeInsurance : false
        }
    },
    methods: {
        ...mapActions('procedures', ['filterProcedures', 'getProceduresByCurrentDecursus', 'createDecursusProcedure']),
        itemSerializer(item) {
            return item.name
        },
        onHit(item) {
            this.form.populate({type_id:  item.id})
            this.save()
        },
        save() {
            this.createDecursusProcedure(this.form.data()).then(() => {
                this.reset()
            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                }
            })
        },
        lookupItem: debounce(function () {
            this.filterProcedures({ search: this.query })
                .then((items) => {
                    if (this.filterByInsurance) {
                        const filtered = items.filter(item => item.resource == this.filterByInsurance)
                        this.items = filtered.length > 0 ? filtered : items
                    }else{
                        this.items = items
                    }
            })
        }, 500),
        reset() {
            this.query = ""
            this.form.reset()
        },
    },
    async mounted() {
        await this.getProceduresByCurrentDecursus()
    }
})
</script>