import session from '@/shared/session'

const state = {
	sidebar: [],
	navbar: [],
}

// getters
const getters = {
	getNavbarMessages: (state) => () => {
		return state.navbar
	},
	getSidbarMessages: (state) => () => {
		return state.sidebar
	}
}

// actions
const actions = {

	async filterMessages({ commit }, data) {
		const messages = await session
			.get("/api/messaging/", {
				params: data,
			})
		return messages.data
	},

	async getSideBar ({ commit }) {
		const sidebar = await session.get('/api/messaging/sidebar/')
		commit('set_sidebar', sidebar.data)
		return sidebar.data
	},

	async getNavBar ({ commit }) {
		const navbar = await session.get('/api/messaging/navbar/')
		commit('set_navbar', navbar.data)
		return navbar.data
	},

	async resetNavbar({ commit, dispatch }) {
		const navbar = await session.put('/api/messaging/navbar/')
		await dispatch("getSideBar")
		commit("set_navbar", navbar.data)

		return navbar.data
	},

	async updateMessage({commit, dispatch}, data) {
		const message = await session.patch(`/api/messaging/${data.id}/`, data.form)
		await dispatch("getSideBar")
		await dispatch("getNavBar")
		return message.data
	}
}

// mutations
const mutations = {
	set_sidebar (state, sidebar) {
		state.sidebar = sidebar
	},
	set_navbar (state, navbar) {
		state.navbar = navbar
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
