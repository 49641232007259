<template>
<b-card>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
    
    <validation-provider vid="non_field_errors" v-slot="{ errors }">
      <b-form-invalid-feedback :state="errors[0] ? false : null">
        <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
      </b-form-invalid-feedback>
    </validation-provider>
    <validation-provider vid="repeat_prescription" v-slot="{ errors }">
    <b-form-group
        label-cols-sm="2"
        label-cols-lg="2"
        :label="!is_chronic ? 'Is Chronic' : ''"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-input-group class="mb-2">
            <b-input-group-prepend is-text v-if="!is_chronic">
                <b-form-checkbox v-model="form.is_chronic" name="is_chronic"></b-form-checkbox>
            </b-input-group-prepend>
            <b-input-group-prepend is-text>repeat</b-input-group-prepend>
            <b-form-input type="number" :disabled="notChronic" v-model="form.repeat_prescription"></b-form-input>
            <b-input-group-prepend is-text>times after</b-input-group-prepend>
            <b-form-select :disabled="notChronic" id="repeat_date" name="repeat_date" plain v-model="form.repeat_date_id">
                <b-form-select-option disabled :value="null">Select Repeat Date</b-form-select-option>
                <b-form-select-option v-for="repeat_date in medication_repeat_dates" :key="repeat_date.id" :value="repeat_date.id">{{ repeat_date.name }}
                </b-form-select-option>
            </b-form-select>
        </b-input-group>
        <validation-provider vid="repeat_date_id" v-slot="{ errors }">
            <b-form-invalid-feedback :state="errors[0] ? false : null">
                {{ errors[0] }}
            </b-form-invalid-feedback>
        </validation-provider>
    </b-form-group>
    </validation-provider>

    <validation-provider vid="medication_id" v-slot="{ errors }">
        <b-form-group
            :disabled="hasAlternateMedication"
            label-cols-sm="2"
            label-cols-lg="2"
            label="Medication"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <vue-bootstrap-autocomplete v-model="query" :data="items" placeholder="Search Medication..." :minMatchingChars="1" :serializer="medicationSerializer" @hit="onHitMedication" @input="lookupItem">
                    <template slot="suggestion" slot-scope="{ data, htmlText }">
                        <div class="d-flex align-items-center">
                            <!-- Note: the v-html binding is used, as htmlText contains
                            the suggestion text highlighted with <strong> tags -->
                            <span class="ml-1" v-html="htmlText"></span>
                            <span class="ml-auto">{{ data.code }}</span>
                        </div>
                    </template>

                <template #append>
                    <b-button variant="warning" @click="query = ''"><i class="fa-regular fa-arrows-rotate-reverse"></i> Clear</b-button>
                </template>
            </vue-bootstrap-autocomplete>
        </b-form-group>
    </validation-provider>
    <validation-provider vid="other" v-slot="{ errors }">
        <b-form-group
            label-cols-sm="2"
            label-cols-lg="2"
            label="Other"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <b-input-group>
                <b-input-group-prepend is-text>
                    <b-form-checkbox v-b-tooltip.hover title="" v-model="hasAlternateMedication" @change="onAlternateMedicationChange">
                </b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input id="other" :disabled="!hasAlternateMedication" name="other" placeholder="Other medication" v-model="form.other" @input="onOtherMedication"/>
            </b-input-group>
        </b-form-group>
    </validation-provider>
    <validation-provider name="Strength" vid="strength" rules="measurement" v-slot="{ errors }">
        <b-form-group
            label-cols-sm="2"
            label-cols-lg="2"
            label="Strength"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <b-input-group>
                <b-form-input type="number" id="strength" v-comma2dot name="strength" placeholder=".00" v-model="form.strength" />
                <template #append>
                    <b-form-select id="unit_id" name="unit_id" class="squared-left" plain v-model="form.unit_id">
                     <b-form-select-option disabled :value="null">Select Unit</b-form-select-option>
                    <b-form-select-option v-for="unit in units" :key="unit.id" :value="unit.id">{{ unit.name }}</b-form-select-option>
                    </b-form-select>
                </template>
            </b-input-group>
             <validation-provider vid="unit_id" v-slot="{ errors }">
                <b-form-invalid-feedback :state="errors[0] ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
            </validation-provider>
        </b-form-group>
    </validation-provider>

    <validation-provider name="Dosage" vid="frequency_usage" rules="double:2" v-slot="{ errors }">
    <b-form-group
        label-cols-sm="2"
        label-cols-lg="2"
        label="Dosage"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
         <b-input-group>
            <b-form-input type="number" id="frequency_usage" name="frequency_usage" v-model="form.frequency_usage" placeholder="Frequency usage..." autocomplete="off" />
             <b-input-group-prepend is-text>
                times per
            </b-input-group-prepend>
            <!-- <b-form-input value="times per" readonly autocomplete="off" /> -->
            <b-form-select id="frequency_unit_id" name="frequency_unit_id" plain v-model="form.frequency_unit_id">
                <b-form-select-option disabled :value="null">Select Frequency Unit</b-form-select-option>
                <b-form-select-option v-for="frequency_unit in frequency_units" :key="frequency_unit.id" :value="frequency_unit.id">{{ frequency_unit.name }}
                </b-form-select-option>
            </b-form-select>
        </b-input-group>
        <validation-provider vid="frequency_unit_id" v-slot="{ errors }">
            <b-form-invalid-feedback :state="errors[0] ? false : null">
                {{ errors[0] }}
            </b-form-invalid-feedback>
        </validation-provider>
    </b-form-group>
    </validation-provider>

    <validation-provider name="Amount" vid="dosage_amount" v-slot="{ errors }">
    <b-form-group
        label-cols-sm="2"
        label-cols-lg="2"
        label="Amount"
        description="example: 3 times per day 2 tablets"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-input-group>
        <b-form-input type="number" id="dosage_amount" v-comma2dot name="dosage_amount" v-model="form.dosage_amount" placeholder="Dosage amount..." autocomplete="off" />
        <b-form-select id="dosage_form_id" name="dosage_form_id" plain v-model="form.dosage_form_id">
            <b-form-select-option disabled :value="null">Select Dosage form</b-form-select-option>
            <b-form-select-option v-for="prescription_dosage_form in prescription_dosage_forms" :key="prescription_dosage_form.id" :value="prescription_dosage_form.id">{{ prescription_dosage_form.name }}
            </b-form-select-option>
        </b-form-select>
        </b-input-group>
        <validation-provider vid="dosage_form_id" v-slot="{ errors }">
            <b-form-invalid-feedback :state="errors[0] ? false : null">
                {{ errors[0] }}
            </b-form-invalid-feedback>
        </validation-provider>
    </b-form-group>
    </validation-provider>

    <validation-provider vid="delivery_form_id" v-slot="{ errors }">
        <b-form-group
            label-cols-sm="2"
            label-cols-lg="2"
            label="Administration"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">

            <b-form-select id="delivery_form_id" name="delivery_form_id" plain v-model="form.delivery_form_id">
                <b-form-select-option disabled :value="null">Select Delivery Form</b-form-select-option>
                <b-form-select-option v-for="delivery_form in delivery_forms" :key="delivery_form.id" :value="delivery_form.id">{{ delivery_form.name }}
                </b-form-select-option>
            </b-form-select>
        </b-form-group>
    </validation-provider>

    <validation-provider vid="amount_of_time" v-slot="{ errors }">
        <b-form-group
            label-cols-sm="2"
            label-cols-lg="2"
            label="Period"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
                <b-input-group>
                    <b-form-input type="number" id="amount_of_time" name="amount_of_time" v-model="form.amount_of_time" placeholder="Amount of time" autocomplete="off" />
                     <b-input-group-prepend is-text>
                        Total
                    </b-input-group-prepend>
                    <b-form-input readonly id="total_amount" :value="calculatedTotal" autocomplete="off" />
                </b-input-group>
        </b-form-group>
    </validation-provider>

    <b-form-group
        label="Administration Instructions"
        label-for="instructions">
            <b-form-textarea id="instructions" v-capitalize v-model="form.instructions" placeholder="Add administration instructions..." rows="5"></b-form-textarea>
    </b-form-group>

    <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
    
    <b-button v-show="templateForm.dirty()" :disabled="$store.getters['loading/isLoading']('prescription-template')" :variant="activeTemplate ? 'warning' : 'success'" class="float-right" tabindex="3" @click="onSaveTemplate">{{ activeTemplate ? 'Update Template' : 'Create Template'}}</b-button>


    </b-form>
    </validation-observer>
</b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"
import { debounce } from 'lodash'

export default ComponentExtension.extend({
    name: "prescriptions-form",
    props: {
        is_chronic: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            items: [],
            form: null,
            templateForm: new Form({
                medication_id: null,
                other: "",
                dosage: {
                    medication_id: null,
                    other: "",
                    is_chronic: this.is_chronic,
                    repeat_prescription: 0,
                    repeat_date_id: null,
                    strength: null,
                    unit_id: null,
                    frequency_usage: null,
                    frequency_unit_id: null,
                    dosage_amount: null,
                    dosage_form_id: null,
                    amount_of_time: null,
                    delivery_form_id: null,
                    instructions: "",
                },
                consistency_token: null
            }),

            query: "",

            isDirty: false,
            hasErrors: false,

            hasAlternateMedication: false
        }
    },
    computed: {
        ...mapState('prescriptions', {
            activeTemplate: (state) => state.templates.active
        }),
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        ...mapState('catalogue', {
            // medications: state => state.medications,
            units: state => state.units,
            frequency_units: state => state.frequency_units,
            prescription_dosage_forms: state => state.prescription_dosage_forms,
            delivery_forms: state => state.delivery_forms,
            side_effects_statuses: state => state.side_effects_statuses,
            medication_repeat_dates: state => state.medication_repeat_dates,
            severities: state => state.severities
        }),

        calculatedTotal() {
            return this.form.frequency_usage * this.form.dosage_amount * (this.form.amount_of_time ? this.form.amount_of_time : 1)
        },

        notChronic() {
            return this.form.is_chronic == false
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }

                this.updateTemplateForm(val)
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        activeTemplate: {
            immediate: true,
            handler(val) {
                if(val){
                    if (val.name) {
                        this.query = val.name
                    }else{
                        this.hasAlternateMedication = true
                    }

                    this.form.populate({
                        medication_id: val.dosage?.medication_id,
                        other: val.dosage?.other,
                        is_chronic: val.dosage?.is_chronic || false,
                        repeat_prescription: val.dosage?.repeat_prescription || 0,
                        repeat_date_id: val.dosage?.repeat_date_id || null,
                        strength: val.dosage?.strength,
                        unit_id: val.dosage?.unit_id,
                        frequency_usage: val.dosage?.frequency_usage,
                        frequency_unit_id: val.dosage?.frequency_unit_id,
                        dosage_amount: val.dosage?.dosage_amount,
                        dosage_form_id: val.dosage?.dosage_form_id,
                        amount_of_time: val.dosage?.amount_of_time,
                        delivery_form_id: val.dosage?.delivery_form_id,
                        instructions: val.dosage?.instructions,
                    })

                    this.templateForm.populate(val)

                }else{
                    this.reset()
                }
            }
        },
        refresh: {
            immediate: true,
            handler(val) {
                if(val) {
                   this.reset()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('prescriptions', ['getPrescriptionsByPatientId', 'createPatientPrescription', 'updatePatientPrescription', 'prescriptionPrintableById', 'filterMedication', 'createPrescriptionTemplate', 'updatePrescriptionTemplate', 'setActiveTemplate']),
        ...mapActions('health', ['checkForAllergy']),
        
        updateTemplateForm(data) {
            this.templateForm.populate({
                medication_id: data?.medication_id,
                other: data?.other,
                dosage:{
                    medication_id: data?.medication_id,
                    other: data?.other,
                    is_chronic: data?.is_chronic || false,
                    repeat_prescription: data?.repeat_prescription || 0,
                    repeat_date_id: data?.repeat_date_id || null,
                    strength: data?.strength,
                    unit_id: data?.unit_id,
                    frequency_usage: data?.frequency_usage,
                    frequency_unit_id: data?.frequency_unit_id,
                    dosage_amount: data?.dosage_amount,
                    dosage_form_id: data?.dosage_form_id,
                    amount_of_time: data?.amount_of_time,
                    delivery_form_id: data?.delivery_form_id,
                    instructions: data?.instructions,
                }
            })
        },

        onAlternateMedicationChange(value) {
            // clear date form data
            this.form.populate({medication_id: null, other: ""})
            this.query = ""
        },
        medicationSerializer(item) {
            return item.name
        },

        async onOtherMedication(str) {
            const check = await this.checkForAllergy({ allergy: str })
            if (check) {
                this.notify("Patient Warning", "Patient is allergic for this medication.", "warning")
                this.$refs.observer?.setErrors({"other": ["Patient is allergic to this medication."]})
            }else{
                this.$refs.observer?.reset()
            }
        },
        async onHitMedication(item) {
            const check = await this.checkForAllergy({ allergy: item.name })
            if (check) {
                this.notify("Patient Warning", "Patient is allergic for this medication.", "warning")
                this.$refs.observer?.setErrors({"medication_id": ["Patient is allergic to this medication."]})
            }else{
                this.$refs.observer?.reset()
            }
            
            this.form.populate({medication_id: item.id})
        },
        lookupItem: debounce(function () {
            this.filterMedication({ search: this.query })
                .then((items) => {
                    this.items = items
            })
        }, 500),

        onFormSubmit() {
            this.createPatientPrescription(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient prescription created", "Patient prescription was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                medication_id: null,
                is_chronic: this.is_chronic,
                other: "",
                strength: null,
                unit_id: null,
                frequency_usage: null,
                frequency_unit_id: null,
                dosage_amount: null,
                dosage_form_id: null,
                amount_of_time: null,
                delivery_form_id: null,
                instructions: "",
                stoppage_reason: "",
                repeat_prescription: this.is_chronic ? defaults.repeat_prescription : 0,
                repeat_date_id: this.is_chronic ? defaults.repeat_date : null,
                consistency_token: null

            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            this.templateForm.reset()
            this.$refs.observer?.reset()

            this.$nextTick(() => {
                this.query = ""
                this.hasErrors = false
                this.hasAlternateMedication = false
                this.saved(this.$vnode.key)
            })

            EventBus.$emit('prescription-reset-active-template', this.form.data())
        },
        onSaveTemplate() {
            if (this.activeTemplate) {
                this.updatePrescriptionTemplate({id: this.activeTemplate?.id, data: this.templateForm.data() }).then((template) => {
                    this.templateForm.populate(template)
                    this.notify("Prescription template updated", "Prescription template was updated successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                    }
                })
            }else{
                this.createPrescriptionTemplate(this.templateForm.data()).then((template) => {
                    this.setActiveTemplate(null)
                    this.notify("Prescription template created", "Prescription template was created successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                    }
                })
            }
        }
    },
    async mounted() {
        // await this.getCatalogueItem('medications')
        await this.getCatalogueItem('units')
        await this.getCatalogueItem('frequency_units')
        await this.getCatalogueItem('prescription_dosage_forms')
        await this.getCatalogueItem('delivery_forms')
        await this.getCatalogueItem('side_effects_statuses')
        await this.getCatalogueItem('medication_repeat_dates')
        await this.getCatalogueItem('severities')
    }
})
</script>