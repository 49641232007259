import session from '@/shared/session'
import downloadjs from 'downloadjs'

const state = {

}

// getters
const getters = {

}

// actions
const actions = {
	async downloadExport({ commit, state, rootState }, id) {
		const download = await session
			.get(`/api/interchange/exports/${id}/download/`)
		
		const { content, filename, mime_type } = download.data
      	downloadjs(content, filename, mime_type)

      	return true
	}
}

// mutations
const mutations = {

}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}