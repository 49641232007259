import session from '@/shared/session'

const state = {
	patient: {
		prescriptions: []
	},

	templates: {
		active: null
	}
}

// getters
const getters = {
	getPatientPrescriptions: (state) => () => {
		return state.patient.prescriptions.filter(item => !item.is_chronic)
	},

	getPatientChronicPrescriptions: (state) => () => {
		return state.patient.prescriptions.filter(item => item.is_chronic)
	},

	getActiveTemplate: (state) => () => {
		return state.templates.active
	},
}

// actions
const actions = {
	setActiveTemplate({ commit }, template) {
		commit('set_active_template', template)
	},

	async filterPrescriptionTemplates({ commit, state }, params) {
		const templates = await session
			.get('/api/prescriptions/templates/', {
					params: params
			})

		return templates.data
	},

	createPrescriptionTemplate({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "prescription-template", { root: true })
		return session
				.post(`/api/prescriptions/templates/`, data)
				.then((template) => {
					dispatch("filterPrescriptionTemplates")
					return template.data
				})
	      .finally(() => {
	        dispatch("loading/end", "prescription-template", { root: true })
	      })
	},

	updatePrescriptionTemplate({ commit, dispatch }, { id , data }) {
    dispatch("loading/load", "prescription-template", { root: true })
		return session
			.put(`/api/prescriptions/templates/${id}/`, data)
			.then((template) => {
					dispatch("filterPrescriptionTemplates")
					return template.data
			})
			.finally(() => {
				dispatch("loading/end", "prescription-template", { root: true })
			})
	},

	deletePrescriptionTemplate({ dispatch }, id) {
		dispatch("loading/load", "prescriptions-template", { root: true })
		return session
				.delete(`/api/prescriptions/templates/${id}/`)
				.then(() => {
					return dispatch("filterPrescriptionTemplates")
				})
      .finally(() => {
        dispatch("loading/end", "prescriptions-template", { root: true })
      })
	},

	async filterMedication({ commit, state }, params) {
		const items = await session
			.get('/api/catalogue/medications/', {
					params: params
			})

		return items.data
	},

	async getPrescriptionsByPatientId({ commit, rootState }) {
		const prescriptions  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/prescriptions/`)

		commit("set_prescriptions", prescriptions.data)

		return prescriptions.data
	},

	createPrescriptionForCurrentPatient({ commit, rootState, dispatch }, data) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.post(`/api/prescriptions/`, {...data,  decursus: rootState.patients.current?.current_consult?.decursus_id})
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "prescriptions", { root: true })
	      })
	},

	createPatientPrescription({ commit, rootState, dispatch }, data) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.post(`/api/prescriptions/`, {...data,  decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "prescriptions", { root: true })
	      })
	},
	updatePatientPrescription({ commit, rootState, dispatch }, { id, data }) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.patch(`/api/prescriptions/${id}/`, data)
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "prescriptions", { root: true })
	      })
	},

	deletePatientPrescription({ commit, dispatch }, id) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.delete(`/api/prescriptions/${id}/`)
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "prescriptions", { root: true })
      })
	},

	prescriptionPrintableById({ commit, state }, id) {
		return session
				.get(`/api/prescriptions/${id}/printable/`)
				.then(printable => {
					return printable.data
				})
	},
}

// mutations
const mutations = {
	set_prescriptions(state, prescriptions) {
		state.patient.prescriptions = prescriptions
	},
	set_active_template(state, template) {
		state.templates.active = template
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}