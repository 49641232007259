let recognitionInstance = null

const initializeRecognition = (lang = 'en-US') => {
    recognitionInstance = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
    recognitionInstance.continuous = true
    recognitionInstance.interimResults = true
    recognitionInstance.lang = lang
}

const startRecognition = (onResult, onEnd) => {
    if (!recognitionInstance) return
    
    recognitionInstance.onresult = (event) => {
        let transcript = Array.from(event.results)
            .map((result) => result[0])
            .map((result) => result.transcript)
            .join(".")

        if (event.results[0].isFinal) {
            if (onResult) onResult(transcript)
        }
    }

    recognitionInstance.onend = onEnd || (() => console.log('Speech recognition ended.'))
    recognitionInstance.start()
}

const stopRecognition = () => {
    if (recognitionInstance) {
        recognitionInstance.stop()
        recognitionInstance = null
    }
}

export default {
    install(Vue, options = {}) {
        Vue.prototype.$speechRecognition = {
            initialize: initializeRecognition,
            start: startRecognition,
            stop: stopRecognition,
        }
    }
}
