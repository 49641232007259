<template>
	<b-nav-item class="px-3 d-md-down-none" to="/messaging" id="popover-1">
		<b-avatar button variant="outline-info" size="30px" :badge="badge" badge-variant="danger">
			<i class="fa-light fa-bell fa-lg"></i>
		</b-avatar>
		<b-popover id="notification-popover" ref="popover" placement="bottom" target="popover-1" title="Notifications" triggers="hover focus">
			<template v-if="hasNewMessages">
				<b-list-group flush>
					<b-list-group-item v-for="(message, index) in messages" to="/messaging" active-class="" :key="index">
						<b-media>
							<template #aside>
								<b-icon icon="circle-fill" variant="danger" shift-v="-8" scale="0.5"></b-icon>

							</template>
							<div>{{message.message}}</div>
							<div class="text-muted small">{{dynamicDateTime(message.created)}}</div>
						</b-media>
					</b-list-group-item>
				</b-list-group>
			</template>
			<template v-else>
				<b-list-group flush>
					<b-list-group-item active-class="">
						No new messages
					</b-list-group-item>
				</b-list-group>
			</template>
		</b-popover>
	</b-nav-item>
</template>
<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: 'messaging-navbar-button',
	computed: {
        ...mapGetters('messaging', ['getNavbarMessages']),
        navbar() {
            return this.getNavbarMessages()
        },
		hasNewMessages() {
			return this.navbar?.length > 0
		},
		badge() {
			return this.hasNewMessages ? String(this.navbar.length) : false
		},
		messages() {
			return this.hasNewMessages ? this.navbar.slice(0, 5) : []
		}
	},
	methods: {
		...mapActions('messaging', ['getNavBar']),
		async handleMessageReceived(message) {
			await this.getNavBar()
			EventBus.$emit('refreshMessages')
		},
	},
	async mounted() {
		await this.getNavBar()

		this.$websocket.$on('messageReceived', this.handleMessageReceived)
		this.$websocket.connect(`${window.location.host}/ws/connect/messaging/?username=${this.user?.username}`)
	},
	beforeDestroy() {
		this.$websocket.$off('messageReceived', this.handleMessageReceived)
		this.$websocket.disconnect()
	},	
}
</script>
<style>
#notification-popover .popover-body {
	padding: 0 !important;
}
</style>