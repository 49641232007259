<template>
    <b-card class="card-accent-dark" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-prescription-bottle-pill"></i> <strong>Prescriptions</strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE PRESCRIPTION ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('prescriptions')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    borderless
                    striped
                    hover
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('prescriptions')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(full_medication)="data">
                        <b-button v-if="data.item.is_repeated" disabled size="sm" variant="secondary" v-b-tooltip.hover title="Repeated Prescription"><i class="fa-solid fa-repeat"></i></b-button> {{ data.value }}
                    </template>

                    <template v-slot:cell(stoppage_date_time)="data">
                        <template v-if="data.value">
                            <div>{{toLocaleDate(data.value)}}</div>
                            <div class="small">
                                {{data.item.stoppage_reason}}
                            </div>                            
                        </template>
                        <template v-else>
                            <span class="small font-italic text-muted">n/a</span>
                        </template>
                    </template>
                    
                    <template v-slot:cell(total_amount)="data">
                        {{ data.value }} units
                    </template>

                    <template v-slot:cell(actions)="row">
                        <b-button-group size="sm">
                            <b-button v-if="row.item.printable" variant="secondary" v-b-tooltip.hover title="Print Prescription" @click="printRow(row)"><i class="fa-solid fa-print"></i></b-button>
                            
                            <template v-if="!row.item.is_repeated">
                                <b-button v-if="!row.item.medication_stopped" variant="warning" @click="row.toggleDetails" v-b-tooltip.hover title="Stop medication"><i class="fa-solid fa-stop"></i></b-button>
                                <!-- <b-button variant="secondary" v-b-tooltip.hover title="Record allergic reaction"><i class="fa-solid fa-syringe"></i></b-button> -->
                                <!-- <b-button v-if="row.item.editable" variant="primary" v-b-tooltip.hover title="Edit prescription" @click="onEditItem(row)"><i class="fa-solid fa-pen"></i></b-button> -->
                                <b-button variant="danger" @click="onRemoveItem(row)" v-b-tooltip.hover title="Remove prescription"><i class="fa-light fa-xmark"></i></b-button>                        
                            </template>
                        </b-button-group>
                    </template>
                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                    <template #row-details="row">
                        <StopPrescriptionForm :item="row.item" />
                    </template>
                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>
                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="4" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer>
                <b-button @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'" class="mb-3"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <b-row>
                        <b-col><PrescriptionForm @created="addNew = false" :refresh="addNew"/></b-col>
                        <b-col cols="4"><TemplateSaved /></b-col>
                    </b-row>
                </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
        </b-collapse>
    </b-card>
</template>
<script>
import Vue from 'vue'
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"
import PrescriptionForm from './PrescriptionForm'
import StopPrescriptionForm from './StopPrescriptionForm'
import TemplateSaved from './templates/TemplateSaved'

import _ from 'lodash'
import printJS from 'print-js'

export default ComponentExtension.extend({
    name: "prescriptions",
    components: {
        PrescriptionForm,
        StopPrescriptionForm,
        TemplateSaved
    },
    data() {
        return {
            addNew: false,
            form: new Form({
                stoppage_reason: "",
                consistency_token: null
            }),

            fields: [
                { key: 'created', label: 'Date/Time', class: 'align-middle' , formatter: (value) => this.toLocaleDate(value) },
                { key: 'full_medication', label: 'Medication', class: 'align-middle' },
                { key: 'directions', label: 'Directions', class: 'align-middle' },
                { key: 'stoppage_date_time', label: 'Stoppage Date/Time', class: 'align-middle' },
                { key: 'total_amount', label: 'Total', class: 'align-middle' },
                // { key: 'repeat', label: 'Repeat', class: 'align-middle' },
                { key: "actions", label: "", class: "align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            confirm: false,
            selected: null,
        }
    },
    computed: {
        ...mapGetters('prescriptions', ['getPatientPrescriptions']),
        items() {
            return _.cloneDeep(this.getPatientPrescriptions())
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        },
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('prescriptions', ['getPrescriptionsByPatientId', 'deletePatientPrescription', 'prescriptionPrintableById']),
        onRemoveItem(row) {
            this.confirm = true
            this.selected = row.item
        },
        onConfirmed() {
            if (this.selected) {
                
                this.deletePatientPrescription(this.selected.id).then(() => {
                    this.notify("Patient prescription removed", "Patient prescription was removed successfully")
                    this.confirm = false
                    this.selected = null

                })
                .catch(errors => {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.confirm = false
                    this.selected = null
                })
            }
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.selected = null
        },
        async printRow(row) {
            const prescription = await this.prescriptionPrintableById(row.item.id)
            printJS({
                printable: prescription,
                type: 'pdf',
                base64: true
            })
        }
    },
    async mounted() {
        await this.getPrescriptionsByPatientId()

    }
})
</script>
