<template>
<b-tab title-item-class="mb-1" class="p-0" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Health
    </template>
    <b-tabs pills card content-class="border-left-0 border-right-0 border-bottom-0">
        <blood-type v-if="checkUserPermission('bloodtype', 'can_view')" @dirty="onDirty" @saved="onSaved" @errors="onErrors"></blood-type>
        <vital-functions v-if="checkUserPermission('vitals', 'can_view')" @dirty="onDirty" @saved="onSaved" @errors="onErrors"></vital-functions>
        <measurements v-if="checkUserPermission('measurements', 'can_view')" @dirty="onDirty" @saved="onSaved" @errors="onErrors"></measurements>
        <attests v-if="checkUserPermission('attest', 'can_view')" @dirty="onDirty" @saved="onSaved" @errors="onErrors"></attests>
    </b-tabs>
</b-tab>
</template>
<script>
import TabExtension from './TabExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import BloodType from "./health/BloodType"
import VitalFunctions from "./health/VitalFunctions"
import Measurements from "./health/Measurements"
import Attests from "./health/Attests"

export default TabExtension.extend({
    name: 'health',
    data() {
        return {
            unSaved: [],
            errors: []
        }
    },
    components: {
        'blood-type': BloodType,
        'vital-functions': VitalFunctions,
        'measurements': Measurements,
        'attests': Attests
    },
    computed: {
        isDirty() {
            return this.unSaved.length > 0
        },
        hasErrors() {
            return this.errors.length > 0
        }
    },
    methods: {
        onSaved(id) {
            this.unSaved = this.unSaved.filter((component) => component.id !== id)
            this.errors = this.errors.filter((component) => component.id !== id)
            this.saved(id)
        },
        onDirty(id) {
            let exists = this.unSaved.find((component) => component.id == id)
            if (!exists) this.unSaved.push({ id: id })
            this.dirty(id)
        },
        onErrors(id) {
            let exists = this.errors.find((component) => component.id == id)
            if (!exists) this.errors.push({id: id})
        }
    }
})
</script>