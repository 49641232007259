import moment from "moment"

const update = el => {

	const value = el.value

	if(value) {
		if(moment(value, 'HH:mm:ss').format('HH:mm:ss') === value) {
			el.value = moment(value, "HH:mm:ss").format("HH:mm")
		}
	}
}
/*
 * Export our directive
 */
export const timeformat = {
  update
}